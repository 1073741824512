import React, {Component} from 'react';
import styles from './Menu.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import LoginUtil from "../../utils/LoginUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import ImageCellRenderer from "../GridCellRenderers/ImageCellRenderer";
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog";
import MenuProduct from "./MenuProduct";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContentText} from "@material-ui/core";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Menü Id',
        field: 'id',
        valueGetter: 'data.menuId'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Menü Adı',
        field: 'name',
        floatingFilter: true,
      },
      {
        headerName: 'Menü Resmi',
        field: 'imageUrl',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'Açıklama',
        field: 'description'
      },
      {
        headerName: 'Fiyat',
        filter: 'agNumberColumnFilter',
        field: 'price'
      },
      {
        headerName: 'KDV Oranı',
        filter: 'agNumberColumnFilter',
        field: 'kdv'
      }
    ];

    this.inputParams = {
      name: {
        label: 'Menü Adı', type: 'text', defaultValue: ''
      },
      description: {
        label: 'Açıklama', type: 'text', defaultValue: ''
      },
      price: {
        label: 'Fiyat', type: 'number', step: '.01', defaultValue: ''
      },
      kdv: {
        label: 'KDV Oranı', defaultValue: 8, type: 'select',
        options: [
          {label: '0', value: 0},
          {label: '1', value: 1},
          {label: '10', value: 10},
          {label: '20', value: 20}
        ]
      },
      image: {
        label: 'Resim Yükleyin', type: 'image'
      },
      currImage: {
        label: 'Mevcut Resim', type: 'image-viewer'
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      editData: null,
      showMenuProductDialog: false,
      menuProdcutDialogMode: 'View',    //"Add", "View"
      selectedMenu: null
    };

    this.gridSettingJson = localStorage.getItem('menuGridSetting');
  }

  async componentDidMount() {
    await this.loadData().then();
  }

  loadData = async () => {
    this.setState({loading: true});
    let apiRes;

    const projectId = Util.isAdminUser() ? this.state.formData.projectId.value : Util.getProjectId();

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/menu/list-for-panel-with-project-id',
      'POST', Util.HEADERS_FOR_GET, projectId);

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    });

    if (!this.gridSettingJson)
      this.gridApi.sizeColumnsToFit();
  }

  close = (event) => {
    this.props.closeTab(event, this.props.tabId);
  }

  onRowDoubleClicked = (event) => {
    this.setState({
      showMenuProductDialog: true,
      selectedMenu: event.data.menuId,
      menuProdcutDialogMode: 'View'
    });
  }

  askForDeleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();

    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show(selectedData[0].data.name + ' Menüsünü ' + (makePassive ? 'pasifleştirmek' : 'silmek') +
        ' istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this, makePassive));
  }

  deleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();
    let currentRowIndex = 0;
    selectedData.forEach((node) => {
      currentRowIndex = node.rowIndex - 1;
      this.delData(node.data.menuId, makePassive, currentRowIndex).then();
    });
  }

  async delData(menuId, makePassive, rowIndex) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("menuId", menuId);
    urlencoded.append("makePassive", makePassive ? 'true' : 'false');

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/menu/del-menu', 'POST',
      headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);

      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  async makeActive(menuId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("menuId", menuId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/menu/activate-menu', 'POST',
      headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);

      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  refreshList = () => {
    this.loadData().then();
  }

  excelExport = () => {
    const params = {
      fileName: "Menu-Listesi.xlsx",
      sheetName: 'Menu-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.gridSettingJson)
      this.gridColumnApi.setColumnState(JSON.parse(this.gridSettingJson));
  };

  saveGridSetting = () => {
    localStorage.removeItem('menuGridSetting');
    let savedState = this.gridColumnApi.getColumnState();
    localStorage.setItem('menuGridSetting', JSON.stringify(savedState));
    Mesaj.show('Tablo ayarları kayıt edildi!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  onFirstDataRendered = params => {
    if (!this.gridSettingJson)
      params.api.sizeColumnsToFit();
  };

  prepareRowData(list) {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  addEditMenu = async () => {
    if (!this.state.formData.name.value) {
      Mesaj.show('Menü adı boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.name.value.length > 50) {
      Mesaj.show('Menü adı 50 karakterden fazla olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.editData && !this.state.formData.image.value) {
      Mesaj.show('Lütfen bir dosya yükleyiniz', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.price.value) {
      Mesaj.show('Fiyat boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    let nameExist = false;
    const that = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      if (!that.state.editData &&
        rowNode.data.name.toString().toLocaleLowerCase('tr') ===
        that.state.formData.name.value.toLocaleLowerCase('tr')
      ) {
        nameExist = true;
        return;
      }
    });

    if (nameExist) {
      Mesaj.show('Bu isimde bir menü önceden oluşturulmuş', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});

    const data = new FormData();
    if (this.state.editData)
      data.append('menuId', this.state.editData.menuId)

    if (this.state.formData.image.value)
      data.append('image', this.state.formData.image.value)

    data.append('name', this.state.formData.name.value)
    data.append('description', this.state.formData.description.value);
    data.append('price', this.state.formData.price.value.toString());
    data.append('kdv', this.state.formData.kdv.value || 0);
    data.append('menuProjectId', this.state.formData.projectId.value || 1);

    console.log("form data : ", this.state.formData);

    const headers = {'Authorization': 'Bearer ' + LoginUtil.getToken()}
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/menu/add-edit-menu', 'POST',
      headers, data);
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }
    //Edit moddan çıkılıyor
    this.clearEditMode();
    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Ürün düzenleme başarılı', 'Bilgi',
      Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then(() => {
      this.gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.menuId === apiRes.menuId) {
          rowNode.setSelected(true);
          that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    });
  }

  clearEditMode() {
    this.state.formData.name.value = '';
    this.state.formData.description.value = '';
    this.state.formData.price.value = '';
    this.state.formData.image.value = null;
    this.state.formData.currImage.value = null;
    this.state.formData.kdv.value = 8;
    this.setState({editData: null});
  }

  editMenu = editData => {
    this.state.formData.name.value = editData.name;
    this.state.formData.image.value = null;
    this.state.formData.currImage.value = editData.imageUrl;
    this.state.formData.description.value = editData.description;
    this.state.formData.price.value = editData.price;
    this.state.formData.kdv.value = editData.kdv;
    this.setState({editData: editData});
  }

  addProduct = (menuId) => {
    this.setState({
      showMenuProductDialog: true,
      selectedMenu: menuId,
      menuProdcutDialogMode: 'Add'
    });
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Menü Sil',
        action: this.askForDeleteRow.bind(this, false),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Menü Pasif Yap',
        disabled: params.node.data.deleted,
        action: this.askForDeleteRow.bind(this, true),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Menü Aktifleştir',
        disabled: !params.node.data.deleted,
        action: this.makeActive.bind(this, params.node.data.menuId)
      },
      {
        name: 'Menüyü Düzenle',
        action: this.editMenu.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      },
      {
        name: 'Ürün Ekle',
        action: this.addProduct.bind(this, params.node.data.menuId),
        icon: '<img src="/images/edit.png"/>',
      }
    ];

    return menu;
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  closeModal = () => {
    this.setState({showMenuProductDialog: false});
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap'}}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        <Dialog
          open={this.state.showMenuProductDialog}
          onClose={this.closeModal}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle id="form-dialog-title"
                       style={{textAlign: 'center'}}>{this.state.menuProdcutDialogMode === 'View' ?
            'Menü Ürünleri' : 'Ürün Ekle'}</DialogTitle>
          <DialogContentText>
            <MenuProduct
              onClose={this.closeModal}
              mode={this.state.menuProdcutDialogMode}
              selectedMenu={this.state.selectedMenu}
              projectId={this.state.formData.projectId.value}/>
          </DialogContentText>
        </Dialog>

        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />
        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.addEditMenu.bind(this)}
          >
            {this.state.editData ? 'Menü Bilgi Güncelle' : 'Menü Ekle'}
          </Button>
        </div>

        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            size="small"
            color="primary"
            startIcon={<CancelIcon/>}
            onClick={this.clearEditMode.bind(this)}
          >
            Düzenleme Modundan Çık
          </Button>
        </div>
      </div>

      <div>
        <Toolbar handlers={
          [
            {callBack: this.refreshList, title: 'Yenile', icon: '/images/menu/mnuAutorenew_24px.svg'},
            {callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'},
            {
              callBack: this.saveGridSetting.bind(this),
              title: 'Tablo Ayarlarını Kaydet',
              icon: '/images/menu/24px/mnuSaveSettings.png'
            }
          ]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 265px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          getContextMenuItems={this.getContextMenuItems}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
          onRowDoubleClicked={this.onRowDoubleClicked}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Menu;
