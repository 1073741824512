import React, {Component} from 'react';
import {CHANGE_GRID_THEME} from "../../redux/actions/actionTypes";
import {connect} from "react-redux";

class CustomThemesToolPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ textAlign: 'center', padding: '5px'}}>
        <select onChange={this.props.changeTheme} defaultValue={this.props.selectedGridTheme}>
          <option value="">-none-</option>
          <option value="ag-theme-alpine">Alpine</option>
          <option value="ag-theme-alpine-dark">Alpine Dark</option>
          <option value="ag-theme-balham">Balham</option>
          <option value="ag-theme-balham-dark">Balham Dark</option>
          <option value="ag-theme-material">Material</option>
          <option value="ag-theme-blue">Blue</option>
          <option value="ag-theme-bootstrap">Bootstrap</option>
          <option value="ag-theme-dark">Dark</option>
          <option value="ag-theme-fresh">Fresh</option>
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('(CustomThemesToolPanel.js) mapStateToProps state : ', state);
  return {
    selectedGridTheme: state.selectedGridTheme
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log('(CustomThemesToolPanel.js) mapDispatchToProps');
  return {
    changeTheme: (event) => {
      dispatch({
        type: CHANGE_GRID_THEME,
        selectedGridTheme: event.target.value
      })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomThemesToolPanel);
