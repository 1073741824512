import React, {Component} from 'react';

import {Sidebar, Topbar} from './components';
import {PageAndMenuUtil} from "../../utils/PageAndMenuUtil";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Util from "../../utils/CommonUtil";
import styles from './Main.module.css'
import Close from '@material-ui/icons/Close';
import {MainProvider} from "./MainContext";
import Mesaj from "../../utils/Mesaj";
import store from "../../redux/store/store";
import {connect} from "react-redux";
import MqttUtil from "../../utils/MqttUtil";
import {Consts} from "../../const/Consts";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalComponent: null,
      mainComponent: null,
      tab: this.updatePages(this.props.pages),
      openSidebar: false,
      domainType: JSON.parse(sessionStorage.getItem("profile")).domainType,
    }

    this.projectList = [];

    this.mqttTopicCallbacks = {
      newOrder: [],
      newUser: []
    }
  }

  async componentDidMount() {
    if (Util.isElevatorUser()) {
      window.location.href = "/elevator";
      return;
    }

    if (Util.isZorluUser()) {
      window.location.href = "/zorlu";
      return;
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/get-project-list',
      'GET', Util.HEADERS_FOR_GET, null);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    if(apiRes && apiRes.length > 0) {
      apiRes.forEach(projectRow => {
        this.projectList.push({value: projectRow.projectId, label: projectRow.projectName});
      });
    }

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/get-project-id-list',
      'GET', Util.HEADERS_FOR_GET, null);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    apiRes.forEach(projectId => {
      this.mqttTopicCallbacks.newOrder.push({topic: 'orders/' + projectId + '/new', callbackArr: []});
      this.mqttTopicCallbacks.newUser.push({topic: 'users/' + projectId + '/new', callbackArr: []});
    });

    this.mqttTopicCallbacks.newOrder.forEach(newOrder => {
      newOrder.callbackArr.push(this.mqttMessageCallback);
    });

    this.client = await MqttUtil.createClient(this.mqttTopicCallbacks);
  }

  componentWillUnmount() {
    if (this.client)
      this.client.end();
  }

  mqttMessageCallback = (topic, message) => {
    const messageJson = JSON.parse(message);
    this.beep(3000, 4);
    Mesaj.show(<p style={{padding: '0', margin: '0'}}><b>Yeni bir şipariş oluşturuldu!</b><br/><b>Sipariş No
      :</b> {messageJson.orderNo} <br/>
      <b>Kat :</b> {messageJson.floorName}<br/><b>Daire No :</b> {messageJson.flatNo} <br/><b>Alıcı
        :</b> {messageJson.buyer} <br/>
      <b>Siparişler :</b> {messageJson.orders}</p>, 'Yeni Sipariş', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  beep = (function () {
    var ctxClass = window.audioContext || window.AudioContext || window.AudioContext || window.webkitAudioContext
    var ctx = new ctxClass();

    return function (duration, type, finishedCallback) {
      duration = +duration;

      // Only 0-4 are valid types.
      type = (type % 5) || 0;

      if (typeof finishedCallback != "function")
        finishedCallback = function () {
        };

      var osc = ctx.createOscillator();
      osc.type = type;

      osc.connect(ctx.destination);
      if (osc.noteOn)
        osc.noteOn(0);

      if (osc.start)
        osc.start();

      setTimeout(function () {
        if (osc.noteOff) osc.noteOff(0);
        if (osc.stop) osc.stop();
        finishedCallback();
      }, duration);
    };
  })();

  updatePages = (newPages) => {
    const toBeCheckedPages = newPages && Array.isArray(newPages) ? newPages : [];
    return this.getDefaultTab(toBeCheckedPages);
  }

  getDefaultTab = (pages) => {
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].defaultOpen === true) {
        const guid = Util.createGuid();
        return {selectedTab: guid, tabs: [this.createTabFromPage(pages[i], guid)]};
      }
    }
    return {selectedTab: null, tabs: []};
  }

  createTabFromPage = (page, guid, tabParams) => {
    return {component: page.tabComponent, label: page.title, value: guid, page: page, tabParams: tabParams};
  }

  handleMenuButtonClick = (page) => {
    if (page.modalComponent) {
      const props = {closeHandler: this.closeModalHandler};
      this.setState({modalComponent: PageAndMenuUtil.getComponentByName(page.modalComponent, props)});
    } else if (page.reduxAction) {
      store.dispatch(page.reduxAction);
    } else if (page.component) {
      this.updateMainComponent(page, this.props.pages);
    } else if (page.tabComponent) {
      this.addTab(page, this.props.pages);
    } else {
      console.error("Main handleMenuButtonClick içinde Bilinmeyen menü türü gelmiştir. page:", page);
    }

  }

  closeModalHandler = () => {
    this.setState({modalComponent: null});
  }

  updateMainComponent = (page, pages) => {
    this.setState({mainComponent: <div>{PageAndMenuUtil.getComponentByName(page.component)}</div>})
  }

  addTab = (page, tabParams) => {
    const newTab = {...this.state.tab};

    //öncelikle multiTab mevcut tablarda aynı sayfadan açılmışmı kontrol edilecek. Aynısı varsa eşleştirip kapatacak.
    if (!page.multiTab || page.multiTab === false) {
      for (let i = 0; i < this.state.tab.tabs.length; i++) {
        if (this.state.tab.tabs[i].page.tabComponent === page.tabComponent) {
          newTab.selectedTab = this.state.tab.tabs[i].value;
          this.setState({tab: newTab});
          return;
        }
      }
    }

//    newTab.tabs.push({component:page.tabComponent, label:page.title, value:guid,page:page});
    const guid = Util.createGuid();
    newTab.tabs.push(this.createTabFromPage(page, guid, tabParams));
    newTab.selectedTab = guid;
    this.setState({mainComponent: null, tab: newTab});
  }


  renderTabOptions = () => {

    if (!this.state.tab.tabs || this.state.tab.tabs.length === 0)
      return;

    let reactComps = [];
    for (let i = 0; i < this.state.tab.tabs.length; i++) {
      //className={ this.state.tab.tabs[i].value === this.state.tab.selectedTab ? styles.selectedTab : null }
      reactComps.push(<Tab key={i} value={this.state.tab.tabs[i].value} label={

        <div className={styles.cancelTabDiv}>
          {this.state.tab.tabs[i].label}
          <Close

            color={this.state.tab.tabs[i].value === this.state.tab.selectedTab ? 'primary' : 'inherit'}
            style={{fontSize: '15px'}} onClick={(e) => {
            if (this.state.tab.tabs[i].page.exitConfirm === true) {
              e.persist();
              Mesaj.show(this.state.tab.tabs[i].label + ' sayfası kapatılacak emin misiniz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, () => {
                this.closeTab(e, this.state.tab.tabs[i].value)
              });
            } else
              this.closeTab(e, this.state.tab.tabs[i].value)
          }}/>
        </div>

      }  {...Util.a11yProps(i)} ></Tab>);
    }

    return reactComps;
  }

  closeTab = (event, tabId) => {
    if (event)
      event.stopPropagation();

    const newTab = {tabs: [], selectedTab: null};
    for (let i = 0; i < this.state.tab.tabs.length; i++) {

      if (this.state.tab.tabs[i].value !== tabId)
        newTab.tabs.push(this.state.tab.tabs[i]);
    }

    if (newTab.tabs.length > 0)
      newTab.selectedTab = newTab.tabs[newTab.tabs.length - 1].value;

    this.setState({tab: newTab});
  }

  registerForInstance = (tabId, instancePointer) => {
    const newTab = {...this.state.tab};

    for (let i = 0; i < this.state.tab.tabs.length; i++) {
      if (this.state.tab.tabs[i].value === tabId) {
        newTab.tabs[i].instancePointer = instancePointer;
        this.setState({tab: newTab});
        return;
      }
    }
  }

  searchOnTab = (compName) => {
    for (let i = 0; i < this.state.tab.tabs.length; i++) {
      if (this.state.tab.tabs[i].component === compName) {
        return this.state.tab.tabs[i];
      }
    }
    return;
  }

  renderTabPages() {
    const tab = this.state.tab;
    if (!tab.tabs || tab.tabs.length === 0)
      return;

    let tabPages = [];

    for (let i = 0; i < tab.tabs.length; i++) {
      const style = tab.selectedTab === tab.tabs[i].value ? null : {style: {display: 'none'}}
      const props = {
        closeTab: this.closeTab,
        tabId: tab.tabs[i].value, ...tab.tabs[i].tabParams,
        addTab: this.addTab,
        projectList: this.projectList,
        registerForInstance: this.registerForInstance
      };
      const renderedTabComp = PageAndMenuUtil.getComponentByName(tab.tabs[i].component, props);

      tabPages.push(<div className={styles.tabPanel}
                         key={i} {...style}>{renderedTabComp}</div>);
    }

    //    setMainComponent(<div>{PageAndMenuUtil.getComponentByName(page.component) }</div>);
    //<Tab label="ALTOS ÖNCÜ"  value={2} icon={<AccountBalanceWalletIcon />} {...this.a11yProps(2)} title="Ön Muhasebe ürünü " />
    return tabPages;
  }


  handleSidebarOpen = () => {
    this.setState({openSidebar: true});
  };

  handleSidebarClose = () => {
    this.setState({openSidebar: false});
  };

  //const shouldOpenSidebar = isDesktop ? true : openSidebar;

  onTabChange = (event, newVal) => {
    console.log('onTabChange', event.target);
    const newTab = {...this.state.tab};
    newTab.selectedTab = newVal;
    this.setState({tab: newTab});
  };

  //Sidebar variant diğer seçenek 'persistent' :
  render() {
    return (
      <MainProvider value={this}>
        {this.state.modalComponent}
        <Topbar
          selectedCompany={this.props.selectedCompany}
          selectedPeriod={this.props.selectedPeriod}
          domainType={this.state.domainType}
          onSidebarOpen={this.handleSidebarOpen}/>
        <Sidebar
          handleMenuButtonClick={this.handleMenuButtonClick}
          pages={this.props.pages}
          updateMainComponent={this.updateMainComponent}
          addTab={this.addTab}
          onClose={this.handleSidebarClose}
          open={true}
          variant={'persistent'}
        />

        <main className={styles.mainContainer}>
          {this.state.mainComponent}
          {this.state.tab.tabs.length > 0 &&
          <div className={styles.tabHeaderContainer}>
            <Tabs
              value={this.state.tab.selectedTab}
              onChange={this.onTabChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              style={{height: '40px', minHeight: '40px'}}
            >
              {this.renderTabOptions()}
            </Tabs>
          </div>
          }

          {this.state.tab.tabs.length > 0 && this.renderTabPages()}

        </main>

        {/*<Footer />*/}
        <div className={styles.footerContainer}>
          <div style={{flexGrow: 1}} title='Versiyon'>V 1.0.0</div>
          <div style={{flexGrow: 1}} title='Vergi Kimlik Numarası'>V 900 XXX XXXX</div>
          {this.props.selectedCompany &&
          <div style={{flexGrow: 1}}
               title={'Çalışılan firma:' + this.props.selectedCompany.companyDesc}>{this.props.selectedCompany.companyName}</div>
          }
          {(this.props.selectedPeriod && this.props.selectedPeriod.periodName) &&
          <div style={{flexGrow: 1}} title='Çalışılan dönem adı'>{this.props.selectedPeriod.periodName}</div>
          }
          {(this.props.selectedPeriod && this.props.selectedPeriod.startDate) &&
          <div style={{flexGrow: 1}}
               title='Çalışılan dönem tarih aralığı'>{this.props.selectedPeriod.startDate} - {this.props.selectedPeriod.endDate}</div>
          }

        </div>

      </MainProvider>

    )
  }
}

const mapStateToProps = (state) => {
  //console.log('(Main.js) mapStateToProps state : ', state);
  return {
    pages: state.pages,
    selectedCompany: state.selectedCompany,
    selectedPeriod: state.selectedPeriod
  };
}

export default connect(mapStateToProps)(Main);
