import React, {useState, useEffect, useRef} from 'react';
import { Checkbox, TextField, Button, Grid, FormControlLabel, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import moment from 'moment-timezone';
import 'moment/locale/tr';
import { Multiselect } from 'multiselect-react-dropdown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import Mesaj from "../../utils/Mesaj";

function CardElevatorDevice({ elevator, availableSecureFloors, closeCallback, headers,parentLoadData }) {
  const [elevatorData, setElevatorData] = useState(elevator);
  const [originalData, setOriginalData] = useState(elevator);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState([]);

  const handleCheckboxChange = (event) => {
    setElevatorData({ ...elevatorData, deleted: !event.target.checked });
  };

  const handleInputChange = (event) => {
    setElevatorData({ ...elevatorData, cardQrReaderSerial: event.target.value });
  };

  const handleFloorsSelect = (selectedList, selectedItem) => {
    setElevatorData({ ...elevatorData, defaultSecureFloors: selectedList.join(',') });
  };

  const formatDate = (date) => {
    if (date === null) return '-';
    moment.locale('tr');
    const formattedDate = moment.tz(date, 'Europe/Istanbul').format('DD.MM.YYYY HH:mm:ss');
    const fromNow = moment.tz(date, 'Europe/Istanbul').fromNow();
    return `${formattedDate} (${fromNow})`;
  };

  const floorList = availableSecureFloors.split(',');

  const askForChanges = () => {
    let changes = [];
    if (elevatorData.cardQrReaderSerial !== originalData.cardQrReaderSerial) {
      changes.push(`Cihaz seri numarasını ${originalData.cardQrReaderSerial} iken ${elevatorData.cardQrReaderSerial} olarak güncelleyeceksiniz.`);
    }
    if (elevatorData.defaultSecureFloors !== originalData.defaultSecureFloors) {
      changes.push(`Seçili katları ${originalData.defaultSecureFloors} iken ${elevatorData.defaultSecureFloors} olarak güncelleyeceksiniz.`);
    }
    if (changes.length > 0) {
      setChanges(changes);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    const body=[elevatorData];
    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/update-elevators-default-secure-floors', 'POST', headers, JSON.stringify(body));
    setLoading(false);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Secure kat bilgileri güncellenirken bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Secure kat bilgileri güncellenirken Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }
    setOriginalData(elevatorData);
    parentLoadData();
    setOpen(false);

    await Mesaj.show('İşlem başarılı!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);

  };

  const goBack = () => {
    console.log('Go back');
    closeCallback();
  };

  useEffect(() => {
    setOriginalData(elevator);
    setElevatorData(elevator);
  }, [elevator]);

  const multiSelectRef = useRef();

  const selectAllFloors = () => {
    //multiSelectRef.current.selectAll();
    setElevatorData({ ...elevatorData, defaultSecureFloors: floorList.join(',') });
  }

  const clearAllFloors = () => {
    //multiSelectRef.current.removeAllSelectedValues();
    setElevatorData({ ...elevatorData, defaultSecureFloors: '' });
  }

  return (
    <div style={{ overflowY: 'scroll', height: 'calc(100vh - 100px)', backgroundColor: elevatorData.deleted ? '#f8d7da' : '#ffffff' }}>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={6}>
          <TextField
            label="Asansör Adı"
            value={elevatorData.elevatorName}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={!elevatorData.deleted} onChange={handleCheckboxChange} />}
            label={elevatorData.deleted ? <Typography color="error">Pasif</Typography> : "Aktif"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Kart Okuyucu Seri Numarası"
            value={elevatorData.cardQrReaderSerial}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Multiselect
            ref={multiSelectRef}
            options={floorList}
            selectedValues={elevatorData.defaultSecureFloors ? elevatorData.defaultSecureFloors.split(',') : null}
            onSelect={handleFloorsSelect}
            onRemove={handleFloorsSelect}
            isObject={false}
            placeholder="Katları seçin"
            style={{ multiselectContainer: { width: '100%' } }}
          />
          <Button variant="contained" size={'small'} color="primary" onClick={selectAllFloors}>
            TÜM KATLARI SEÇ
          </Button>
          <Button variant="contained" size={'small'} color="secondary" onClick={clearAllFloors} style={{marginLeft:'10%'}}>
            TÜM SEÇİMLERİ KALDIR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<SaveIcon />}
            onClick={askForChanges}
            disabled={loading}
            variant="contained"
            color="primary"
          >
            BİLGİLERİ GÜNCELLE
          </Button>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={goBack}
            style={{ marginLeft: '10px' }}
            variant="contained"
          >
            GERİ
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bağlantı Zamanı"
            value={formatDate(elevatorData.lastConnectTime)}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Kart Okutma Zamanı"
            value={formatDate(elevatorData.lastDataTime)}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bağlantı Problemi Bildirme Zamanı"
            value={formatDate(elevatorData.lastConnectProblemNotifiedTime)}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son İhlal Bildirme Zamanı"
            value={formatDate(elevatorData.lastViolationNotifiedTime)}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen IP"
            value={elevatorData.lastInformedIp}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen Bellek Kullanımı"
            value={elevatorData.lastInformedMemoryUsage}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen CPU Sıcaklığı"
            value={elevatorData.lastInformedCpuTemperature}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen Boş Disk Alanı"
            value={elevatorData.lastInformedFreeDisk}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen SDK Versiyonu"
            value={elevatorData.lastInformedSdkVersion}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen Versiyon"
            value={elevatorData.lastInformedVersion}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Son Bilinen CPU Kullanımı"
            value={elevatorData.lastInformedCpuUsage}
            disabled
            fullWidth
            InputProps={{ style: { color: 'black' } }}
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{"Değişiklikleri onaylayın"}</DialogTitle>
        <DialogContent>
          {changes.map((change, i) => <Typography key={i}>{change}</Typography>)}
          <Typography style={{ marginTop: '10px' }}>Emin misiniz?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Onayla
          </Button>
        </DialogActions>
      </Dialog>

      {loading && <CircularProgress style={{ top: '50%', left: '50%', position: 'absolute', zIndex: 9000 }} />}
    </div>
  );
}

export default CardElevatorDevice;
