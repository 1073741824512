import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  NotFound as NotFoundView,
  SignIn as SignInView,
  SignUp as SignUpView
} from './views';
import LoginUtil from './utils/LoginUtil';
import ElevatorCall from './components/ElevatorCall/ElevatorCall';
import ElevatorSecureCall from './components/ElevatorCall/ElevatorSecureCall';
import Order from './components/Order/Order';
import Util from './utils/CommonUtil';
import CardQrGenerate from './components/CardQr/CardQrGenerate';
import CardQrAdmin from './components/CardQr/CardQrAdmin';
import ElevatorAdmin from './components/ElevatorCall/ElevatorAdmin';
import CardQrLog from './components/CardQr/CardQrLog';
import RobotRemoteProject from './components/RobotRemoteProject/index';
import RobotLayout from 'layouts/RobotRemoteLayout';
import CardQrDeviceInfo from './components/CardQr/CardQrDeviceInfo';
import ElevatorAdminDubai from "./components/ElevatorCall/ElevatorAdminDubai";


const Routes = () => {
  // if (!LoginUtil.isLoggedIn()) {
  //   return (
  //     <Redirect
  //       to="/sign-up"
  //     />);
  // }

  return (
    <Switch>
      <RouteWithLayout
        component={SignInView}
        layout={MinimalLayout}
        path="/sign-in/:product?"
      />
      {/*<RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />*/}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <RouteWithLayout
        component={CardQrGenerate}
        exact
        layout={MinimalLayout}
        path="/card-qr"
      />

      <RouteWithLayout
        component={CardQrLog}
        exact
        layout={MinimalLayout}
        path="/card-qr-log"
      />

      <RouteWithLayout
        component={CardQrDeviceInfo}
        exact
        layout={MinimalLayout}
        path="/card-qr-device-list"
      />

      <RouteWithLayout
        component={CardQrAdmin}
        exact
        layout={MinimalLayout}
        path="/card-qr-admin"
      />

      
      <RouteWithLayout
        component={ElevatorAdminDubai}
        exact
        layout={MinimalLayout}
        path="/elevator-dubai-asd123ghj234ghj12asdsadhkj132"
      />
      

      {!LoginUtil.isLoggedIn() ? <Redirect to="/sign-in"/> : null}
      {/*Login olunarak girilmesi gereken ekranlar buradan sonrasına yazılacak*/}
      {/*{PageAndMenuUtil.getPageRouteDefinitions()[0]}*/}


      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      {(Util.isElevatorUser() || Util.isAdminUser()) && (
        <RouteWithLayout
          component={ElevatorCall}
          exact
          layout={MinimalLayout}
          path="/elevator2"
        />
      )}

      {(Util.isElevatorUser() || Util.isAdminUser()) && (
        <RouteWithLayout
          component={ElevatorAdmin}
          exact
          layout={MinimalLayout}
          path="/elevator"
        />
      )}

      {(Util.isZorluUser() || Util.isAdminUser()) && (
        <RouteWithLayout
          component={Order}
          exact
          layout={MinimalLayout}
          path="/zorlu"
        />
      )}

      {/*      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />*/}
      <RouteWithLayout
        component={RobotRemoteProject}
        exact
        layout={RobotLayout}
        path="/robot-remote"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
