import React, {Component} from 'react';
import Util from "../../utils/CommonUtil";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import styles from './CardQrGenerate.module.css'

import LoginUtil from "../../utils/LoginUtil";
import InputForm from "../InputForm/InputForm";
import {Consts} from "../../const/Consts";
import Mesaj from "../../utils/Mesaj";
import Grid from "../Grid/Grid";
import moment from "moment";
import CardQrLogin from "./CardQrLogin";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardQrUtil from "./CardQrUtil";


class CardQrLog extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR,
      /*cellClass: params => {
        return params.data.operatorOperation ? 'custom-cell-red' : ''
      }*/
    };

    this.state = {
      inputParams:{
        logStart: {
          label: 'Başlangıç Tarihi',
          type: 'datetime-custom',
          defaultValue: moment().add(-1, "day"),
          /*dateType: 'date',*/
          nullable: true
        },
        logEnd: {
          label: 'Bitiş Tarihi',
          type: 'datetime-custom',
          defaultValue: moment().add(0, "day"),
          /*dateType: 'date',*/
          nullable: true
        },
        eventType: {
          label: 'Tanımlama Tipi', type: 'radio', defaultValue: '*', title: 'Log türü seçimi',
          options: [
            {label: 'Tümü', value: '*'},
            {label: 'Kart Okutma', value: 'CARD_READ_SUCCESS'}
          ]
        },
        projectId: {
          label: 'Proje (Blok)', type: 'radio', defaultValue: 1, title: 'Proje seçiniz',
          options: [
            {label: 'A Blok', value: 1},
            {label: 'B Blok', value: 4},
            {label: 'C Blok', value: 6}
          ]
        },
      },
      formData: null,
      gridRowData: [],
      token:sessionStorage.getItem('cardqr-token'),
      loading:false
    }


  }

  async componentDidMount() {
    this.state.formData= FormService.createInitialFormState(this.state.inputParams);
    this.loadData();
    this.inputParamKeys=Object.keys(this.state.inputParams);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
   /* let paramChanged=false;
    for(const key of this.inputParamKeys) {
      if(prevState.formData[key].value !== this.state.formData[key].value )
        paramChanged=true;
    }
    if(paramChanged)
      this.loadData();*/
  }

  checkChangeFormData=(oldFormData,newFormData)=> {
    let paramChanged=false;
   for(const key of this.inputParamKeys) {
     if(oldFormData[key].value !== newFormData[key].value )
       paramChanged=true;
   }
   return paramChanged;
  }

  loadData = async () => {
    console.log('loadData istek gitti');
    this.setState({loading: true});

    const logListRequestModel= {
      projectId:this.state.formData.projectId.value,
      eventType:this.state.formData.eventType.value,
      logStart:this.state.formData.logStart.value,
      logEnd:this.state.formData.logEnd.value
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/log/list-for-panel', 'POST', this.getHeaders(), JSON.stringify(logListRequestModel));
    this.setState({loading: false});
    console.log('loadData isteğe cevap geldi apiRes,',apiRes);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    const jsonList = Util.prepareRowFromHeaderAndRows(apiRes.resultModel.headers, apiRes.resultModel.rows);
    const colDefs= apiRes.resultModel.headers.map( header => {

      return {
        field: header.name
      }
    } )

    this.setState({
      colDefs:colDefs,
      gridRowData: jsonList
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();

  }

  formDataUpdater = (formData) => {
    this.state.formData=formData;
    this.setState({formData});

    //TODO değişim koşuluna göre düzenlenecek.
    this.loadData();

  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';
    return null;
  }
  onRowDoubleClicked = (event) => {
    console.log('TODO onRowDoubleClicked event',event);
  }

  loginSuccess=(token)=> {
    const that=this;
    this.setState({token:token}, function () {
      that.loadData();
    } )
  }

  logout=()=> {
    this.setState({token:null},function () {
      CardQrUtil.cardQrLogout();
    });
  }

  getHeaders=()=> {
    return {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.state.token
    };
  }

  excelExport = () => {
    const params = {
      fileName: "Tanımlı_Kayıtlar.xlsx",
      sheetName: 'Tanımlı_Kayıtlar',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  render() {
    if(Util.checkNullOrUndefined(this.state.token)) {
      return <CardQrLogin successCallback={this.loginSuccess}  />
    }

    console.log('sessionStorage.getItem("cardqr-profile")',sessionStorage.getItem("cardqr-profile"));
    const profile=JSON.parse(sessionStorage.getItem("cardqr-profile")) ;

    return <div>

      {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

      {this.state.formData &&
      <InputForm
        formData={this.state.formData}
        formDataUpdater={this.formDataUpdater}
        inputParams={this.state.inputParams}
        inputFormColumnWidth={'50%'}
        tabLoop={true}
        customTabKeyCode={13}
      />
      }

      <div className={styles.buttonContainer}>

        <div>
          <input type={"text"} placeholder={"Genel Arama."} style={{"height":"80%","width":"60%"}}
                 onChange={(e) => this.gridApi.setQuickFilter(e.target.value)}/>
        </div>

        <Button
          style={{marginRight: '20px', float: 'right'}}
          variant="contained"
          color="primary"
          size="small"
          onClick={this.excelExport}>
          Excel'e Aktar
        </Button>


      </div>
      <div
        style={{
          height: '500px',
          width: '100%',
          marginTop: '0px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.state.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressDragLeaveHidesColumns={true}
          rowHeight={30}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          getRowClass={this.getRowClass}
          onRowDoubleClicked={this.onRowDoubleClicked}
        />

      </div>

    </div>
  };


}

export default CardQrLog;
