import React, { Component, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getTargets,getFloors,getSites } from '../Utils/configUtil';
import RobotCallModalCss from '../Styles/RobotCallModal.module.css';
import { wsConnectionStatus } from '../RobotEnums';
import { rrLanguage } from '../Utils/rrLanguage';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#151515'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    }
});
const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography style={{color:"#fff"}} variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    style={{color:"#fff"}}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#151515'
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        backgroundColor: '#151515'
    }
}))(MuiDialogActions);





const RobotCallModal = props => {
    const projectConfig = props.projectConfig
    
    const [floor, setFloor] = useState("");
    const [target, setTarget] = useState("");
    const [site, setSite] = useState("");

    const [siteFilter, setSiteFilter] = useState("");
    const [floorFilter, setFloorFilter] = useState("");
    const [targetFilter, setTargetFilter] = useState("");
    const activeTarget = siteFilter + "_" + floorFilter + "_" + targetFilter;

    useEffect(()=>{
        /*TODO: For Production (if)*/
        if(projectConfig!=null){
          if(!('sites' in projectConfig)){
            projectConfig.sites =  {list:[{name:'Main'},{name:'A'}]}
            }  
        }
        
        setSite(getSites(projectConfig != null ? projectConfig.sites.list : []))
    },[projectConfig])

    useEffect(()=>{
        /*TODO: For Production (if)*/
        if(projectConfig!=null){
            if(!('floors' in projectConfig)){
              projectConfig.floors =  {list:[{site:'Main',name:'P3'},{site:'A',name:'1'},{site:'A',name:'2'},{site:'A',name:'3'},{site:'A',name:'4'}]}
            }  
        }

        if(site.length>0){
            setFloor(getFloors(siteFilter,projectConfig != null ? projectConfig.floors.list : []))
        }
        /* TODO: BUG- setTarget resetlemesinden kaynaklı arada targetlar gelmemekte */
        setTarget([])
        const buttons = document.getElementsByClassName(RobotCallModalCss.floorItem)
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(RobotCallModalCss.active)
        }
    },[siteFilter])

    useEffect(()=>{
        if(site.length>0 && floor.length>0){
            setTarget(getTargets(siteFilter,floorFilter,projectConfig != null ? projectConfig.targets.list : []))
        }
    },[floorFilter])

    const SiteOnClick = (e) => {
        setSiteFilter(e.target.innerText)
        //seçili olan site rengi değiştirme işlemi
        const buttons = document.getElementsByClassName(RobotCallModalCss.siteItem)
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(RobotCallModalCss.active)
        }
        e.target.classList.add(RobotCallModalCss.active)
    }
    const FloorOnClick = (e) => {
        setFloorFilter(e.target.innerText)
        //seçili olan kat rengi değiştirme işlemi
        const buttons = document.getElementsByClassName(RobotCallModalCss.floorItem)
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(RobotCallModalCss.active)
        }

        e.target.classList.add(RobotCallModalCss.active)
    }
    const TargetOnClick = (e) => {
        setTargetFilter(e.target.innerText)
        //seçili olan hedef konum rengi değiştirme işlemi
        const buttons = document.getElementsByClassName(RobotCallModalCss.roomItem)
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(RobotCallModalCss.active)
        }

        e.target.classList.add(RobotCallModalCss.active)
    }

    const continueClick = () => {
        if(targetFilter != "" && wsConnectionStatus.OPEN===props.ws.current.readyState) {
            // props.ws.current.send(
            //   JSON.stringify(
            //     props.payloadPub(
            //       /*TODO: topicname ,*/
            //       props.robotId,
            //       activeTarget
            //     )
            //   )
            // );
            console.log(activeTarget)
        }
    }
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.isOpen}
            maxWidth={'md'}
            fullWidth={true}
            >
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose} >
                {props.title}
            </DialogTitle>
            <DialogContent dividers>
                <div className={RobotCallModalCss.floorContainer}>
                
                    <div className={RobotCallModalCss.floorInnerContainer} >
                        {
                            site.length>0 &&
                            site.map((value, key)=>{
                                return <button 
                                onClick={(e) => SiteOnClick(e)}
                                className={RobotCallModalCss.siteItem}
                                key={key}
                            >
                            {value.name}
                            </button>
                            })
                        }
                    </div>
               
                    <div className={RobotCallModalCss.floorInnerContainer} >
                        {
                            floor.length>0 &&
                            floor.map((value, key)=>{
                                return <button 
                                onClick={(e)=>FloorOnClick(e)}
                                className={RobotCallModalCss.floorItem}
                                key={key}
                                >
                                {value.name}
                                </button>
                            })
                        }
                    </div>
                 
                    <div className={RobotCallModalCss.poseInnerContainer} >
                        {
                            target.length>0 &&
                            target.map((value, key)=>{
                                return <button 
                                className={RobotCallModalCss.roomItem}
                                key={key}
                                onClick={(e) => TargetOnClick(e)}
                                >
                                {value.name}
                                </button>
                            })
                        }   
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={continueClick} variant="contained" size="large" style={{backgroundColor:"green",color:"#fff"}}>
                    {rrLanguage.robotCall}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default RobotCallModal;
