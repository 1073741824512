import React, {Component} from 'react';
import Util from "../../utils/CommonUtil";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import styles from './ElevatorCall.module.css'
import {Consts} from "../../const/Consts";
import Mesaj from "../../utils/Mesaj";
import LoginUtil from "../../utils/LoginUtil";
import MqttUtil from "../../utils/MqttUtil";
import LockIcon from '@material-ui/icons/Lock';
import {IconButton} from "@material-ui/core";
import ElevatorCallCell from "./ElevatorCallCell";

const mqtt = require('mqtt')

class ElevatorCall extends Component {
  constructor(props) {
    super(props);
    this.defaultProjectId="1";

    this.inputParams = {
      projectId: {
        label: 'Proje-Id (Skyland:1 Zorlu:2)', type: 'text', defaultValue: this.defaultProjectId, placeholder: 'Project-Id',disabled:true
      },
      groupId: {
        label: 'Group Id (Start from 0)', type: 'text', defaultValue: '3', placeholder: 'Id of group'
      },
      elevatorId: {
        label: 'Elevator Id (Starts from 0, keep empty if AnyElevator is true)',
        type: 'text',
        defaultValue: '0',
        placeholder: ''
      },
      robotId: {
        label: 'RobotId', type: 'text', defaultValue: 'smr01'
      },
      floorName: {
        label: 'FloorName', type: 'text', defaultValue: 'P3'
      },
      anyElevator: {
        label: 'AnyElevator (Assign any available elevator)', defaultValue: 'false', type: 'select',
        options: [
          {label: 'Yes', value: 'true'},
          {label: 'No', value: 'false'}
        ]
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      mqttResponse: '',
      selectedFloorNameForRP1: 'P3',
      selectedFloorNameForRP2: 'P3',
      selectedFloorNameForRP3: 'P3',
      selectedFloorNameForRP4: 'P3',
      selectedFloorNameForRP5: 'P3',
      selectedFloorNameForRP6: 'P3',
      selectedFloorNameForRP7: 'P3',
      selectedFloorNameForRP8: 'P3',
      selectedFloorNameForRP9: 'P3',
      selectedFloorNameForRP10: 'P3',
      selectedFloorNameForRP11: 'P3',
      selectedFloorNameForRP12: 'P3'
    }

    this.group2 = ['P6','P5','P4','P3','P2','P1','P0','T9','T8','T7','T6','T5','T4','T3','T2','L','M','LC','T1','T0','S1','RS','S2','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','S3','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44'];
    this.group3 = ['P6','P5','P4','P3','P2','P1','T9','T6','T4','T2','L','LC','T0','S1','S2','1','3','5','7','9','11','13','15','17','19','21','23','S3','25','26','27','28','29','30','31','32','33','34','35','36','37','38'];
    this.group4 = ['P5','P4','P3','P2','P1','T9','T6','T4','T2','L','LC','T0','S1','S2','1','3','5','7','9','11','13','15','17','19','21','23','S3','25','27','29','31','33','35','37','39','40','41','42','43'];

    //      elevatorInfo: {topic:"elevator/"+this.defaultProjectId+"/info/#", callbackArr: []},
    //       elevatorError: {topic:"elevator/"+this.defaultProjectId+"/error", callbackArr: []}

    this.mqttTopicCallbacks = {
      elevatorInfo: [],
      elevatorError: []
    }
  }

  async componentDidMount() {
    //this.mqttTopicCallbacks.elevatorInfo.callbackArr.push(this.mqttMessageCallback);
    //this.mqttTopicCallbacks.elevatorError.callbackArr.push(this.mqttMessageCallback);
    this.client = await MqttUtil.createClient(this.mqttTopicCallbacks);
  }

  componentWillUnmount() {
    this.client.end();
  }

  mqttMessageCallback = (topic, message) => {
    console.log('Mqtt message received. Topic', topic, 'Message:', message.toString());
    this.setState({mqttResponse: message.toString()});
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  createFloorOption = (floors) => {
    let floorOptions = [];

    floors.forEach((floor, index) => {
      floorOptions.push(<option key={index} value={floor}>{floor}</option>)
    });

    return floorOptions;
  }

  sendCallMqttMessage = (groupId, elevatorId, floorName, event) => {
    const elevatorCommand = {
      groupId: groupId,
      elevatorId: elevatorId,
      floorName: floorName
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.formData.projectId.value+'/command/manuel/call', JSON.stringify(elevatorCommand));
    Mesaj.show('Your request was sent to Elevator Server over Mqtt', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  makeIndependentMqttMsg = (elevatorName,groupId, elevatorId, makeIndependent) => {
    /*
    if (topic.equals("elevator/" + projectId + "/make-independent")) {
                elevatorControlService.makeIndependent(
                        (Integer) jsonMapPayload.get("groupId"),
                        (Integer) jsonMapPayload.get("elevatorId"),
                        (Boolean) jsonMapPayload.get("makeIndependent"));
            }
     */
    if(makeIndependent===true) {
      const sonuc= window.confirm(elevatorName+ ' asansörü Independent (Kilit) moduna alınacak. Emin misiniz?');
      if(!sonuc)
        return;
    }

    const independentCommand = {
      groupId: parseInt(groupId) ,
      elevatorId: parseInt(elevatorId) ,
      makeIndependent: makeIndependent
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.formData.projectId.value+'/make-independent', JSON.stringify(independentCommand));
    Mesaj.show('Your request was sent to Elevator Server over Mqtt', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  onChangeSelect = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    return <div className={styles.mainContainer} style={{overflow: 'auto', height: '89%', position: 'fixed'}}>
      {!Util.isElevatorUser() &&
      <Button
        style={{marginRight: '20px'}}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {window.location.href = "/";}}>
        Anasayfa
      </Button>}
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {LoginUtil.logout();}}>
        Çıkış
      </Button>
      <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', paddingBottom: '10px'}}>
        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP12} elevatorName={'RP12'} groupId={'3'} elevatorId={'2'}  floorArr={this.group4}   mainRef={this} />

        <div style={{width: '40%', textAlign: 'center', padding: '5px', border: '1px solid black'}}/>

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP11} elevatorName={'RP11'} groupId={'3'} elevatorId={'1'} floorArr={this.group4}   mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP10} elevatorName={'RP10'} groupId={'3'} elevatorId={'0'} floorArr={this.group4}   mainRef={this} />

      </div>

      <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', paddingBottom: '10px'}}>

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP9} elevatorName={'RP9'} groupId={'2'} elevatorId={'3'} floorArr={this.group3} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP7} elevatorName={'RP7'} groupId={'2'} elevatorId={'0'} floorArr={this.group3} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP8} elevatorName={'RP8'} groupId={'2'} elevatorId={'2'} floorArr={this.group3} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP6} elevatorName={'RP6'} groupId={'2'} elevatorId={'1'} floorArr={this.group3} mainRef={this} />

      </div>

      <div style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>
        <div style={{width: '40%', textAlign: 'center', padding: '5px', border: '1px solid black'}}/>

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP3} elevatorName={'RP3'} groupId={'1'} elevatorId={'2'} floorArr={this.group2} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP5} elevatorName={'RP5'} groupId={'1'} elevatorId={'4'} floorArr={this.group2} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP2} elevatorName={'RP2'} groupId={'1'} elevatorId={'1'} floorArr={this.group2} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP4} elevatorName={'RP4'} groupId={'1'} elevatorId={'3'} floorArr={this.group2} mainRef={this} />

        <ElevatorCallCell selectedElevatorName={this.state.selectedFloorNameForRP1} elevatorName={'RP1'} groupId={'1'} elevatorId={'0'} floorArr={this.group2} mainRef={this} />

      </div>
    </div>
  };
}

export default ElevatorCall;
