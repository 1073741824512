import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import LoginUtil from "../../../../../../utils/LoginUtil";
import {Consts} from "../../../../../../const/Consts";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));


const Profile = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const profile  = LoginUtil.getProfile();

  if (profile === null) {
    return null;
  }

  /*
  const user = {
    name: profile.name,
    avatar: '/images/avatars/avatar_5.png',
    email: profile.email
  };
  */

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/*<Avatar*/}
      {/*  alt="Person"*/}
      {/*  className={classes.avatar}*/}
      {/*  component={RouterLink}*/}
      {/*  src={user.avatar}*/}
      {/*  to="/settings"*/}
      {/*/>*/}
      <Typography
        className={classes.name}
        variant="h4"
      >
        {profile.name}
      </Typography>
      <Typography variant="body2">{profile.email}</Typography>
      { profile.role===Consts.ROLE_ADMIN &&
        <Typography variant="body2">Admin (Yetkili Kullanıcı)</Typography>
      }

    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
