import React from 'react';
import ReactDOM from 'react-dom';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import './components/Grid/AgGridCommon.css';

//ag grid tema css dosyaları
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine-dark.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import '@ag-grid-community/core/dist/styles/ag-theme-blue.css';
import '@ag-grid-community/core/dist/styles/ag-theme-bootstrap.css';
import '@ag-grid-community/core/dist/styles/ag-theme-dark.css';
import '@ag-grid-community/core/dist/styles/ag-theme-fresh.css';//Ortak css dosyaları buradan import edilmeli.
import './services/Form/Form.css'
import './index.css'
import * as serviceWorker from './serviceWorker';
import App from './App';
import {LicenseManager} from "@ag-grid-enterprise/core";
import {Provider} from "react-redux";
import store from "./redux/store/store";

ReactDOM.render(
  <Provider store={store}> <App/> </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
