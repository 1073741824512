import Mesaj from "../../utils/Mesaj";

const CardQrUtil = {

  getCardQrToken : function () {
    sessionStorage.get('cardqr-token');
  },
  cardQrLogout: function () {
    sessionStorage.removeItem('cardqr-token');
    sessionStorage.removeItem('cardqr-profile');
  },

  arrToCommaSeperatedStr(arr) {
    if(arr===undefined || arr===null || arr.length===0)
      return '';

      return arr.join();

  },
  commaSeperateStrToArr(commaSeperateStr) {
    if(commaSeperateStr===undefined || commaSeperateStr===null || commaSeperateStr==="")
      return [];
    return commaSeperateStr.split(',');
  },
  trUpper(str) {
    return str.toUpperCase().replaceAll('İ','I');
  },
  checkFloorNames(toBeCheckedArrStr, allAvailableFloorNames,preMsg) {
    const upperedArrStr= CardQrUtil.trUpper(toBeCheckedArrStr);
    const arr=  CardQrUtil.commaSeperateStrToArr(upperedArrStr);
    for(const toBeCheckedElem of arr) {
      if(!allAvailableFloorNames.includes(toBeCheckedElem)) {
        Mesaj.show(preMsg+ ' Kat: '+toBeCheckedElem+ ' bulunamadı. Lütfen asansörlerde olan bir kat bilgisi giriniz.', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
        return false;
      }
    }

    return true;
  },
  makeArrTrUpper(arr) {
     return arr.map(arrElem => CardQrUtil.trUpper(arrElem) );
  }
}

export default CardQrUtil;
