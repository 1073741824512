import React, {Component} from 'react';
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "../Grid/Grid";

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.isZorluUser = Util.isZorluUser();

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Ürün',
        field: 'productName'
      },
      {
        headerName: 'Ürün Resmi',
        field: 'imageUrl',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'Fiyat',
        field: 'price'
      },
      {
        headerName: 'Miktar',
        field: 'amount'
      },
      {
        headerName: 'İyzico Ödeme No',
        field: 'paymentTransactionId'
      }
    ];

    this.inputParams = {
      orderId: {
        label: 'Sipariş Id', type: 'text', defaultValue: this.props.orderDetail.orderId, disabled: true
      },
      orderNo: {
        label: 'Sipariş No', type: 'text', defaultValue: this.props.orderDetail.orderNo, disabled: true
      },
      status: {   //Hazırlanıyor, RobotYolda, RobotAsansörde, RobotKapıyaGeldi, TeslimEdildi
        label: 'Durum', defaultValue: this.props.orderDetail.status, type: 'select',
        options: [
          {label: 'Hazırlanıyor', value: 'Hazırlanıyor'},
          {label: 'Robot Yolda', value: 'Robot Yolda'},
          {label: 'Robot Asansörde', value: 'Robot Asansörde'},
          {label: 'Robot Katta', value: 'Robot Katta'},
          {label: 'Robot Kapıya Geldi', value: 'Robot Kapıya Geldi'},
          {label: 'Teslim Edildi', value: 'Teslim Edildi'},
          {label: 'Sipariş Elle Teslim Edildi', value: 'Sipariş Elle Teslim Edildi'},
          {label: 'Teslim Edildi', value: 'Teslim Edildi'},
          {label: 'Sipariş İptal Edildi', value: 'Sipariş İptal Edildi'},
          {label: 'Teslim Edilemedi', value: 'Teslim Edilemedi'}
        ]
      },
      robotId: {
        label: 'Robot ID', defaultValue: this.isZorluUser ? 'smr04' : this.props.orderDetail.robotId, type: 'select',
        disabled: this.isZorluUser,
        // options: this.createRobots()
        options: this.props.getRobotOptions()
      },
      orderDate: {
        label: 'Sipariş Tarihi',
        defaultValue: this.props.orderDetail.orderDate,
        type: 'datetime-custom',
        disabled: true
      },
      buyer: {
        label: 'Alıcı', type: 'text', defaultValue: this.props.orderDetail.buyer, disabled: true
      },
      phone: {
        label: 'Telefon', type: 'text', defaultValue: this.props.orderDetail.phone, disabled: true
      },
      projectId: {
        label: 'Proje No', type: 'text', defaultValue: this.props.orderDetail.projectId, disabled: true
      },
      flatNo: {
        label: 'Daire No', type: 'text', defaultValue: this.props.orderDetail.flatNo, disabled: true
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
    };
  }

  async componentDidMount() {
    this.getOrderItems().then();
  }

  /*createRobots = () => {
    const options = [
      {label: '', value: ''},
      {label: 'Sıradaki Robot', value: 'next_robot_id'}
    ]

    this.props.robots.forEach(robot => {
      options.push({label: robot, value: robot});
    });

    return options;
  }*/

  getOrderItems = async () => {
    this.setState({loading: true});

    const body = {
      orderId: this.props.orderDetail.orderId,
      projectId: this.props.projectId
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/order/get-order-details',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();
  }

  updateOrderStatus = async () => {
    this.setState({loading: true});

    const body = {
      orderId: this.props.orderDetail.orderId,
      status: this.state.formData.status.value,
      robotId: this.state.formData.robotId.value,
      projectId: this.props.projectId
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/order/update-order-status',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
    } else
      Mesaj.show('Sipariş durumu güncellendi.', 'Başarılı', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  render() {
    return <div style={{padding: '10px'}}>
      <div style={{display: 'flex', flexWrap: 'wrap', height: '180px'}}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />

        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.updateOrderStatus}
          >
            Güncelle
          </Button>

          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.props.onClose}
            style={{marginLeft: '20px'}}
          >
            Geri
          </Button>
        </div>

      </div>

      <div
        style={{
          height: '300px',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressContextMenu={true}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          summaryBar={false}
        >
        </Grid>
      </div>
    </div>
  };
}

export default OrderDetail;
