export const Consts = {
  ROLE_ADMIN:'admin',
  ROLE_MODERATOR:'moderator',
  ROLE_ELEVATOR_USER:'elevator_user',
  RESULT_SUCCESS:'Success',
  RESULT_ERROR :'Error',
  ROLE_RR_VIEW_ONLY:'rr_view_only',
  ROLE_RR_ADMIN:'rr_admin',
  ROLE_RR_PROJECT_ADMIN:'rr_project_admin',
  ROLE_RR_SYSTEM_ADMIN:'rr_system_admin',
}

