import React, {Component} from 'react';
import styles from './SalesSummary.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";

class SalesSummary extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR,
      cellClass: params => {
        return params.data.operatorOperation ? 'custom-cell-red' : ''
      }
    };

    this.colDefs = [
      {
        headerName: 'Ürün/Menü',
        field: 'itemName',
      },
      {
        headerName: 'Satılan Adet',
        filter: 'agNumberColumnFilter',
        field: 'salesAmount'
      },
      {
        headerName: 'KDV Dahil Birim Fiyat',
        filter: 'agNumberColumnFilter',
        field: 'unitPrice'
      },
      {
        headerName: 'KDV Oranı',
        filter: 'agNumberColumnFilter',
        field: 'kdv'
      },
      {
        headerName: 'KDV Dahil Toplam Tutar',
        filter: 'agNumberColumnFilter',
        field: 'totalPrice'
      },
      {
        headerName: 'KDV Hariç Toplam Tutar',
        filter: 'agNumberColumnFilter',
        field: 'priceWithoutKdv'
      }
    ];

    this.inputParams = {
      beginDate: {
        label: 'Başlangıç Tarihi',
        defaultValue: '',
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      finishDate: {
        label: 'Bitiş Tarihi',
        defaultValue: '',
        type: 'datetime-custom',
        dateType: 'date',
        nullable: true
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      totalKDV: []
    }
  }

  loadData = async () => {
    this.setState({loading: true});

    const form = this.state.formData;
    const body = {
      beginDate: form.beginDate.value,
      finishDate: form.finishDate.value,
      projectId: form.projectId.value
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/order/get-items-for-sales-summary',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.calculateTotalKDV(apiRes);

    this.setState({
      gridRowData: this.prepareRowData(apiRes)
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();
  }

  calculateTotalKDV = (list) => {
    let totalKDV = 0;
    let totalKDV1 = 0;
    let totalKDV10 = 0;
    let totalKDV20 = 0;

    list.forEach(item => {
      let tempPrice = item.totalPrice * item.kdv / 100;

      if (item.kdv === 1)
        totalKDV1 += tempPrice;
      else if (item.kdv === 10)
        totalKDV10 += tempPrice;
      else if (item.kdv === 20)
        totalKDV20 += tempPrice;

      totalKDV += tempPrice;
    });

    this.setState({
      totalKDV: [{
        itemName: 'Toplam KDV Tutarı : ' + totalKDV.toFixed(2),
        salesAmount: 'Toplam %1 KDV Tutarı : ' + totalKDV1.toFixed(2),
        unitPrice: 'Toplam %10 KDV Tutarı : ' + totalKDV10.toFixed(2),
        kdv: 'Toplam %20 KDV Tutarı : ' + totalKDV20.toFixed(2),
      }]
    });
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  excelExport = () => {
    const params = {
      fileName: "Satis-Ozet-Listesi.xlsx",
      sheetName: 'Satis-Ozet-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      if (item.totalPrice)
        item.totalPrice = item.totalPrice.toFixed(2);

      if (item.priceWithoutKdv)
        item.priceWithoutKdv = item.priceWithoutKdv.toFixed(2);

      rowData.push(item);
    });

    return rowData;
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap', height: '120px'}}>
        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />

        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            onClick={this.loadData}
          >
            Sorgula
          </Button>
        </div>

      </div>
      <div>
        <Toolbar handlers={
          [{callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'}]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 250px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered}
          suppressContextMenu={true}
          pinnedBottomRowData={this.state.totalKDV}
        >
        </Grid>
      </div>
    </div>
  };
}

export default SalesSummary;
