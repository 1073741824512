import React, { Component, useEffect , useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Cached from '@material-ui/icons/Cached';
import Typography from '@material-ui/core/Typography';
import Grid from 'components/Grid/Grid';
import Util from 'utils/CommonUtil';
import moment from "moment";
import { modals } from '../RobotEnums';
import { rrLanguage } from '../Utils/rrLanguage'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#151515'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  refreshButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1)
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onRefresh , onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{color:"#fff"}} variant="h6">{children}</Typography>
      <IconButton
        style={{color:"#fff"}}
        className={classes.refreshButton}
        onClick={onRefresh}
      >
        <Cached />
      </IconButton>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          style={{color:"#fff"}}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#151515'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#151515'
  }
}))(MuiDialogActions);

const RobotLedModal = props => {
  const data = props.data;
  const [rowData,setRowData] = useState([]);
  const [columnDefs,setColumnDefs] = useState([]);

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    enableRowGroup: true,
    comparator: Util.GRID_COLUMN_COMPARATOR,
    keyCreator: Util.GRID_GROUP_KEY_CREATOR
  };

  useEffect(()=>{
    if(data!=null && props.title!=modals.NavHistory){
      const dataKey=Object.keys(data[0])
      for(let i=1;i<dataKey.length;i++){
        setColumnDefs(columnDefs => [...columnDefs,
          { field: dataKey[i] ,
            width: 120 ,
            pinned: dataKey[i]=="time" ? 'left' : null,
          }
        ])
      }
      for(let k=0;k<data.length;k++){  
        const dataValue=Object.values(data[k])
        setRowData(rowData => [...rowData, 
          {
            //id:String(dataValue[0]),
            time:moment(dataValue[1]).format('YYYY-MM-DD HH:mm:ss'),
            project_id:String(dataValue[2]),
            robot_id:String(dataValue[3]),
            state:String(dataValue[4]),
            site:String(dataValue[5]),
            floor:String(dataValue[6]),
            alarm_id:String(dataValue[7]),
            alarm_text:String(dataValue[8])
          }
        ])
      }
    }

    else if(data!=null && props.title==modals.NavHistory){
      const dataKey=Object.keys(data[0])
      for(let i=0;i<dataKey.length;i++){
        setColumnDefs(columnDefs => [...columnDefs,
          { field: dataKey[i] ,
            width: 120,
            pinned: dataKey[i]=="timestamp" ? 'left' : null,
          }
        ])
      }
      for(let k=0;k<data.length;k++){
        const dataValue = Object.values(data[k])
        setRowData(rowData => [...rowData, 
          {
            timestamp: moment(dataValue[0]).format('YYYY-MM-DD HH:mm:ss'), //String(dataValue[0]),
            project_id: String(dataValue[1]),
            robot_id: String(dataValue[2]),
            state: String(dataValue[3]),
            battery_percentage: String(dataValue[4]),
            charging: String(dataValue[5]),
            orientationw: String(dataValue[6]),
            orientationz: String(dataValue[7]),
            positionx: String(dataValue[8]),
            positiony: String(dataValue[9]),
            floor_name: String(dataValue[11]),
            order_id: String(dataValue[10]),
          }
        ])
      }
      
    }
  },[data])

  useEffect(()=>{
    setColumnDefs([])
    setRowData([])
  },[props.isOpen,props.modalRefresh])

const onRefreshFunc = () => {
  props.setModalRefresh(!props.modalRefresh)
}

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      maxWidth={'lg'}
      fullWidth={true}
      >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose} onRefresh={onRefreshFunc}>
        {props.title}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{width:"100%",height:400}} id="myGrid" className="ag-theme-alpine-dark">
          <Grid 
            rowData={rowData} 
            columnDefs={columnDefs} 
            defaultColDef={defaultColDef} 
            rowSelection='single'
            columnsSizeAuto={true}
            rowHeight={50}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {rrLanguage.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RobotLedModal;
