import store from "../redux/store/store";
import {SET_ALERT} from "../redux/actions/actionTypes";

const Mesaj = {

  ICON_INFO: 'info',
  ICON_WARN: 'warn',
  ICON_ERROR: 'error',
  ICON_SUCCESS: 'success',
  ICON_QUESTION: 'question',

  BUTTON_OK: 'btnOk',
  BUTTON_OK_CANCEL: 'btnOkCancel',
  BUTTON_ABORT_RETRY_IGNORE: 'btnAbortRetryIgnore',
  BUTTON_YES_NO: 'btnYesNo',
  BUTTON_YES_NO_CANCEL: 'btnYesNoCancel',
  BUTTON_RETRY_CANCEL: 'btnRetryCancel',

  show(message, title, mesajIcon, mesajButtonType, ...events) {

    let buttons = null;
    if (mesajButtonType === this.BUTTON_OK) {
      buttons = [{
        text: 'Tamam (Kısayol Tuşu: Esc)',
        handler: events[0]
      }];
    } else if (mesajButtonType === this.BUTTON_YES_NO) {
      buttons = [
        {text: 'Evet', handler: events[0]},
        {text: 'Hayır', handler: events[1]}
      ];
    } else if (mesajButtonType === this.BUTTON_YES_NO_CANCEL) {
      buttons = [
        {text: 'Evet', handler: events[0]},
        {text: 'Hayır', handler: events[1]},
        {text: 'Vazgeç', handler: events[2]}
      ];
    } else if (mesajButtonType === this.BUTTON_OK_CANCEL) {
      buttons = [
        {text: 'Tamam', handler: events[0]},
        {text: 'Vazgeç', handler: events[1]}
      ];
    } else if (mesajButtonType === this.BUTTON_ABORT_RETRY_IGNORE) {
      buttons = [
        {text: 'İptal', handler: events[0]},
        {text: 'Tekrar Dene', handler: events[1]},
        {text: 'Önemseme', handler: events[2]}
      ];
    } else if (mesajButtonType === this.BUTTON_RETRY_CANCEL) {
      buttons = [
        {text: 'Tekrar Dene', handler: events[0]},
        {text: 'Vazgeç', handler: events[1]}
      ];
    }

    store.dispatch({
      type: [SET_ALERT],
      alert: {
        message: message,
        title: title,
        icon: mesajIcon,
        buttons: buttons
      }
    });

  },

  confirm(message, title, mesajButtonType = Mesaj.BUTTON_YES_NO, ...events) {
    let messageResolve;
    const promise = new Promise(resolve => {
      messageResolve = resolve;
    });

    const closeMessage = function (result) {
      store.dispatch({
        type: [SET_ALERT],
        alert: null
      });
      messageResolve(result);
    };

    let buttons = null;
    if (mesajButtonType === Mesaj.BUTTON_OK) {
      buttons = [{
        text: 'Tamam',
        handler: Mesaj.isObjectEvent(events[0]) ?
          (events[0].event ? [closeMessage.bind(null, true), events[0].event] : closeMessage.bind(null, true)) :
          (events[0] ? [events[0], closeMessage.bind(null, true)] : closeMessage.bind(null, true)),
        focus: Mesaj.isObjectEvent(events[0]) ? events[0].focus : false,
        keys: Mesaj.isObjectEvent(events[0]) ? (events[0].keys ? events[0].keys : ['A', 'a']) : ['A', 'a']
      }];
    } else if (mesajButtonType === Mesaj.BUTTON_YES_NO) {
      buttons = [
        {
          text: 'Evet',
          handler: Mesaj.isObjectEvent(events[0]) ?
            (events[0].event ? [closeMessage.bind(null, true), events[0].event] : closeMessage.bind(null, true)) :
            (events[0] ? [events[0], closeMessage.bind(null, true)] : closeMessage.bind(null, true)),
          focus: Mesaj.isObjectEvent(events[0]) ? (events[0].focus ? events[0].focus : false) : false,
          keys: Mesaj.isObjectEvent(events[0]) ? (events[0].keys ? events[0].keys : ['E', 'e']) : ['E', 'e']
        },
        {
          text: 'Hayır',
          handler: Mesaj.isObjectEvent(events[1]) ?
            (events[1].event ? [closeMessage.bind(null, false), events[1].event] : closeMessage.bind(null, false)) :
            (events[1] ? [events[1], closeMessage.bind(null, false)] : closeMessage.bind(null, false)),
          focus: Mesaj.isObjectEvent(events[1]) ? (events[1].focus ? events[1].focus : false) : false,
          keys: Mesaj.isObjectEvent(events[1]) ? (events[1].keys ? events[1].keys : ['H', 'h']) : ['H', 'h']
        }
      ];
    } else if (mesajButtonType === Mesaj.BUTTON_OK_CANCEL) {
      buttons = [
        {
          text: 'Tamam',
          handler: Mesaj.isObjectEvent(events[0]) ?
            (events[0].event ? [closeMessage.bind(null, true), events[0].event] : closeMessage.bind(null, true)) :
            (events[0] ? [events[0], closeMessage.bind(null, true)] : closeMessage.bind(null, true)),
          focus: Mesaj.isObjectEvent(events[0]) ? (events[0].focus ? events[0].focus : false) : false,
          keys: Mesaj.isObjectEvent(events[0]) ? (events[0].keys ? events[0].keys : ['A', 'a']) : ['A', 'a']
        },
        {
          text: 'İptal',
          handler: Mesaj.isObjectEvent(events[1]) ?
            (events[1].event ? [closeMessage.bind(null, false), events[1].event] : closeMessage.bind(null, false)) :
            (events[1] ? [events[1], closeMessage.bind(null, false)] : closeMessage.bind(null, false)),
          focus: Mesaj.isObjectEvent(events[1]) ? (events[1].focus ? events[1].focus : false) : false,
          keys: Mesaj.isObjectEvent(events[1]) ? (events[1].keys ? events[1].keys : ['P', 'p']) : ['P', 'p']
        }
      ];
    }

    store.dispatch({
      type: [SET_ALERT],
      alert: {
        message: message,
        title: title ? title : 'Soru',
        icon: Mesaj.ICON_QUESTION,
        buttons: buttons
      }
    });

    return promise;
  },
  isObjectEvent(event) {
    return (typeof event === "object");
  }
}

export default Mesaj;
