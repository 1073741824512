import React, { Component, useCallback, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NavigationIcon from '@material-ui/icons/Navigation';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AndroidIcon from '@material-ui/icons/Android';
import { rrLanguage } from '../Utils/rrLanguage';
import { TabContext, TabPanel } from '@material-ui/lab';
import RCcss from '../Styles/RobotControl.module.css';
import config from '../config';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { modals, wsConnectionStatus } from '../RobotEnums';

export default function RobotControl({
  payloadWsGeneric,
  ws,
  robotId,
  navHistory,
  lidState,
  setLidState,
  setRobotCallModal,
  hasPermission,
  enableCmdxz,
  enableCmdxzAssignedUserId,
  userId,
  disabledControl,
  setDisabledControl
}) {
  const [value, setValue] = React.useState('one');
  const [radioCheck, setRadioCheck] = useState('0.1');
  const [cmdX,setCmdX] = useState(0)
  const [cmdZ,setCmdZ] = useState(0)

  let StateText = navHistory.state;
  const scale_cmd_z = 2;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDisabedButton = () => {
    setDisabledControl(!disabledControl);
    if (!disabledControl) {

      payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz, robotId, 'PUBLISH', '1')

      payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz_assigned_uid, robotId, 'PUBLISH', userId)

    } else {
      if(enableCmdxzAssignedUserId!== null){

        payloadWsGeneric(config.pubsTopicNames.cmd_xz, robotId, 'PUBLISH', '0,0')

        payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz_assigned_uid, robotId, 'PUBLISH', '0')

        payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz, robotId, 'PUBLISH', '0')

      }
    }
  };

  const useKeyPress = targetKey => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(true);
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(false);
    };

    useEffect(() => {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }, []);

    return keyPressed;
  };

  const [controlButtons, setControlButtons] = useState({
    wPressed: false,
    aPressed: false,
    sPressed: false,
    dPressed: false
  });

  let w = useKeyPress('w');
  let a = useKeyPress('a');
  let s = useKeyPress('s');
  let d = useKeyPress('d');

  useEffect(() => {
    setControlButtons({
      wPressed: w,
      aPressed: a,
      sPressed: s,
      dPressed: d
    });
  }, [w, a, s, d]);

  //if tab change -> reset process
  useEffect(() => {
    if (value !== 'four') {
      setDisabledControl(false);
      setControlButtons({
        wPressed: false,
        aPressed: false,
        sPressed: false,
        dPressed: false
      });

      if (ws.current !== null && enableCmdxzAssignedUserId!== null) {
        if (enableCmdxzAssignedUserId.toString() === userId) {

          payloadWsGeneric(config.pubsTopicNames.cmd_xz, robotId, 'PUBLISH', '0,0')

          payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz_assigned_uid, robotId, 'PUBLISH', '0')

          payloadWsGeneric(config.pubsTopicNames.enable_cmd_xz, robotId, 'PUBLISH', '0')

        }
      }
    }
  }, [value]);

  //if robot controller active -> data send
  useEffect(() => {
    if (disabledControl && value === 'four' && hasPermission) {
      let cmd_x = 0;
      let cmd_z = 0;
      //FL
      if (
        controlButtons.wPressed &&
        controlButtons.aPressed &&
        !(controlButtons.sPressed || controlButtons.dPressed)
      ) {
        //console.log("FL")
        cmd_x = radioCheck;
        cmd_z = radioCheck * scale_cmd_z;
      }
      //FR
      else if (
        controlButtons.wPressed &&
        controlButtons.dPressed &&
        !(controlButtons.sPressed || controlButtons.aPressed)
      ) {
        //console.log("FR")
        cmd_x = radioCheck;
        cmd_z = -radioCheck * scale_cmd_z;
      }
      //BL
      else if (
        controlButtons.sPressed &&
        controlButtons.aPressed &&
        !(controlButtons.wPressed || controlButtons.dPressed)
      ) {
        //console.log("BL")
        cmd_x = -radioCheck;
        cmd_z = -radioCheck * scale_cmd_z;
      }
      //BR
      else if (
        controlButtons.sPressed &&
        controlButtons.dPressed &&
        !(controlButtons.wPressed || controlButtons.aPressed)
      ) {
        //console.log("BR")
        cmd_x = -radioCheck;
        cmd_z = radioCheck * scale_cmd_z;
      }
      //F
      else if (
        controlButtons.wPressed &&
        !(controlButtons.dPressed || controlButtons.sPressed || controlButtons.aPressed)
      ) {
        //console.log("F")
        cmd_x = radioCheck;
        cmd_z = 0;
      }
      //B
      else if (
        controlButtons.sPressed &&
        !(controlButtons.dPressed || controlButtons.wPressed || controlButtons.aPressed)
      ) {
        //console.log("B")
        cmd_x = -radioCheck;
        cmd_z = 0;
      }
      //L
      else if (
        controlButtons.aPressed &&
        !(controlButtons.dPressed || controlButtons.wPressed || controlButtons.sPressed)
      ) {
        //console.log("L")
        cmd_x = 0;
        cmd_z = radioCheck * scale_cmd_z;
      }
      //R
      else if (
        controlButtons.dPressed &&
        !(controlButtons.sPressed || controlButtons.wPressed || controlButtons.aPressed)
      ) {
        //console.log("R")
        cmd_x = 0;
        cmd_z = -radioCheck * scale_cmd_z;
      } else {
        //console.log("else")
        cmd_x = 0;
        cmd_z = 0;
      }
      setCmdX(cmd_x)
      setCmdZ(cmd_z)

      //console.log(controlButtons.wPressed,controlButtons.sPressed,controlButtons.aPressed,controlButtons.dPressed)
    }

  }, [controlButtons]);

  useEffect(()=>{

    function manualControlDataSend () {
        if (enableCmdxzAssignedUserId.toString() === userId) {
        payloadWsGeneric(
          config.pubsTopicNames.cmd_xz,
          robotId,
          'PUBLISH',
          cmdX + ',' + cmdZ
        )
      }
    }
    
    if(disabledControl){
      const intervalId = setInterval(() => {
        manualControlDataSend()
      }, 200) // in milliseconds
      return () => clearInterval(intervalId)
    }
    
  })

  useEffect(()=>{
      if (enableCmdxzAssignedUserId && enableCmdxzAssignedUserId.toString() !== userId) {
        setDisabledControl(false)
      }
  },[enableCmdxzAssignedUserId])

  return (
    <div>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example">
          <Tab
            value="one"
            label={rrLanguage.autonomy}
            icon={<AndroidIcon />}
            style={{ flex: '1' }}
          />
          <Tab
            value="two"
            label={rrLanguage.navigation}
            icon={<NavigationIcon />}
            style={{ flex: '1' }}
          />
          <Tab
            value="three"
            label={rrLanguage.elevator}
            icon={<EqualizerIcon />}
            style={{ flex: '1' }}
          />
          <Tab
            value="four"
            label={rrLanguage.control}
            icon={<ControlCameraIcon />}
            style={{ flex: '1' }}
          />
        </Tabs>

        <TabPanel value="one" style={{ height: '176px' }}>
          <div className={RCcss.autonomiContainer}>
            <div className={RCcss.buttonsContainer}>
              <button
                className={RCcss.buttons}
                disabled={
                  config.permissionsStateOpenLid.includes(StateText) ? false : true
                }
                style={
                  config.permissionsStateOpenLid.includes(StateText)
                    ? null
                    : { opacity: '.6' }
                }
                onClick={() => {
                  setLidState({ ...lidState, lt: !lidState.lt });
                  // ws.current.send(
                  //   JSON.stringify(
                  //     payloadPub(
                  //       /*TODO: topicname ,*/
                  //       robotId,
                  //       lidState.lt
                  //     )
                  //   )
                  // );
                }}>
                {rrLanguage.leftTopLid} {lidState.lt ? rrLanguage.close : rrLanguage.open}
              </button>
              <button
                className={RCcss.buttons}
                disabled={
                  config.permissionsStateOpenLid.includes(StateText) ? false : true
                }
                style={
                  config.permissionsStateOpenLid.includes(StateText)
                    ? null
                    : { opacity: '.6' }
                }
                onClick={() => {
                  setLidState({ ...lidState, rt: !lidState.rt });
                  // ws.current.send(
                  //   JSON.stringify(
                  //     payloadPub(
                  //       /*TODO: topicname ,*/
                  //       robotId,
                  //       lidState.rt
                  //     )
                  //   )
                  // );
                }}>
                {rrLanguage.rightTopLid}{' '}
                {lidState.rt ? rrLanguage.close : rrLanguage.open}
              </button>
              <button
                className={RCcss.buttons}
                disabled={
                  config.permissionsStateOpenLid.includes(StateText) ? false : true
                }
                style={
                  config.permissionsStateOpenLid.includes(StateText)
                    ? null
                    : { opacity: '.6' }
                }
                onClick={() => {
                  setLidState({ ...lidState, lb: !lidState.lb });
                  // ws.current.send(
                  //   JSON.stringify(
                  //     payloadPub(
                  //       /*TODO: topicname ,*/
                  //       robotId,
                  //       lidState.lb
                  //     )
                  //   )
                  // );
                }}>
                {rrLanguage.leftBottomLid}{' '}
                {lidState.lb ? rrLanguage.close : rrLanguage.open}
              </button>
              <button
                className={RCcss.buttons}
                disabled={
                  config.permissionsStateOpenLid.includes(StateText) ? false : true
                }
                style={
                  config.permissionsStateOpenLid.includes(StateText)
                    ? null
                    : { opacity: '.6' }
                }
                onClick={() => {
                  setLidState({ ...lidState, rb: !lidState.rb });
                  // ws.current.send(
                  //   JSON.stringify(
                  //     payloadPub(
                  //       /*TODO: topicname ,*/
                  //       robotId,
                  //       lidState.rb
                  //     )
                  //   )
                  // );
                }}>
                {rrLanguage.rightBottomLid}{' '}
                {lidState.rb ? rrLanguage.close : rrLanguage.open}
              </button>
            </div>
            <button
              className={RCcss.buttons}
              disabled={
                config.permissionsStateRobotCall.includes(StateText) ? false : true
              }
              style={
                config.permissionsStateRobotCall.includes(StateText)
                  ? null
                  : { opacity: '.6' }
              }
              onClick={() => setRobotCallModal('RobotCall')}>
              {rrLanguage.robotCall}
            </button>
          </div>
        </TabPanel>
        <TabPanel value="two" style={{ height: '176px' }}></TabPanel>
        <TabPanel value="three" style={{ height: '176px' }}></TabPanel>

        <TabPanel
          value="four"
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <div className={RCcss.controlContainer}>
            <button
              style={disabledControl ? { backgroundColor: 'red' } : null}
              className={RCcss.enableDisableControl}
              onClick={handleDisabedButton}
              disabled={!hasPermission}>
              <WarningIcon style={{ color: '#ffcc00' }} />
              {disabledControl ? rrLanguage.disableManualControl : rrLanguage.enableManualControl}
            </button>
            <div className={RCcss.inputRadioButtons}>
              <label>
                <input
                  disabled={!disabledControl}
                  type="radio"
                  value="0.25"
                  checked={radioCheck == '0.25'}
                  onChange={e => setRadioCheck(e.target.value)}
                />
                {rrLanguage.normal}
              </label>
              <label>
                <input
                  disabled={!disabledControl}
                  type="radio"
                  value="0.1"
                  checked={radioCheck == '0.1'}
                  onChange={e => setRadioCheck(e.target.value)}
                />
                {rrLanguage.slow}
              </label>
            </div>
            <div className={RCcss.wasdButtons}>
              <button
                onMouseDown={() =>
                  setControlButtons({ ...controlButtons, wPressed: true })
                }
                onMouseUp={() =>
                  setControlButtons({ ...controlButtons, wPressed: false })
                }
                disabled={!disabledControl}
                className={RCcss.wasd}>
                <ExpandLessIcon />W
              </button>
              <button
                onMouseDown={() =>
                  setControlButtons({ ...controlButtons, aPressed: true })
                }
                onMouseUp={() =>
                  setControlButtons({ ...controlButtons, aPressed: false })
                }
                disabled={!disabledControl}
                className={RCcss.wasd}>
                <ExpandLessIcon style={{ transform: 'rotate(-90deg)' }} />A
              </button>
              <button
                onMouseDown={() =>
                  setControlButtons({ ...controlButtons, sPressed: true })
                }
                onMouseUp={() =>
                  setControlButtons({ ...controlButtons, sPressed: false })
                }
                disabled={!disabledControl}
                className={RCcss.wasd}>
                S
                <ExpandLessIcon
                  style={{
                    transform: 'rotate(180deg)',
                    position: 'absolute',
                    bottom: '0'
                  }}
                />
              </button>
              <button
                onMouseDown={() =>
                  setControlButtons({ ...controlButtons, dPressed: true })
                }
                onMouseUp={() =>
                  setControlButtons({ ...controlButtons, dPressed: false })
                }
                disabled={!disabledControl}
                className={RCcss.wasd}>
                D
                <ExpandLessIcon style={{ transform: 'rotate(90deg)' }} />
              </button>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}
