import React, { Component, useEffect, useState } from 'react';
import Robot from '../../../assets/RobotRemoteImages/robot_image.png';
import RPcss from '../Styles/RobotPayload.module.css'

const RobotPayload = props => {
  const robotState = props.robotState
  
  const [basketsData,setBasketsData]=useState(["","","","","","",""])
  const siteName = "A"

  useEffect(()=>{
  if(robotState.order_id != undefined){
    if(robotState.target_door != undefined){
      let newArr = [...basketsData]
      newArr[0] = "d "+robotState.target_door.toString() 
      setBasketsData(newArr)
    }
   } else setBasketsData(["","","","","","",""])
  
  },[robotState])
 
  return (
    <div className={RPcss.container}>
      <img className={RPcss.robotImage} src={Robot} />
      <div className={RPcss.robotBasketsContainer}>

        {
          basketsData.map((item,key)=>{
           if(key<=3){
             return basketsData[key] != ""
               ?
               <div className={RPcss.robotBasketTekliKutu}>
                 {basketsData[key]}
                 <span className={RPcss.robotBasketSiteName}>
                  {siteName}
                </span>
               </div>
               :
               <div className={RPcss.robotBasketTekliKutuNull}>
               </div>
           }
           else if(key>3){
             return basketsData[key] != ""
               ?
               <div className={key==4 ? RPcss.robotBasket2liKutuUst : key==5 ? RPcss.robotBasket2liKutuAlt : RPcss.robotBasket4lü}>
                 {basketsData[key]}
                 <span className={RPcss.robotBasketSiteName}>
                   {siteName}
                 </span>
               </div>
               :
               null
           }
          })
        }
      </div>
    </div>
  );
};
export default RobotPayload;
