import React from 'react';
import Util from "../../utils/CommonUtil";
import FormService from "./FormService";


const FormEventService = {

  onKeyDown(event, that) {
    const keyCode = event.which || event.keyCode;
    //console.log('onKeyDown shiftKey:'+ event.shiftKey + ' KeyCode:'+keyCode, 'state',that.props.formData,'target:',event.target);

    //9:Tab key
    if (keyCode !== that.props.customTabKeyCode && keyCode !== 9) {
      return;
    }

    const isReverse = event.shiftKey && keyCode === 9;

    const sortedTabIndexes = FormEventService.createSortedTabIndexList(that);

    //Alttaki if içinde bu kontrol vardı, ancak başka formda aynı isimde olabileceğinden sıkıntılıydı iptal edildi !that.props.formData[event.target.name] &&
    const isCurrentFormElement = FormEventService.isCurrentFormElement(that.props.formData, event, sortedTabIndexes);
    //isCustomTabIndexElement Kaydet ve Kapat düğme referansı oluyor. Ancak GMS.net te buralara tabla gelme olayı olmadığı için iptal ediliyor şimdilik
    //const isCustomTabIndexElement=  FormEventService.isCustomTabIndexElement(that, event.target);

    //console.log('isCustomTabIndexElement:'+isCustomTabIndexElement+' isCurrentFormElement:'+isCurrentFormElement);

    //    if (!(isCurrentFormElement || isCustomTabIndexElement) ) {
    if (!isCurrentFormElement) {
      console.log('Formda olmayan bir elemana tıklandı. Çıkılıyor. isCurrentFormElement:' + isCurrentFormElement);
      return;
    }

    const currentElementIndex = FormEventService.currentElementIndex(sortedTabIndexes, event.target);

    //Tab'ın kendiliğinden işlevini iptal et, metot ne diyorsa onu yap.
    if (keyCode === 9) {
      //Fatura Input form gibi yerlerde tab sonraki sıradan devam etmelidir.
      if (that.props.tabLoop === false && currentElementIndex === sortedTabIndexes.length - 1) {
        console.log('Tab tuşu engellenmemiştir.');
        return;
      } else {
        event.preventDefault();
      }
    } else {
      //customTabKeyCode (büyük olasılıkla Enter) tuşuna basıldı, event buradan yönetilecek dışarı çıkmaması için event durduruluyor
      event.stopPropagation();
    }

    if (that.props.formData[event.target.name]) {
      FormEventService.focusElement(currentElementIndex, that, isReverse, sortedTabIndexes);
    } else {
      //CustomTabIndex componentinde event tetiklenmiştir.
      for (let i = 0; i < that.props.customTabElements.length; i++) {
        if (document.activeElement !== that.props.customTabElements[i].current) {
          continue;
        }

        //Buraya gelindiğinde i.eleman eventin tıklandığı elemandır.
        if (i + 1 === that.props.customTabElements.length) {
          //En son customTabIndex elemanında olunduğundan ilk form inputuna geçilmelidir.
          that.props.formData[Object.keys(that.props.formData)[0]].reactRef.current.focus();
        } else {
          that.props.customTabElements[i + 1].current.focus();
        }
        return;

      }


    }
  },
  focusElement(curElementIndex, that, isReverse, sortedTabIndexes) {

    let toBeFocusedIndex = isReverse === false ? curElementIndex + 1 : curElementIndex - 1;
    if (toBeFocusedIndex < 0) {
      toBeFocusedIndex += sortedTabIndexes.length;
    } else if (toBeFocusedIndex >= sortedTabIndexes.length) {
      toBeFocusedIndex = 0;
    }

    sortedTabIndexes[toBeFocusedIndex].reactRef.current.focus();
  },
  isCustomTabIndexElement(that, target) {

    if (!that.props.customTabElements) {
      return false;
    }

    for (let i = 0; i < that.props.customTabElements.length; i++) {
      if (that.props.customTabElements[i].current === target) {
        return true;
      }
    }

    return false;
  },

  currentElementIndex(sortedTabIndexes, targetElement) {
    for (let i = 0; i < sortedTabIndexes.length; i++) {
      if (targetElement === sortedTabIndexes[i].reactRef.current) {
        return i;
      }
    }
    return null;
  },

  focusForInitialLoad(that) {
    that.props.formData[Object.keys(that.props.formData)[0]].reactRef.current.focus();
  },
  isCurrentFormElement(formStateElements, event, sortedTabIndexes) {

    for (let i = 0; i < sortedTabIndexes.length; i++) {
      if (sortedTabIndexes[i].reactRef.current === event.target) {
        return true;
      }

    }

    /*const keyArray=Object.keys(formStateElements);
    for (let i = 0; i < keyArray.length; i++) {
      if (formStateElements[keyArray[i]].reactRef.current === event.target) {
        return true;
      }

    }*/

    return false;
  },


  createSortedTabIndexList(that,) {
    const tabIndexes = [];
    Object.keys(that.props.formData).forEach((loopedElemName, index) => {

      if (Object.keys(that.props.inputParams[loopedElemName]).length === 0) {
        return;
      }

      //Disable ise tab listesine hiç girmeyecek. Disable koşulları dinamik olduğu için bu liste her tab işleminde kontrol edilmeli.
      if (Util.isTrue(FormService.prepareDisableProperty(that, that.props.inputParams[loopedElemName]))) {
        return;
      }

      //html tipindeki input form elementlerinde tabIndex olmuyor.
      if (!that.props.formData[loopedElemName].tabIndex) {
        return;
      }

      if (typeof that.props.formData[loopedElemName].tabIndex === 'object') {
        //tabIndex obje ise bu radio öğesidir. Optionları için ayrı ayrı tab oluşmuştur.
        const optionsTabIndexes = that.props.formData[loopedElemName].tabIndex;
        Object.keys(optionsTabIndexes).forEach((key, index) => {
          tabIndexes.push({
            elementName: optionsTabIndexes[key].optionName,
            tabIndex: optionsTabIndexes[key].optionTabIndex,
            reactRef: optionsTabIndexes[key].reactRef
          });
        });
      } else {
        tabIndexes.push({
          elementName: loopedElemName,
          tabIndex: that.props.formData[loopedElemName].tabIndex,
          reactRef: that.props.formData[loopedElemName].reactRef
        });
      }

    });

    tabIndexes.sort(Util.compareByField.bind(null, 'tabIndex'));
    return tabIndexes;
  }
}

export default FormEventService;
