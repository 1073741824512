import React, {Component} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import trLocale from 'date-fns/locale/tr';
import styles from './Calendar.module.css'
import CustomToolbar from './CustomToolbar'
import moment from 'moment';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isArrowkey: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.open === true && this.state.open === false) {
      setTimeout(() => {
        this.props.reactRef.current.focus()
      }, 100);
    }
  }

  componentDidMount() {
    for (const el of document.querySelectorAll('[placeholder][data-slots]')) {
      const pattern = el.getAttribute('placeholder'),
        slots = new Set(el.dataset.slots || '_'),
        prev = (j => Array.from(pattern, (c, i) => slots.has(c) ? j = i + 1 : j))(0),
        first = [...pattern].findIndex(c => slots.has(c)),
        accept = new RegExp(el.dataset.accept || '\\d', 'g'),
        clean = input => {
          input = input.match(accept) || [];
          return Array.from(pattern, c =>
            input[0] === c || slots.has(c) ? input.shift() || c : c
          );
        },
        format = () => {
          const [i, j] = [el.selectionStart, el.selectionEnd].map(i => {
            i = clean(el.value.slice(0, i)).findIndex(c => slots.has(c));
            return i < 0 ? prev[prev.length - 1] : back ? prev[i - 1] || first : i;
          });
          el.value = clean(el.value).join``;
          el.setSelectionRange(i, j);
          back = false;
        };
      let back = false;
      el.addEventListener('keydown', (e) => back = e.key === 'Backspace');
      el.addEventListener('input', format);
      el.addEventListener('focus', format);
      el.addEventListener('blur', () => el.value === pattern && (el.value = ''));
    }
  }

  handleDateChange = (date) => {
    //console.log('handleDateChange : dateString', date);

    if (this.state.open === true && this.state.isArrowKey !== true) {
      date.setHours(0, 0, 0, 0);
      this.props.onChange(this.changeDateToStateStringValue(date));
    }

    if (this.state.isArrowKey === true)
      this.setState({isArrowKey: false})
  }

  inputChange = (event) => {
    //console.log('inputChange');
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13 || keyCode === 9) { //Enter veya tab tuşuna basıldığında
      if (this.props.nullable === false && (event.target.value === '__.__.____ __:__' || event.target.value === '__.__.____')) {
        this.props.onChange(this.props.value);
        return;
      }

      if (event.target.value !== '')
        this.props.onChange(this.changeInputStringValueToStateStringValue(event.target.value));
    } else if (keyCode === 117) { //f6 tuşuna basıldığında
      let newOpenState;

      if (this.state.open === true)
        newOpenState = false;
      else
        newOpenState = true;

      this.setState({open: newOpenState});
      event.preventDefault();
    }
  }

  inputOnChange = (event) => {
    //console.log('inputOnChange');
    this.inputChange(event);
  }

  inputOnKeyDown = (event) => {
    //console.log('inputOnKeyDown');
    this.inputChange(event);
  }

  popKeyDown = (event) => {
    //console.log('popKeyDown event : ', event);
    const keyCode = event.which || event.keyCode;
    if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40)  //ok tuşlarına basıldığında
      this.setState({isArrowKey: true})
    else if (keyCode === 27)  //esc tuşu
      this.setState({open: false});
  }

  // input value dan aldığı string i state value için YYYY-MM-DDTHH:mm bu formatta bir string üretecek
  changeInputStringValueToStateStringValue(inputStringValue) {
    //console.log('changeInputStringValueToStateStringValue => inputStringValue : ' + inputStringValue);

    if (this.props.nullable === true && (inputStringValue === '__.__.____ __:__' || inputStringValue === '__.__.____'))
      return null;

    let calculatedDate = new Date();
    const dateAndTimeArr = inputStringValue.split(' ');

    calculatedDate.setHours(0, 0, 0, 0);

    let dayAndMonthAndYear = dateAndTimeArr[0].split('.');
    if (dayAndMonthAndYear[0] === '__')  //Gün
      return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
    else if (dayAndMonthAndYear[0] > 31 || dayAndMonthAndYear[0] === '0_' || dayAndMonthAndYear[0] === '00') {
      calculatedDate.setDate(1);
      return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
    } else if (dayAndMonthAndYear[0].includes('_'))
      dayAndMonthAndYear[0] = dayAndMonthAndYear[0].replace('_', '');

    if (dayAndMonthAndYear[1] === '__') {  //Ay
      calculatedDate.setDate(dayAndMonthAndYear[0]);
      return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
    } else if (dayAndMonthAndYear[1] > 12 || dayAndMonthAndYear[1] === '0_' || dayAndMonthAndYear[1] === '00') {
      calculatedDate.setDate(dayAndMonthAndYear[0]);
      calculatedDate.setMonth(0);
      return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
    } else if (dayAndMonthAndYear[1].includes('_'))
      dayAndMonthAndYear[1] = dayAndMonthAndYear[1].replace('_', '');

    dayAndMonthAndYear[1] = dayAndMonthAndYear[1] - 1;  // aylar 0 dan başladığı için 1 eksiktmek gerekiyor.

    if (dayAndMonthAndYear[2] === '____') {  //Yıl
      calculatedDate.setDate(dayAndMonthAndYear[0]);
      calculatedDate.setMonth(dayAndMonthAndYear[1]);
      return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
    } else if (dayAndMonthAndYear[2].includes('___'))
      dayAndMonthAndYear[2] = 2000 + Number(dayAndMonthAndYear[2].split('_').join(''));
    else if (dayAndMonthAndYear[2].includes('__'))
      dayAndMonthAndYear[2] = 2000 + Number(dayAndMonthAndYear[2].split('_').join(''));
    else if (dayAndMonthAndYear[2].includes('_'))
      dayAndMonthAndYear[2] = '2000';

    calculatedDate.setFullYear(dayAndMonthAndYear[2], dayAndMonthAndYear[1], dayAndMonthAndYear[0]);

    //time
    if( this.props.dateType === 'date' )
      calculatedDate.setHours(0, 0, 0, 0);
    else{
      const hourAndMinute = dateAndTimeArr[1].split(':');
      if (hourAndMinute[0] === '__')   //Saat
        return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
      else if (hourAndMinute[0] > 23 || hourAndMinute[0] === '0_')
        hourAndMinute[0] = '00';
      else if (hourAndMinute[0].includes('_'))
        hourAndMinute[0] = hourAndMinute[0].replace('_', '');

      if (hourAndMinute[1] === '__') {  //Saat
        calculatedDate.setHours(hourAndMinute[0], 0, 0, 0);
        return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
      } else if (hourAndMinute[1] > 59 || hourAndMinute[1] === '0_') {
        calculatedDate.setHours(hourAndMinute[0], 0, 0, 0);
        return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
      } else if (hourAndMinute[1].includes('_'))
        hourAndMinute[1] = hourAndMinute[1].replace('_', '');

      calculatedDate.setHours(hourAndMinute[0], hourAndMinute[1], 0, 0);
    }

    return this.changeDateToStateStringValue(this.checkMinAndMaxDate(calculatedDate));
  }

  // date nesnesinden state value için YYYY-MM-DDTHH:mm bu formatta bir string üretecek
  changeDateToStateStringValue(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  }

  checkMinAndMaxDate(calculatedDate) {
    if (this.props.min !== null) {
      if (calculatedDate < this.props.min.minDate)
        calculatedDate = this.props.min.minDate;
      else if (this.props.max !== null) {
        if (calculatedDate > this.props.max.maxDate)
          calculatedDate = this.props.max.maxDate;
      }
    }

    if (this.state.open === true)
      calculatedDate.setHours(0, 0, 0, 0);

    return calculatedDate;
  }

  onClose = (event) => {
    //console.log('onClose');
    this.setState({open: false});
  }

  onOpen = (event) => {
    //console.log('onOpen');
    this.setState({open: true});
  }

  iconOnClick = (event) => {
    //console.log('iconOnClick');
    this.setState({open: true});
  }

  inputOnBlur = (event) => {
    //console.log('inputOnBlur');
    if (this.props.nullable === false && (event.target.value === '__.__.____ __:__' || event.target.value === '__.__.____')) {
      this.props.onChange(this.props.value);
      return;
    }

    this.props.onChange(this.changeInputStringValueToStateStringValue(event.target.value));
  }

  inputOnFocus = (event) => {
    //console.log('inputOnFocus');
    this.props.reactRef.current.setSelectionRange(0, this.props.reactRef.current.value.length);
  }

  changeDateStringToInputStringValue(dateString) {   // date => YYYY-MM-DDTHH:mm şeklinde string (değer varsa)
    if (dateString === null || dateString === undefined || dateString === '')
      return this.setPlaceHolder();

    let date = new Date(dateString);
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();

    if( this.props.dateType === 'date' ){
      return day.padStart(3 - day.length, '0') + '.'
        + month.padStart(3 - month.length, '0') + '.'
        + date.getFullYear();
    }else{
      const hour = date.getHours().toString();
      const minute = date.getMinutes().toString();
      return day.padStart(3 - day.length, '0') + '.'
        + month.padStart(3 - month.length, '0') + '.'
        + date.getFullYear() + ' '
        + hour.padStart(3 - hour.length, '0') + ':'
        + minute.padStart(3 - minute.length, '0');
    }
  }

  setPlaceHolder = () => {
    if( this.props.dateType === 'date' )
      return '__.__.____';
    else
      return '__.__.____ __:__';
  }

  render() {
    const ref = this.props.reactRef ? {ref: this.props.reactRef} : null;
    const name = this.props.name ? {name: this.props.name} : null;

    if (this.props.disabled) {
      return (
        <input
          {...ref}
          {...name}
          type='text'
          value={this.changeDateStringToInputStringValue(this.props.value)}
          disabled={true}
        />
      )
    }

    return (
      <div className={styles.dateTimeCustom}>
        <input
          {...ref}
          {...name}
          data-slots='_'
          type='text'
          placeholder={this.setPlaceHolder()}
          value={this.changeDateStringToInputStringValue(this.props.value)}
          onChange={this.inputOnChange}
          onKeyDown={this.inputOnKeyDown}
          onBlur={this.inputOnBlur}
          onFocus={this.inputOnFocus}/>
        <span
          onClick={this.iconOnClick}>
          &#9660;
        </span>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
          <KeyboardDatePicker
            style={{visibility: 'hidden'}}
            {...name}
            variant='inline'
            {...this.props.min}
            {...this.props.max}
            onClose={this.onClose}
            onOpen={this.onOpen}
            open={this.state.open === true ? true : false}
            autoOk={true}
            value={this.props.value === undefined ? null : this.props.value}
            onChange={this.handleDateChange}
            PopoverProps={{onKeyDown: this.popKeyDown}}
            keyboardIcon={null}
            ToolbarComponent={CustomToolbar}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default Calendar;
