import React, {Component} from 'react';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import TextField from "@material-ui/core/TextField";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ElevatorMqttUtil from "../ElevatorCall/ElevatorMqttUtil";

class ServerStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      serverStatus: 'aktif',
      serverStatusMessage: '',
      checkLocation: 'false',
      selectedProjectId: Util.getProjectId(),
      manuelDelivery: 'false',
      manuelDeliveryMinOrderAmount:''
    }

    this.mqttClient = ElevatorMqttUtil.createClient([]).then(mqttClient=> this.mqttClient=mqttClient);
  }

  async componentDidMount() {
    await this.init().then();
  }

  init = async () => {
    this.setState({loading: true});

    const serverInfos = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/get-server-infos',
      'POST', Util.HEADERS_FOR_GET, this.state.selectedProjectId);
    this.setState({loading: false});

    if (serverInfos.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", serverInfos);
      Mesaj.show(serverInfos.resultMessage ? serverInfos.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      serverStatus: serverInfos.status,
      serverStatusMessage: serverInfos.statusMessage,
      checkLocation: serverInfos.checkLocation,
      manuelDelivery:serverInfos.manuelDelivery,
      manuelDeliveryMinOrderAmount:serverInfos.manuelDeliveryMinOrderAmount
    });
  }

  onChangeInput = (event) => {
    this.setState({serverStatusMessage: event.target.value});
  }

  onChangeRadio = async (event) => {
    this.setState({serverStatus: event.target.value});
  }

  onChangeRadioLocation = async (event) => {
    this.setState({checkLocation: event.target.value});
  }

  onChangeRadioManuelDelivery = async (event) => {
    this.setState({manuelDelivery: event.target.value});
  }

  save = async (event) => {
    const body = {
      projectId: parseInt(this.state.selectedProjectId),
      status: this.state.serverStatus,
      statusMessage: this.state.serverStatusMessage,
      checkLocation: this.state.checkLocation,
      manuelDelivery: this.state.manuelDelivery,
      manuelDeliveryMinOrderAmount: this.state.manuelDeliveryMinOrderAmount
    };

    const apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/update-server-infos',
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Başarılı', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  onChangeProject = (event) => {
    // console.log('event.target.value : ', event.target.value, ', event.target.name : '
    //   , event.target.name);
    this.setState({[event.target.name]: event.target.value}, () => {
      this.init().then();
    });
  }

  robotSifirla=async (robotId) => {
    const devam = await Mesaj.confirm(robotId.toUpperCase() + ' robotu sıfırlanacaktır. Teslimat sürecinde olan robotlar için tavsiye edilmez. Devam etmek istiyor musunuz?');
    if (!devam)
      return;

    //robotId='mehmetrobot';

    this.mqttClient.publish(`robots/${this.state.selectedProjectId}/clear-state`, robotId);
    this.mqttClient.publish(`field-manager/${this.state.selectedProjectId}/release/from_all`, robotId);
    //this.mqttClient.publish(`field-manager/${this.state.selectedProjectId}/filling/release`, robotId);
    //this.mqttClient.publish(`field-manager/${this.state.selectedProjectId}/fl0/release`, robotId);

    await Mesaj.show(` ${robotId} robotu için sıfırlama komutları gönderildi. Robot üzerinde kontrol ediniz.`, 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);

  }

  render() {
    return <div style={{
      height: '100%',
      width: '100%',
      paddingTop: '8px',
    }}>
      {this.state.loading &&
      <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

      <div style={{
        height: '550px',
        backgroundColor: 'white',
        marginTop: '15px',
        padding: '10px'
      }}>
        <select
          name='selectedProjectId'
          onChange={this.onChangeProject}
          value={this.state.selectedProjectId}
          disabled={!Util.isAdminUser()}
        >
          <option value="0">Test</option>
          <option value="1">Skyland</option>
          <option value="2">Zorlu</option>
          <option value="3">Kone</option>
        </select>
        <br/>
        <br/>
        <TextField label="Sunucu durum mesajı giriniz." variant="outlined" value={this.state.serverStatusMessage}
                   fullWidth={true}
                   size='small'
                   onChange={this.onChangeInput}
                   type='text'/>
        <br/>
        <RadioGroup value={this.state.serverStatus} onChange={this.onChangeRadio}>
          <FormControlLabel value='aktif' control={<Radio/>} label="Aktif"/>
          <FormControlLabel value='pasif' control={<Radio/>} label="Pasif"/>
        </RadioGroup>
        <br/>
        <p>Lokasyon kontrol edilsin mi?</p>
        <RadioGroup value={this.state.checkLocation} onChange={this.onChangeRadioLocation}>
          <FormControlLabel value='true' control={<Radio/>} label="Evet"/>
          <FormControlLabel value='false' control={<Radio/>} label="Hayır"/>
        </RadioGroup>

        <p>Elle Teslimat Aktif?</p>
        <div style={{display: 'flex'}}>
          <div>
            <RadioGroup value={this.state.manuelDelivery} onChange={this.onChangeRadioManuelDelivery}>
              <FormControlLabel value='true' control={<Radio/>} label="Evet"/>
              <FormControlLabel value='false' control={<Radio/>} label="Hayır"/>
            </RadioGroup>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <label htmlFor="manuelDeliveryMinOrderAmount">Elle teslimatta minimum sipariş tutarı ( TL )</label>
            <input
              id="manuelDeliveryMinOrderAmount"
              type={'number'}
              value={this.state.manuelDeliveryMinOrderAmount}
              onChange={(e)=>this.setState({manuelDeliveryMinOrderAmount:e.target.value})}/>
          </div>
        </div>


        <Button
          disabled={this.state.loading}
          variant={'contained'}
          size="small"
          color="primary"
          onClick={this.save.bind(this)}
        >
          Kaydet
        </Button>
      </div>

      <div style={{
        height: '150px',
        backgroundColor: 'white',
        marginTop: '15px',
        padding: '10px'
      }}>
        { ['smr02','smr03'].map(robotId => { return <Button
          color="primary"
          disabled={this.state.loading}
          onClick={this.robotSifirla.bind(this,robotId)}
          size="small"
          style={{ padding: 5, margin: 2 }}
          variant={'contained'}
        >
          {robotId.toUpperCase()} robotunu sıfırla
        </Button>})}
      </div>


    </div>
  };
}

export default ServerStatus;
