import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  topbarTitle: {
    fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.7rem',
  }
  }
}));

const Topbar = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      //color="primary"
      position="fixed"
      style={{backgroundColor: '#E95F43'}}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            style={{height: '70px',position:'absolute',top:'60px',left:'50%',transform:'translate(-50%,-50%)'}}
            alt="Logo"
            src="/images/logos/speedy_round_logo.png"
          />
        </RouterLink>
        <Typography variant="h1" style={{color:"#fff",fontWeight:"400"}} className={classes.topbarTitle}>
          Speedy Portal
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
