import React, {Component} from 'react';
import Util from "../../utils/CommonUtil";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import styles from './ElevatorCall.module.css'
import {Consts} from "../../const/Consts";
import Mesaj from "../../utils/Mesaj";
import LoginUtil from "../../utils/LoginUtil";
import MqttUtil from "../../utils/MqttUtil";
import LockIcon from '@material-ui/icons/Lock';
import {IconButton} from "@material-ui/core";
import {AiOutlineLock, AiOutlineUnlock} from 'react-icons/ai';
import {MdElevator} from 'react-icons/md';
const mqtt = require('mqtt')

class ElevatorCallCell extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
  }


  render() {
    return <div style={{width: '40%', textAlign: 'center', padding: '5px', border: '1px solid black'}}>
      <div style={{height:'22px',textAlign:'center'}}>
        <label style={{fontWeight:'bold'}}>{this.props.elevatorName}</label>
        <select style={{}} onChange={this.props.mainRef.onChangeSelect} defaultValue={this.props.selectedElevatorName} name={ 'selectedFloorNameFor'+ this.props.elevatorName} >
          {this.props.mainRef.createFloorOption(this.props.floorArr)}
        </select>
        <IconButton color={'primary'} onClick={ ()=> this.props.mainRef.sendCallMqttMessage(this.props.groupId, this.props.elevatorId, this.props.selectedElevatorName)} >
          <MdElevator size={'25'} />
        </IconButton>
      </div>


      <div style={{height:'30px',padding:'20px',textAlign:'center'}}>
        <IconButton color={'secondary'} onClick={ ()=> this.props.mainRef.makeIndependentMqttMsg(this.props.elevatorName,this.props.groupId, this.props.elevatorId, true)} title={'Make elevator Independent'} >
          <AiOutlineLock size={'25'} />
        </IconButton>

        <IconButton color={'primary'} onClick={ ()=> this.props.mainRef.makeIndependentMqttMsg(this.props.elevatorName,this.props.groupId, this.props.elevatorId, false)} title={'Cancel elevator from independent'}>
          <AiOutlineUnlock size={'25'} />
        </IconButton>
      </div>

    </div>
  };
}

export default ElevatorCallCell;
