import React from "react";
import {createStyles} from "@material-ui/styles";
import {Toolbar, withStyles} from "@material-ui/core";
import moment from "moment";

export const styles = theme =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      height: 50,
      minHeight: 50,
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.primary.main
          : theme.palette.background.default
    },
    label: {
      color:
        theme.palette.type === "light"
          ? theme.palette.primary.contrastText
          : theme.palette.background.default
    }
  });

const CustomToolbar = ({date}) => {
  moment.updateLocale('tr', {
    week: {
      dow: 1
    }
  });
  const currentDate = moment(date);
  let weekNumber = currentDate.week();

  return (
    <Toolbar
      className="MuiToolbar-root MuiToolbar-regular MuiPickersToolbar-toolbar MuiPickersDatePickerRoot-toolbar MuiToolbar-gutters">
        <span className="MuiPickersToolbar-label">
            {weekNumber}. Hafta
        </span>
    </Toolbar>
  );
};

export default withStyles(styles, {name: "MuiPickersToolbar"})(CustomToolbar);
