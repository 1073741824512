import Util from "./CommonUtil";
import {Consts} from "../const/Consts";
import Mesaj from "./Mesaj";
import React from "react";

const mqtt = require('mqtt');

const MqttUtil = {
  getOptions: async function () {
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/get-mqtt-connection-info',
      'GET', Util.HEADERS_FOR_GET, null);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    const options = {
      host: apiRes.host,
      username: apiRes.username,
      password: apiRes.password,
      port: parseInt(apiRes.port),
      reconnectPeriod: parseInt(apiRes.reconnectPeriod),
      clientId: Util.createGuid()
    };

    return options;
  },
  createClient: async function (mqttTopicCallbacks) {
    try {
      const subscribeTopics = [];

      Object.keys(mqttTopicCallbacks).forEach(key => {
        if(!Array.isArray(mqttTopicCallbacks[key]))
          subscribeTopics.push(mqttTopicCallbacks[key].topic);
        else
          mqttTopicCallbacks[key].forEach(item => subscribeTopics.push(item.topic));
      });

      const options = await MqttUtil.getOptions();
      const client = mqtt.connect('ws://' + options.host, options);

      client.on('connect', function () {
        console.log('Mqtt client connected to server');
        client.subscribe(subscribeTopics, function (err) {
          if (!err)
            console.log('Subscribe callback worked');
          else {
            console.log('An error occured while connectiong to mqtt server', err);
            alert('Mqtt WebSocket sunucusuna bağlanırken hata oluştu:' + JSON.stringify(err));
          }
        }.bind(this));
      }.bind(this));

      client.on('message', function (topic, message) {
        if (message !== null && message !== undefined && message.length > 0) {
          Object.keys(mqttTopicCallbacks).forEach(key => {
            mqttTopicCallbacks[key].forEach(topicItem => {
              if (topicItem.topic === topic)
                topicItem.callbackArr.forEach(callback => callback(topic, message));
            });
          });
        }
      }.bind(this));

      client.on('error', (err) => console.error('An error occured on mqtt client Err:', err));

      return client;
    } catch (e) {
      Mesaj.show('Mqtt bağlantısı için http ile giriniz!', 'Uyarı', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
      console.error("mqtt bağlantısında bir hata oluştu! Hata : ", e.toString());
    }
  },
  createClientV2: async function (mqttSubscribeTopics,mqttMessageCallback) {
    try {
      const options = await MqttUtil.getOptions();
      const client = mqtt.connect('ws://' + options.host, options);

      client.on('connect', function () {
        console.log('Mqtt client connected to server');
        client.subscribe(mqttSubscribeTopics, function (err) {
          if (!err)
            console.log('Subscribtion is successful');
          else {
            console.log('An error occured while connectiong to mqtt server', err);
            alert('Mqtt WebSocket sunucusuna bağlanırken hata oluştu:' + JSON.stringify(err));
          }
        }.bind(this));
      }.bind(this));

      client.on('message', function (topic, message) {
        console.log('Message received topic:',topic,'message',message);
        mqttMessageCallback(topic,message);
      }.bind(this));

      client.on('error', (err) => console.error('An error occured on mqtt client Err:', err));

      return client;
    } catch (e) {
      Mesaj.show('Mqtt bağlantısı için http ile giriniz!', 'Uyarı', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
      console.error("mqtt bağlantısında bir hata oluştu! Hata : ", e.toString());
    }
  }
}

export default MqttUtil;
