import React from 'react';
import { useEffect, useState } from 'react';
import chooseProject from '../Styles/ChooseProject.module.css';
import Util from '../../../utils/CommonUtil';
import { Consts } from '../../../const/Consts';
import config from '../config';

const Popup = props => {
  const [show, setShow] = useState(true);
  const uniqueProjectIdList = props.uniqueProjectIdList;
  const setProjectId = props.setProjectId;
  const projectNames = props.projectNames;

  const onChangeCheck = e => {
    setProjectId(e.target.value.toString());
    
    props.setActiveProjectName(projectNames[e.target.value.toString()])
  };
  const closeHandler = e => {
    setShow(false);
    props.getRobots();
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
        opacity: show ? '1' : '0'
      }}
      className={chooseProject.overlay}>
      <div className={chooseProject.popup}>
        <h2>Choose your project</h2>
        <span className={chooseProject.close} onClick={closeHandler}>
          &times;
        </span>
        <div className={chooseProject.content}>
          {uniqueProjectIdList.map(element => {
            return (
              <>
                <div>
                  {
                    projectNames.length > 0 ? (
                    <>
                      <input
                        type="radio"
                        id={element}
                        name="check"
                        value={element}
                        onChange={e => onChangeCheck(e)}
                      />
                      <label for={element}>{projectNames[element]}</label>
                    </>
                  ) : null }

                </div>
              </>
            );
          })}
        </div>
        <div className={chooseProject.button}>
          <button className={chooseProject.okeyButton} onClick={closeHandler}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
