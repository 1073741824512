import ModalSub from "./ModalSub";

class ModalBody extends ModalSub {
  static displayName = "ModalBody";

  render() {
    return this.props.children;
  }
}

export default ModalBody;
