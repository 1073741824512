import React, { Component } from 'react';
import moment from 'moment';

export default class ImageCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOver: false,
    };
  }

  onMouseOver = () => {
    this.setState({mouseOver: true})
  }

  onMouseLeave = () => {
    this.setState({mouseOver: false})
  }


  openImage = () => {
    window.open(process.env.REACT_APP_API_GATEWAY_URL + '/images/' + this.props.value);
  }


  render() {

    const imgStyle = {height: this.props.height ? this.props.height : '45px'};

    /*
    if(this.state.mouseOver) {
      imgStyle.width='250px';
      imgStyle.zIndex='9999';
      imgStyle.position='fixed';
    }

     */

    return <a style={{cursor:'pointer'}} onClick={this.openImage}>
      <img
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        style={imgStyle}
        src={process.env.REACT_APP_API_GATEWAY_URL + '/images/' + this.props.value}/>

    </a>
  }
}
