import {Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import React, {useState} from 'react';
import InputForm from '../InputForm/InputForm';
import FormService from '../../services/Form/FormService';
import styles from './CardQrLogin.module.css'
import LoginUtil from '../../utils/LoginUtil';
import Util from '../../utils/CommonUtil';
import {Consts} from '../../const/Consts';
import Mesaj from '../../utils/Mesaj';
import CircularProgress from '@material-ui/core/CircularProgress';
import {RoleConsts} from '../../const/RoleConsts';

// Declaring type of props - see "Typing Component Props" for more examples
type CardQrLoginProps = {
  maxWidth?:string,
  successCallback:"function"
}; /* use `interface` if exporting so that consumers can extend */

export default function CardQrLogin(props:CardQrLoginProps) {

  const previousProjectBranchId= localStorage.getItem('cardqr.previous.projectid_branchid');


  const inputParams= {

    projectBranchId: {
      label: 'Konum Seçimi', defaultValue: previousProjectBranchId ? previousProjectBranchId : '', type: 'select',
      options: [
        {label: 'Lütfen Seçim Yapınız', value: ''},
        {label: 'Skyland A Blok', value: '1_0'},
        {label: 'Skyland B Blok', value: '4_0'},
        {label: 'Skyland C Blok', value: '1_1'},
      ]
    },
    email: {
      label: 'Kullanıcı Adı (E-posta)', type: 'text', defaultValue: '', placeholder: 'E-posta'
    },
    password: {
      label: 'Şifre', type: 'password', defaultValue: '', placeholder: 'Şifre'
    },
  }
  const initialState=FormService.createInitialFormState(inputParams);
  console.log('initialState',initialState);
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const login=async () => {


    const headers = {
      //'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/json'
    }

    const projectBranchId=formData.projectBranchId.value;
    if(!projectBranchId ) {
      Mesaj.show('Lütfen Tanımlama Yapacağınız Lokasyonu Seçiniz!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    const [projectId,branchId]=projectBranchId.split('_');
    const loginModel= {
      projectId: parseInt(projectId),
      branchId:parseInt(branchId),
      email:formData.email.value.trim(),
      password:formData.password.value
    }

    setLoading(true);
    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/access-control/sign-in-with-branch-id',
      'POST', headers, JSON.stringify(loginModel) );

    setLoading(false);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    console.log('apiRes.role',apiRes.role)
    if(apiRes.role!==RoleConsts.ROLE_PROJECT_CARD_QR_ADMIN && apiRes.role!==RoleConsts.ROLE_PROJECT_ADMIN) {
      Mesaj.show('Kart tanımlamak için yetkiniz bulunmamaktadır. Lütfen sistem yöneticisi ile görüşünüz!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    //Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    const token=apiRes.token;
    sessionStorage.setItem('cardqr-token',token);
    delete apiRes.token;
    delete apiRes.result;
    sessionStorage.setItem('cardqr-profile',JSON.stringify(apiRes));

    localStorage.setItem('cardqr.previous.projectid_branchid',projectBranchId);

    props.successCallback(token);
  }

  return  <Dialog
    fullWidth
    maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
    onClose={()=> props.closeHandler}
    open
          >
    {loading &&
    <CircularProgress style={{top: '50%', left: '50%', position: 'absolute', zIndex: 9000}}/>}

    <DialogTitle style={{textAlign: 'center'}}>Sisteme Giriş</DialogTitle>
    <DialogContent className={styles.dialogContent}>

      <InputForm
        customTabKeyCode={13}
        formData={formData}
        formDataUpdater={setFormData}
        inputFormColumnWidth={'100%'}
        inputParams={inputParams}
        tabLoop
      />

      <Button
        color="primary"
        onClick={login}
        size="small"
        style={{textTransform: 'none', width:'40%', marginLeft: '30%',marginTop: '10px'}}
        variant="contained"
      >
        Giriş Yap
      </Button>

    </DialogContent>
  </Dialog>
}
