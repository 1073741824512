import {
  CHANGE_GRID_THEME,
  CHECK_PAGES,
  SET_ALERT,
  SET_SELECTED_COMPANY_PERIOD,
  SHOW_COMPANY_SELECT_MODAL,
  SIGNOUT
} from '../actions/actionTypes'
import {PageAndMenuUtil} from "../../utils/PageAndMenuUtil";
import storageSession from 'redux-persist/lib/storage/session'

const initialState = {
  pages: PageAndMenuUtil.defaultVisiblePages,
  showCompanySelectModal: false,
  selectedCompany: null,
  selectedPeriod: null,
  render: false,
  alert: null,
  selectedGridTheme: 'ag-theme-balham'
};

const rootReducer = (state = initialState, action) => {
  //console.log('rootReducer : state : ', state, ' action : ', action);
  let newState = fillState(state);

  const types = Array.isArray(action.type) ? action.type : [action.type];

  types.forEach(function (type) {
    if (type === SET_ALERT) {
      //console.log('action is SET_ALERT');
      newState.alert = action.alert;
    } else if (type === CHECK_PAGES) {
      //console.log('action is CHECK_PAGES');
      setPagesBySelectedPeriod(newState, action.selectedPeriod);
    } else if (type === SHOW_COMPANY_SELECT_MODAL) {
      //console.log('action is SHOW_COMPANY_SELECT_MODAL');
      newState.showCompanySelectModal = action.showCompanySelectModal;
      newState.render = action.showCompanySelectModal === true ? true : false;
    } else if (type === SET_SELECTED_COMPANY_PERIOD) {
      //console.log('action is SET_SELECTED_COMPANY_PERIOD');
      newState.selectedCompany = action.selectedCompany;
      newState.selectedPeriod = action.selectedPeriod;
      setPagesBySelectedPeriod(newState, action.selectedPeriod);
    } else if (type === SIGNOUT) {
      //console.log('action is SIGNOUT');
      newState = undefined;
      storageSession.removeItem('persist:root');
    } else if (type === CHANGE_GRID_THEME) {
      //console.log('action is CHANGE_GRID_THEME');
      newState.selectedGridTheme = action.selectedGridTheme;
    }
  })
  return newState;
}

const setPagesBySelectedPeriod = (newState, selectedPeriod) => {
  const pages = PageAndMenuUtil.getPageDefinitions(selectedPeriod);
  const toBeCheckedPages = pages && Array.isArray(pages) ? pages : [];
  newState.pages = toBeCheckedPages;
}

const fillState = (state) => {
  const newState = {...state};
  const persistStr = sessionStorage.getItem("persist:root");
  if (persistStr) {
    //console.log("Session storage da persist:root bulunuyor!");
    const persist = JSON.parse(persistStr);
    if (!persist.pages)
      return newState;

    newState.pages = JSON.parse(persist.pages);

    const selectedCompanyTemp = JSON.parse(persist.selectedCompany);
    if (selectedCompanyTemp)
      newState.selectedCompany = selectedCompanyTemp;

    const selectedPeriodTemp = JSON.parse(persist.selectedPeriod);
    if (selectedPeriodTemp)
      newState.selectedPeriod = selectedPeriodTemp;

    newState.showCompanySelectModal = JSON.parse(persist.showCompanySelectModal);
    newState.render = JSON.parse(persist.render);
  }

  return newState;
}

export default rootReducer
