import React, { Component } from 'react';
import moment from 'moment';

export default class DateTimeCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  /*
  moment(lineJson[key], moment.ISO_8601, true).isValid()  )
{
  if (  lineJson[key].length>20){
  lineJson[key]= moment(lineJson[key]).format('DD.MM.YYYY');
*/
  //DD.MM.YYYY hh:mm:ss

  renderVal=()=> {
    if(this.props.value===null || this.props.value===undefined || this.props.value==='')
      return '';

    if(typeof this.props.value === 'string' && this.props.value.length<10) {
      return this.props.value;
    }

    if(this.props.value <1000000000) {
      return this.props.value;
    }

    return moment(this.props.value).format(this.props.format);
  }

  render()  {
    return <label title={'Val:'+this.props.value}>{this.renderVal()}</label>
  }
}
