import React, {Component} from 'react';
import styles from './Product.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import LoginUtil from "../../utils/LoginUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import ImageCellRenderer from "../GridCellRenderers/ImageCellRenderer";
import CancelIcon from '@material-ui/icons/Cancel';
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

class Product extends Component {
  constructor(props) {
    super(props);

    const categoryColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.categoryId',
        hide: true
      },
      {
        headerName: 'Kategori',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Ürün Id',
        field: 'id',
        valueGetter: 'data.productId'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Ürün Adı',
        field: 'name',
        floatingFilter: true,
        filterParams: {
          debounceMs: 900
        }
      },
      {
        headerName: 'Ürün Resmi',
        field: 'imageUrl',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'Açıklama',
        floatingFilter: true,
        field: 'description'
      },
      {
        headerName: 'Fiyat',
        filter: 'agNumberColumnFilter',
        field: 'price'
      },
      {
        headerName: 'İndirimli Fiyat',
        filter: 'agNumberColumnFilter',
        field: 'discountedPrice'
      },
      {
        headerName: 'Stok Miktar',
        filter: 'agNumberColumnFilter',
        field: 'stockAmount'
      },
      {
        headerName: 'Kritik Stok Seviyesi',
        filter: 'agNumberColumnFilter',
        field: 'criticalStockLevel'
      },
      {
        headerName: 'Marka',
        floatingFilter: true,
        field: 'brand'
      },
      {
        headerName: 'Bağlı Olduğu Kategori Adı',
        floatingFilter: true,
        field: 'categoryName'
      },
      {
        headerName: 'Bağlı Olduğu Kategori Id',
        field: 'categoryId'
      },
      {
        headerName: 'Popüler Ürün',
        field: 'popular',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'KDV Oranı',
        filter: 'agNumberColumnFilter',
        field: 'kdv'
      }
    ];

    this.inputParams = {
      name: {
        label: 'Ürün Adı', type: 'text', defaultValue: ''
      },
      description: {
        label: 'Açıklama', type: 'text', defaultValue: ''
      },
      price: {
        label: 'Fiyat', type: 'number', defaultValue: ''
      },
      discountedPrice: {
        label: 'İndirimli Fiyat', type: 'number', defaultValue: ''
      },
      stockAmount: {
        label: 'Stok Miktarı', type: 'number', defaultValue: ''
      },
      criticalStockLevel: {
        label: 'Kritik Stok Seviyesi', type: 'number', defaultValue: ''
      },
      category: {
        label: 'Bağlı Olduğu Kategori', type: 'select', defaultValue: ''
        , options: {
          lazy: false,
          columnDefs: categoryColumnDefs,
          urlEndPoint: "/category/get-categories-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Kategori Seçiniz',
          onSelection: this.onCategorySelection.bind(this)
        },
      },
      brand: {
        label: 'Marka', type: 'auto-complete', defaultValue: '', options: null
      },
      isPopular: {
        label: 'Popüler Ürün', defaultValue: 'false', type: 'radio',
        options: [
          {label: 'Hayır', value: 'false'},
          {label: 'Evet', value: 'true'}
        ]
      },
      kdv: {
        label: 'KDV Oranı', defaultValue: 8, type: 'select',
        options: [
          {label: '0', value: 0},
          {label: '1', value: 1},
          {label: '10', value: 10},
          {label: '20', value: 20}
        ]
      },
      image: {
        label: 'Resim Yükleyin', type: 'image'
      },
      currImage: {
        label: 'Mevcut Resim', type: 'image-viewer'
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    };

    this.inputParamsForUpdatePrice = {
      rate: {
        label: 'Değişim Oranı', type: 'number', defaultValue: ''
      },
      category: {
        label: 'Kategori', type: 'select', defaultValue: ''
        , options: {
          lazy: false,
          columnDefs: categoryColumnDefs,
          urlEndPoint: "/category/get-categories-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Kategori Seçiniz',
          onSelection: this.onCategoryForUpdatePriceSelection.bind(this)
        },
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      formDataForUpdatePrice: FormService.createInitialFormState(this.inputParamsForUpdatePrice),
      gridColumnDefs: [],
      gridRowData: [],
      editData: null,
      dontShowPassiveProduct: false,
      showUpdatePriceDialog: false
    };

    this.gridSettingJson = localStorage.getItem('productGridSetting');
  }

  onCategorySelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].categoryId;
    formData[parentItemName].label = selectedRow[0].name;
    this.setState({formData: formData});
  }

  onCategoryForUpdatePriceSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formDataForUpdatePrice = {...this.state.formDataForUpdatePrice};
    formDataForUpdatePrice[parentItemName].showPopModal = false;
    formDataForUpdatePrice[parentItemName].value = selectedRow[0].categoryId;
    formDataForUpdatePrice[parentItemName].label = selectedRow[0].name;
    this.setState({formDataForUpdatePrice: formDataForUpdatePrice});
  }

  async componentDidMount() {
    await this.getBrands().then(brands => this.inputParams.brand.options = brands);
    await this.loadData().then();
    setInterval(this.refreshList, 60_000); // 1 dk da bir sayfa kendini yenileyecek.
  }

  loadData = async () => {
    this.setState({loading: true});
    let apiRes;

    const projectId = Util.isAdminUser() ? this.state.formData.projectId.value : Util.getProjectId();

    const body = {
      projectId: projectId,
      deleted: this.state.dontShowPassiveProduct
    }

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL +
      '/product/list-for-panel-with-project-id-and-deleted', 'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!',
        'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    })

    if (!this.gridSettingJson)
      this.gridApi.sizeColumnsToFit();
  }

  getBrands = async () => {
    const body = {};
    body.parameters = Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()];

    const apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL +
      '/product/get-brand-list-by-project-id', 'Post', Util.HEADERS_FOR_GET, JSON.stringify(body));
    return apiRes;
  }

  close = (event) => {
    this.props.closeTab(event, this.props.tabId);
  }

  askForDeleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();

    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show(selectedData[0].data.name + ' Ürününü ' + (makePassive ? 'pasifleştirmek' : 'silmek') +
        ' istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this, makePassive));
  }

  deleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();
    let currentRowIndex = 0;
    selectedData.forEach((node) => {
      currentRowIndex = node.rowIndex - 1;
      this.delData(node.data.productId, makePassive, currentRowIndex).then();
    });
  }

  async delData(id, makePassive, rowIndex) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("makePassive", makePassive ? 'true' : 'false');

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/product/del-product',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!',
        'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  async makeActive(productId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("productId", productId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/product/activate-product',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!',
        'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  refreshList = () => {
    this.loadData().then();
  }

  excelExport = () => {
    const params = {
      fileName: "Ürün-Listesi.xlsx",
      sheetName: 'Ürün-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.gridSettingJson)
      this.gridColumnApi.setColumnState(JSON.parse(this.gridSettingJson));
  };

  saveGridSetting = () => {
    localStorage.removeItem('productGridSetting');
    let savedState = this.gridColumnApi.getColumnState();
    localStorage.setItem('productGridSetting', JSON.stringify(savedState));
    Mesaj.show('Tablo ayarları kayıt edildi!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  onFirstDataRendered = params => {
    if (!this.gridSettingJson)
      params.api.sizeColumnsToFit();
  };

  prepareRowData(list) {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  formDataForUpdatePriceUpdater = (formDataForUpdatePrice) => {
    this.setState({formDataForUpdatePrice});
  }

  addEditProduct = async () => {
    if (!this.state.formData.name.value) {
      Mesaj.show('Ürün adı boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.name.value.length > 100) {
      Mesaj.show('Ürün adı 100 karakterden fazla olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.category.value) {
      Mesaj.show('Ürünün hangi kategoriye bağlı olacağını seçmelisiniz.', 'Uyarı', Mesaj.ICON_WARN,
        Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.editData && !this.state.formData.image.value) {
      Mesaj.show('Lütfen bir dosya yükleyiniz', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.price.value) {
      Mesaj.show('Fiyat boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.discountedPrice.value &&
      (parseInt(this.state.formData.price.value) < parseInt(this.state.formData.discountedPrice.value))) {
      Mesaj.show('İndirimli fiyat, fiyattan daha büyük olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    let nameExist = false;
    const that = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      if (!that.state.editData &&
        rowNode.data.name.toString().toLocaleLowerCase('tr') ===
        that.state.formData.name.value.toLocaleLowerCase('tr')
        && rowNode.data.name.brand !== that.state.formData.brand
      )
        nameExist = true;
    });

    if (nameExist) {
      Mesaj.show('Bu isimde bir ürün aynı marka altında daha önceden oluşturulmuş', 'Uyarı',
        Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!await Mesaj.confirm('Ürün ekleme/güncelleme işlemi yapılacaktır! Emin misiniz?'))
      return;

    this.setState({loading: true});

    const data = new FormData();
    if (this.state.editData)
      data.append('productId', this.state.editData.productId);

    if (this.state.formData.image.value)
      data.append('image', this.state.formData.image.value);

    data.append('name', this.state.formData.name.value)
    data.append('description', this.state.formData.description.value);
    data.append('categoryId', this.state.formData.category.value);
    data.append('brand', this.state.formData.brand.value);
    data.append('price', this.state.formData.price.value ? this.state.formData.price.value.toString() : '');
    data.append('discountedPrice', this.state.formData.discountedPrice.value ?
      this.state.formData.discountedPrice.value.toString() : '');
    data.append('stockAmount', this.state.formData.stockAmount.value ?
      this.state.formData.stockAmount.value.toString() : '');
    data.append('criticalStockLevel', this.state.formData.criticalStockLevel.value ?
      this.state.formData.criticalStockLevel.value.toString() : '');
    data.append('popular', this.state.formData.isPopular.value.toString());
    data.append('kdv', this.state.formData.kdv.value);
    data.append('productProjectId', this.state.formData.projectId.value || 1);

    const date = moment().format("YYYY-MM-YY HH:mm:ss");
    let tmpText = '';

    for (const pair of data.entries())
      tmpText += pair[0] + ' : ' + pair[1] + ', ';

    localStorage.setItem(date, tmpText);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken()
    };

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/product/add-edit-product',
      'POST', headers, data);
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }
    //Edit moddan çıkılıyor
    this.clearEditMode();
    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Ürün düzenleme başarılı', 'Bilgi',
      Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then(() => {
      this.gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.productId === apiRes.productId) {
          rowNode.setSelected(true);
          that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    });
  }

  clearEditMode() {
    // this.inputParams.projectId.disabled = false;
    // this.state.formData.projectId.value = Util.getProjectId();
    this.state.formData.name.value = '';
    this.state.formData.description.value = '';
    this.state.formData.brand.value = '';
    this.state.formData.price.value = '';
    this.state.formData.discountedPrice.value = '';
    this.state.formData.stockAmount.value = '';
    this.state.formData.criticalStockLevel.value = '';
    this.state.formData.isPopular.value = 'false';
    this.state.formData.kdv.value = 8;
    this.state.formData.category = {};
    this.state.formData.image.value = null;
    this.state.formData.currImage.value = null;
    this.setState({editData: null});
  }

  editProduct = editData => {
    // this.inputParams.projectId.disabled = true;
    this.state.formData.name.value = editData.name;
    this.state.formData.image.value = null;
    this.state.formData.currImage.value = editData.imageUrl;
    this.state.formData.category = {value: editData.categoryId, label: editData.categoryName};
    this.state.formData.description.value = editData.description;
    this.state.formData.brand.value = editData.brand;
    this.state.formData.stockAmount.value = editData.stockAmount;
    this.state.formData.criticalStockLevel.value = editData.criticalStockLevel;
    this.state.formData.isPopular.value = editData.popular.toString();
    this.state.formData.price.value = editData.price;
    this.state.formData.discountedPrice.value = editData.discountedPrice;
    this.state.formData.kdv.value = editData.kdv ? editData.kdv : 0;
    this.setState({editData: editData});
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Ürün Sil',
        action: this.askForDeleteRow.bind(this, false),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Ürünü Pasif Yap',
        disabled: params.node.data.deleted,
        action: this.askForDeleteRow.bind(this, true),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Ürünü Aktifleştir',
        disabled: !params.node.data.deleted,
        action: this.makeActive.bind(this, params.node.data.productId)
      },
      {
        name: 'Ürünü Düzenle',
        action: this.editProduct.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      }
    ];
    return menu;
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  onRowDoubleClicked = (event) => {
    this.editProduct.bind(this, event.data)();
  }

  getRowStyle = (params) => {
    const data = params.node.data;

    if (data.criticalStockLevel >= data.stockAmount)
      return {background: 'lightcoral'};
  }

  onGridKeyDown = (event) => {
    const ariaLabel = event.target.getAttribute("aria-label");
    if (ariaLabel) {
      if (ariaLabel.includes('Filter Input'))
        this.lastFilterSelectorName = '[aria-label="' + ariaLabel + '"]';
    }
  }


  onFilterChanged = (params) => {
    if (!this.lastFilterSelectorName)
      return;

    const items = document.querySelectorAll(this.lastFilterSelectorName.trim());
    const itemLength = items.length;
    //birden fazla aynı isimde input olabileceği için altaki kod eklendi.
    for (let i = 0; i < itemLength; i++)
      items[i].focus();
  }

  onChangeDontShowPassiveProduct = (event) => {
    this.setState({dontShowPassiveProduct: event.target.checked}, this.loadData);
  }

  closeUpdatePriceModal = () => {
    this.setState({showUpdatePriceDialog: false});
    this.loadData().then();
  }

  updatePriceWithCategory = async () => {
    const formDataForUpdatePrice = this.state.formDataForUpdatePrice;

    if (!formDataForUpdatePrice.rate.value) {
      Mesaj.show('Değişim oranı boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!formDataForUpdatePrice.category.value) {
      Mesaj.show('Kategori boş olamaz!', 'Uyarı', Mesaj.ICON_WARN,
        Mesaj.BUTTON_OK);
      return;
    }

    if (!await Mesaj.confirm('Fiyat güncellemesi yapılacaktır! Emin misiniz?'))
      return;

    this.setState({loading: true});

    const data = new FormData();
    data.append('categoryId', formDataForUpdatePrice.category.value);
    data.append('rate', formDataForUpdatePrice.rate.value);
    data.append('productProjectId', this.state.formData.projectId.value || 1);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken()
    };

    const apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL
      + '/product/update-price-with-category', 'POST', headers, data);
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show('Kategori altındaki ürünler fiyatları güncellendi.', 'Başarı',
      Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap'}}>
        {this.state.loading &&
          <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}

        <Dialog
          open={this.state.showUpdatePriceDialog}
          onClose={this.closeUpdatePriceModal}
          style={{zIndex: '9900'}}
        >
          <DialogTitle id="form-dialog-title"
                       style={{textAlign: 'center'}}>Kategori İle Fiyat Güncelleme</DialogTitle>
          <DialogContent style={{padding: '20px'}}>
            <InputForm
              formData={this.state.formDataForUpdatePrice}
              formDataUpdater={this.formDataForUpdatePriceUpdater}
              inputParams={this.inputParamsForUpdatePrice}
              inputFormColumnWidth={'100%'}
              tabLoop={true}
              customTabKeyCode={13}
            />
            <br/>
            <Button
              disabled={this.state.loading}
              variant={"contained"}
              color="primary"
              size="small"
              startIcon={<SaveIcon/>}
              onClick={this.updatePriceWithCategory}
            >
              Fiyatı Güncelle
            </Button>
          </DialogContent>
        </Dialog>

        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />
        <div style={{width: '23%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.addEditProduct.bind(this)}
          >
            {this.state.editData ? 'Ürün Bilgi Güncelle' : 'Ürün Ekle'}
          </Button>
        </div>

        <div style={{width: '23%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            size="small"
            color="primary"
            startIcon={<CancelIcon/>}
            onClick={this.clearEditMode.bind(this)}
          >
            Düzenleme Modundan Çık
          </Button>
        </div>

        <div style={{width: '23%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            size="small"
            color="primary"
            onClick={() => this.setState({showUpdatePriceDialog: true})}
          >
            Toplu Fiyat Güncelle
          </Button>
        </div>

        <div style={{width: '23%', textAlign: 'center', padding: '10px'}}>
          <FormControlLabel
            control={<Checkbox checked={this.state.dontShowPassiveProduct} color="primary"
                               onChange={this.onChangeDontShowPassiveProduct}/>}
            label="Pasif Ürünleri Gösterme"
          />
        </div>
      </div>

      <div>
        <Toolbar handlers={
          [
            {callBack: this.refreshList, title: 'Yenile', icon: '/images/menu/mnuAutorenew_24px.svg'},
            {callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'},
            {
              callBack: this.saveGridSetting.bind(this),
              title: 'Tablo Ayarlarını Kaydet',
              icon: '/images/menu/24px/mnuSaveSettings.png'
            }
          ]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 350px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}
        onKeyDown={this.onGridKeyDown}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          getContextMenuItems={this.getContextMenuItems}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
          onRowDoubleClicked={this.onRowDoubleClicked}
          getRowStyle={this.getRowStyle}
          onFilterChanged={this.onFilterChanged}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Product;
