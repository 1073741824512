import React, {Component} from 'react';
import Util from '../../utils/CommonUtil';
import FormService from '../../services/Form/FormService';
import Button from '@material-ui/core/Button';
import styles from './CardQrGenerate.module.css'

import {Consts} from '../../const/Consts';
import Mesaj from '../../utils/Mesaj';
import Grid from '../Grid/Grid';
import moment from 'moment';
import CardQrLogin from './CardQrLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardQrUtil from './CardQrUtil';


class CardQrDeviceInfo extends Component {
  constructor(props) {
    super(props);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR,
      /*cellClass: params => {
        return params.data.operatorOperation ? 'custom-cell-red' : ''
      }*/
    };

    this.state = {
      gridRowData: [],
      token:sessionStorage.getItem('cardqr-token'),
      loading:false
    }


  }

  async componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    /* let paramChanged=false;
    for(const key of this.inputParamKeys) {
      if(prevState.formData[key].value !== this.state.formData[key].value )
        paramChanged=true;
    }
    if(paramChanged)
      this.loadData();*/
  }

  loadData = async () => {
    console.log('loadData KartReader istek gitti');
    this.setState({loading: true});

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/log/card-reader-list', 'GET', this.getHeaders(), null);
    this.setState({loading: false});
    console.log('loadData isteğe cevap geldi apiRes,',apiRes);

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    const jsonList = Util.prepareRowFromHeaderAndRows(apiRes.resultModel.headers, apiRes.resultModel.rows);
    const colDefs= apiRes.resultModel.headers.map( header => {
      //Id	Card_qr_reader_serial	Create_time	Default_secure_floors	Deleted	Elevator_name	Group_id	Project_id	Last_connect_problem_notified_time	Last_connect_time	Last_data_time	Last_informed_cpu_temperature	Last_informed_cpu_usage	Last_informed_free_disk	Last_informed_ip	Last_informed_memory_usage	Last_informed_sdk_version	Last_informed_version	Branch_id	Last_violation_notified_time
      const defaultHiddenHeaders=['id','deleted','group_id','project_id','create_time','default_secure_floors','last_informed_cpu_temperature','last_informed_cpu_usage',	'last_informed_free_disk',	'last_informed_ip',	'last_informed_memory_usage',	'last_informed_sdk_version',	'last_informed_version',		'last_violation_notified_time'];
      const isHidden = defaultHiddenHeaders.includes(header.name);
      return {
        field: header.name,
        hide:isHidden
      }
    } )

    this.setState({
      colDefs:colDefs,
      gridRowData: jsonList
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();

  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';
    return null;
  }
  onRowDoubleClicked = (event) => {
    console.log('TODO onRowDoubleClicked event',event);
  }

  loginSuccess=(token)=> {
    const that=this;
    this.setState({token:token}, function () {
      that.loadData();
    } )
  }

  logout=()=> {
    this.setState({token:null},function () {
      CardQrUtil.cardQrLogout();
    });
  }

  getHeaders=()=> {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.state.token
    };
  }

  excelExport = () => {
    const params = {
      fileName: 'Tanımlı_Kayıtlar.xlsx',
      sheetName: 'Tanımlı_Kayıtlar',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  render() {
    if(Util.checkNullOrUndefined(this.state.token)) {
      return <CardQrLogin successCallback={this.loginSuccess}  />
    }

    console.log('sessionStorage.getItem("cardqr-profile")',sessionStorage.getItem('cardqr-profile'));
    const profile=JSON.parse(sessionStorage.getItem('cardqr-profile')) ;

    return <div>

      {this.state.loading &&
        <CircularProgress style={{top: '50%', left: '50%', position: 'absolute', zIndex: 9000}}/>}

      <div className={styles.buttonContainer}>

        <div>
          <input
            onChange={(e) => this.gridApi.setQuickFilter(e.target.value)}
            placeholder={'Genel Arama.'}
            style={{'height':'80%','width':'60%'}}
            type={'text'}
          />
        </div>

        <Button onClick={()=>this.gridColumnApi.autoSizeAllColumns(false)}>
          Genişlet
        </Button>

        <Button onClick={()=>this.gridColumnApi.autoSizeAllColumns(true)}>
          Genişlet (Başlıksız)
        </Button>

        <Button onClick={()=>this.gridApi.sizeColumnsToFit()}>
          Sığdır
        </Button>

        <Button
          color="primary"
          onClick={this.excelExport}
          size="small"
          style={{marginRight: '20px', float: 'right'}}
          variant="contained"
        >
          Excel'e Aktar
        </Button>


      </div>
      <div
        style={{
          height: '600px',
          width: '100%',
          marginTop: '0px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.state.colDefs}
          defaultColDef={this.defaultColDef}
          getRowClass={this.getRowClass}
          onFirstDataRendered={this.onFirstDataRendered}
          onGridReadyCustom={this.onGridReady}
          onRowDoubleClicked={this.onRowDoubleClicked}
          rowData={this.state.gridRowData}
          rowHeight={30}
          rowSelection="single"
          suppressDragLeaveHidesColumns
        />

      </div>

    </div>
  }


}

export default CardQrDeviceInfo;
