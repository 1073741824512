import React, { Component } from 'react';
import moment from "moment";

export default class CheckBoxCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    if(this.props.value===undefined || this.props.value===null) {
      return null;
    }
    return <input type='checkbox' checked={this.props.value===true ? true : false} />
  }
}
