import { Consts } from 'const/Consts';
const getBatteryImage = (percentage, isCharging) => {
  var battStatus = 'batt';
  if (percentage > 90) {
    battStatus = '90';
  } else if (percentage > 80) {
    battStatus = '80';
  } else if (percentage > 60) {
    battStatus = '60';
  } else if (percentage > 40) {
    battStatus = '40';
  } else if (percentage > 30) {
    battStatus = '40';
  } else if (percentage < 30) {
    battStatus = '25';
  }
  battStatus += isCharging && percentage ? 'c' : '';
  return require(`../../../assets/RobotRemoteImages/batt/${battStatus}.png`);
};

const getElevatorImage = (elevAssigned, assignedElevIndependent) => {
  var elevStatus = 'disabled';
  if (elevAssigned) {
    if (assignedElevIndependent) {
      elevStatus = 'open';
    } else {
      elevStatus = 'assigned';
    }
  }
  return require(`../../../assets/RobotRemoteImages/elev/${elevStatus}.png`);
};
const hasCameraPermission = (userRoles, projectId) => {
  const permissions = [
    Consts.ROLE_RR_ADMIN,
    Consts.ROLE_RR_PROJECT_ADMIN,
    Consts.ROLE_RR_SYSTEM_ADMIN
    //Consts.ROLE_RR_VIEW_ONLY
  ];
  if (projectId && userRoles && userRoles[0].role === Consts.ROLE_RR_SYSTEM_ADMIN){
    return true
  }
  return userRoles?.some(
    item => item.projectId == projectId && permissions.includes(item.role)
  );
};
const hasLivePosePermission = (userRoles, projectId) => {
  const permissions = [
    Consts.ROLE_RR_ADMIN,
    Consts.ROLE_RR_PROJECT_ADMIN,
    Consts.ROLE_RR_SYSTEM_ADMIN
    //Consts.ROLE_RR_VIEW_ONLY
  ];
  if (projectId && userRoles && userRoles[0].role === Consts.ROLE_RR_SYSTEM_ADMIN){
    return true
  }
  return userRoles?.some(
    item => item.projectId == projectId && permissions.includes(item.role)
  );
};

const hasRobotControlPermission = (userRoles, projectId, enableCmdxzAssignedUserId) => {
  const permissions = [
    Consts.ROLE_RR_ADMIN,
    Consts.ROLE_RR_PROJECT_ADMIN,
    Consts.ROLE_RR_SYSTEM_ADMIN
    //Consts.ROLE_RR_VIEW_ONLY
  ];
  if (userRoles[0].role === Consts.ROLE_RR_SYSTEM_ADMIN) {
    return true;
  } else {
    if (
      enableCmdxzAssignedUserId == '0' ||
      enableCmdxzAssignedUserId == userRoles['0'].userId
    ) {
      return userRoles?.some(
        item => item.projectId == projectId && permissions.includes(item.role)
      );
    } else {
      return false;
    }
  }
  
};

const hasSystemAdminPermission = (userRoles, projectId) => {
  const permissions = [
    Consts.ROLE_RR_SYSTEM_ADMIN
  ];
  return userRoles?.some(
    item => item.projectId == projectId && permissions.includes(item.role)
  );
};

export {
  getBatteryImage,
  getElevatorImage,
  hasCameraPermission,
  hasRobotControlPermission,
  hasLivePosePermission,
  hasSystemAdminPermission
};
