import React, { Component } from 'react';
import moment from "moment";
import Util from "../../utils/CommonUtil";

export default class CurrencyCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  //    #.##0,00
  //    #.###,##
  //    #.##0,##

  //currencyFormatter: new Intl.NumberFormat("tr", {maximumFractionDigits: 2, minimumFractionDigits: 2})
  renderCurrency = () => {
    if(!this.props.displayZero===true && !this.props.value) {
      return '';
    }

    return Util.currencyFormatter.format(this.props.value);

  }

  render() {
    return <label>{this.renderCurrency()}</label>
  }
}
