import React, {Component} from 'react';
import Util from '../../utils/CommonUtil';
import Button from '@material-ui/core/Button';
import styles from './ElevatorCall.module.css'
import Mesaj from '../../utils/Mesaj';
import LoginUtil from '../../utils/LoginUtil';
import {IconButton} from '@material-ui/core';
import ElevatorMqttUtil from './ElevatorMqttUtil';
import {AiOutlineLock, AiOutlineUnlock} from 'react-icons/ai';
import {MdElevator} from 'react-icons/md';
import InfoIcon from '@material-ui/icons/Info';

const mqtt = require('mqtt')


const sortLookupList = {
  'R1':10 ,
  'R2':11 ,
  'R3':12 ,
  'P4':13 ,
};

class ElevatorAdminDubai extends Component {
  constructor(props) {
    super(props);
    this.defaultProjectId='15';


    this.state = {
      projectId:'15',
      elevators:null,
      mqttResponse: '',
      /*selectedFloorNameForRP1: 'P3',*/
    }

    //      elevatorInfo: {topic:"elevator/"+this.defaultProjectId+"/info/#", callbackArr: []},
    //       elevatorError: {topic:"elevator/"+this.defaultProjectId+"/error", callbackArr: []}


  }

  async componentDidMount() {

    //this.mqttTopicCallbacks.elevatorInfo.callbackArr.push(this.mqttMessageCallback);
    //this.mqttTopicCallbacks.elevatorError.callbackArr.push(this.mqttMessageCallback);
    const callBackArr=[];
    callBackArr.push({topic:'elevator/15/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,15)});
    //callBackArr.push({topic:'elevator/4/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,4)});
    //callBackArr.push({topic:'elevator/6/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,6)});

    this.client = await this.createClient(callBackArr);
    this.setState({projectId:15});
    setInterval(this.sendElevSummaryRequest,5000)
  }

  async createClient (mqttTopicCallbacks) {
    try {
      const subscribeTopics = mqttTopicCallbacks.map(topicCallback => topicCallback.topic );

      // eslint-disable-next-line no-undef
      const mqttPass=process.env.REACT_APP_DUBAI_MQTT_PASS;
      //const options = await ElevatorMqttUtil.getOptions();
      const client = mqtt.connect('ws://remote.saharobotik.com' , {
        host: 'remote.saharobotik.com',
        username: 'speedy_prod_demo_2',
        password: mqttPass,
        port: 49001,
        reconnectPeriod: 1000,
        clientId: Util.createGuid()
      });

      client.on('connect', function () {
        console.log('Mqtt client connected to server');
        client.subscribe(subscribeTopics, function (err) {
          if (!err)
            console.log('Subscribe callback worked');
          else {
            console.log('An error occured while connectiong to mqtt server', err);
            alert('Mqtt WebSocket sunucusuna bağlanırken hata oluştu:' + JSON.stringify(err));
          }
        }.bind(this));
      }.bind(this));

      client.on('message', function (topic, message) {
        if (message !== null && message !== undefined && message.length > 0) {
          for(const mqttTopicCallback of mqttTopicCallbacks) {
            if(mqttTopicCallback.topic===topic)
              mqttTopicCallback.callback(topic,message);
          }
        }
      }.bind(this));

      client.on('error', (err) => console.error('An error occured on mqtt client Err:', err));

      return client;
    } catch (e) {
      Mesaj.show('Mqtt bağlantısı için http ile giriniz!', 'Uyarı', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
      console.error('mqtt bağlantısında bir hata oluştu! Hata : ', e.toString());
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if(prevState.projectId!==this.state.projectId) {
      console.log('prevState.projectId',prevState.projectId);
      this.sendElevSummaryRequest(true);
    }
  }

  sendElevSummaryRequest=(requestFloorNames)=> {
    const msg= requestFloorNames ? JSON.stringify({floorNames:true}) : '{}';
    //console.log('msg',msg);
    if(this.client)
      this.client.publish('elevator/'+this.state.projectId+'/elev-summary/request', msg);
    else
      console.error('this.client hazır değildi.')
  }

  elevSummaryMsgReceived=(projectId,topic,msg,)=> {
    console.log('elevSummaryMsgReceived received');
    //console.log('elevSummaryMsgReceived projectId,',projectId,'topic',topic,'msg',msg);
    if(projectId!==this.state.projectId) {
      console.log('Farklı projectId geldi');
      return;
    }
    try {
      const elevators = JSON.parse(msg);

      Object.keys(elevators).forEach(elevName=> {
        const elevObj= elevators[elevName];
        if(elevObj.floorNames)
          this.state['floorNames_'+elevName]=elevObj.floorNames;
      })

      const elevatorArr=Object.keys(elevators).map(elevatorName=> {
        const newElev= elevators[elevatorName];
        newElev.name=elevatorName;
        return newElev;
      } ) ;

      elevatorArr.sort(function compare(a, b) {
        return sortLookupList[a.name] - sortLookupList[b.name];
      });


      //console.log('topic,msg,projectId',topic,elevators,projectId);
      this.setState({elevators:elevatorArr})
    }catch (e) {
      console.log('elevSummaryMsgReceived hata',e);
      Mesaj.show('Bir hata oluştu, lütfen sayfasyı yenileyiniz', 'Error', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
    }

  }


  componentWillUnmount() {
    this.client.end();
  }

  mqttMessageCallback = (topic, message) => {
    console.log('Mqtt message received. Topic', topic, 'Message:', message.toString());
    this.setState({mqttResponse: message.toString()});
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  createFloorOption = (floors) => {
    if(!floors || floors.length===0)
      return ;
    let floorOptions = [];

    floors.forEach((floor, index) => {
      floorOptions.push(<option
        key={index}
        value={floor}
                        >{floor}</option>)
    });

    return floorOptions;
  }

  sendCallMqttMessage = (groupId, elevatorId, floorName, event) => {
    const elevatorCommand = {
      groupId: groupId.toString(),
      elevatorId: elevatorId.toString(),
      floorName: floorName
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.projectId+'/command/manuel/call', JSON.stringify(elevatorCommand));
    Mesaj.show('Kat isteği gönderildi', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  makeIndependentMqttMsg = (elevatorName,groupId, elevatorId, makeIndependent) => {
    /*
    if (topic.equals("elevator/" + projectId + "/make-independent")) {
                elevatorControlService.makeIndependent(
                        (Integer) jsonMapPayload.get("groupId"),
                        (Integer) jsonMapPayload.get("elevatorId"),
                        (Boolean) jsonMapPayload.get("makeIndependent"));
            }
     */
    if(makeIndependent===true) {
      const sonuc= window.confirm(elevatorName+ ' asansörü Independent (Kilit) moduna alınacak. Emin misiniz?');
      if(!sonuc)
        return;
    }

    const independentCommand = {
      groupId: parseInt(groupId) ,
      elevatorId: parseInt(elevatorId) ,
      makeIndependent: makeIndependent
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.projectId+'/make-independent', JSON.stringify(independentCommand));
    Mesaj.show('Independent request sent', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  onChangeSelect = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  renderElevators() {
    return this.state.elevators.map(elevObj=> {
      //const elevObj=this.state.elevators[elevatorName];
      const elevatorName=elevObj.name;
      let stateColor='transparent';
      if(elevObj.operationMode==='INOPERATION')
        stateColor='green';
      else if(!['IDLE','NORMAL','PARK'].includes(elevObj.operationMode) )
        stateColor='red';

      //const toBeSendFloorVal= this.state[elevatorName+'_floorName'];
      return <div
        key={elevatorName}
        style={{width: '95%', textAlign: 'center', paddingTop: '5px',paddingBottom: '5px', border: '1px solid black'}}
             >
        <div style={{height:'22px',textAlign:'center'}}>
          <IconButton
            onClick={() => Mesaj.show('Tüm bilgiler:'+ Util.printKeysAndValues(elevObj) , 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK)}
            size={'small'}
            style={{'padding': '0'}}
          >
            <InfoIcon fontSize={'small'}/>
          </IconButton>
          <label style={{fontWeight:'bold'}}>{elevatorName}</label>
          <label style={{}}> (Kat:{elevObj.currFloorName}) : </label>

          {/*<input type={'text'} size={'3'} value={ Util.checkNullOrUndefined(toBeSendFloorVal) ? '' : toBeSendFloorVal}
                   onChange={(e)=>this.setState({[elevatorName+'_floorName']:e.target.value})}/>*/}
          <select
            onChange={(e)=>this.setState({[elevatorName+'_floorName']:e.target.value})}
            style={{}}
          >
            {this.createFloorOption(this.state['floorNames_'+elevatorName])}
          </select>

          <IconButton
            color={'primary'}
            onClick={()=> this.sendCallMqttMessage(elevObj.groupId, elevObj.elevatorId, this.state[elevatorName+'_floorName'] )}
          >
            <MdElevator size={'25'} />
          </IconButton>

          <label>Hrkt:{elevObj.movingDirection}</label>
        </div>


        <div style={{height:'30px',paddingTop:'15px',paddingBottom:'3px',textAlign:'center'}}>

          <label style={{backgroundColor:stateColor}}>Mod:{elevObj.operationMode}</label>

          <IconButton
            color={'secondary'}
            onClick={()=> this.makeIndependentMqttMsg(elevatorName,elevObj.groupId, elevObj.elevatorId, true)}
            style={{'paddingTop':'10px' }}
            title={'Make elevator Independent'}
          >
            <AiOutlineLock size={'25'} />
          </IconButton>

          <IconButton
            color={'primary'}
            onClick={()=> this.makeIndependentMqttMsg(elevatorName,elevObj.groupId, elevObj.elevatorId, false)}
            style={{'paddingTop':'10px' }}
            title={'Cancel elevator from independent'}
          >
            <AiOutlineUnlock size={'25'} />
          </IconButton>

          <label>Yön: {elevObj.carDirection}</label>
        </div>

      </div>
    }
    )
  }

  render() {
    return <div
      className={styles.mainContainer}
      style={{overflow: 'auto', height: '89%'}}
    >
      <div>
        <Button
          color="primary"
          onClick={() => {this.sendElevSummaryRequest()}}
          size="small"
          style={{marginRight: '2px',padding:'2px',textTransform:'none'}}
          variant="contained"
        >
        Yenile
        </Button>
      </div>
      {/*<div>
        {[{label:'A-C Bloklar',value:1},{label:'B Blok',value:15}]
          .map((option, index) => {
            const checked = this.state.projectId === option.value ? true : '';
            return (
              <label key={index}>
                <input
                  checked={checked}
                  name={option.label}
                  onChange={(e)=> this.setState({projectId:parseInt(e.target.value)})}
                  type="radio"
                  value={option.value}
                />
                {option.label}
              </label>);
          })}
      </div>*/}
      {(!this.state.elevators || Object.keys(this.state.elevators).length==0) ?
        <h2>Asansör Bilgisi okunamadı</h2> :
        this.renderElevators()
      }

    </div>
  }


}

export default ElevatorAdminDubai;
