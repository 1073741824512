import React, {Component} from 'react';
import CardQrUtil from './CardQrUtil';
import Util from '../../utils/CommonUtil';
import CardQrLogin from './CardQrLogin';
import styles from './CardQrAdmin.module.css'
import {Checkbox, CircularProgress, FormControlLabel, FormGroup, Typography} from '@material-ui/core';
import {Consts} from '../../const/Consts';
import Mesaj from '../../utils/Mesaj';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import {Switch} from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import CardElevatorDevice from "./CardElevatorDevice";
import moment from "moment-timezone";
import RefreshIcon from "@material-ui/icons/Refresh";


class CardQrAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token:sessionStorage.getItem('cardqr-token'),
      groups:[],
      elevators:[],
      allDeleted:false,
      toBeEditedElevator:null,
      availableSecureFloors:null //toBeEditedElevator seçili asansörüne ait olandır.
    }

    setInterval(()=>this.setState({}),60000)

  }


  async componentDidMount() {
    if (!Util.checkNullOrUndefined(this.state.token)) {
      this.loadData();
    }
  }


  formatDate = (date) => {
    if (date === null) return '-';
    moment.locale('tr');
    const formattedDate = moment.tz(date, 'Europe/Istanbul').format('HH:mm ');
    const fromNow = moment.tz(date, 'Europe/Istanbul').fromNow();
    return `${formattedDate} (${fromNow})`;
  };

  loadData = async () => {
    this.setState({loading: true});

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/list-admin-groups-elevators', 'GET', this.getHeaders(), null);
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Admin sayfa verileri yüklenirken bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    apiRes.emsGroups.forEach(group=> {
      group.elevators=[];
      for(let i=0;i<apiRes.emsElevators.length;i++){
        const elevator=apiRes.emsElevators[i];
        if(elevator.groupId===group.groupId) {
          group.elevators.push(elevator);
        }
      }
    })

    this.setState({
      groups:apiRes.emsGroups,
      elevators:apiRes.emsElevators
    });

    console.log('apiRes.emsGroups',apiRes.emsGroups);
    console.log('apiRes.elevators',apiRes.emsElevators);

  }

  loginSuccess=(token)=> {
    this.setState({token:token},this.loadData.bind(this))
  }

  logout=()=> {
    this.setState({token:null},function () {
      CardQrUtil.cardQrLogout();
    });
  }

  getHeaders=()=> {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.state.token
    };
  }

  sendMessage = (msg) => {
    this.clientRef.sendMessage('/app/chat', msg);
  }

  saveElevatorFloors=async () => {
    this.setState({loading: true});

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/update-elevators-default-secure-floors', 'POST', this.getHeaders(), JSON.stringify(this.state.elevators) );
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Secure kat bilgileri güncellenirken bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Secure kat bilgileri güncellenirken Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    await Mesaj.show('İşlem başarılı!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData();

  }


  tumAsansorleriAktifPasifGuncelle=()=> {
    this.state.groups.forEach(group=>
      group.elevators.forEach(elavator=>elavator.deleted=!this.state.allDeleted)
    );
    this.setState({allDeleted:!this.state.allDeleted})
  }

  render() {
    if(Util.checkNullOrUndefined(this.state.token)) {
      return <CardQrLogin successCallback={this.loginSuccess}  />
    }

    if(this.state.toBeEditedElevator) {
      return <CardElevatorDevice availableSecureFloors={this.state.availableSecureFloors} elevator={this.state.toBeEditedElevator}  closeCallback={()=> this.setState({toBeEditedElevator:null})}
        headers={this.getHeaders()}
        parentLoadData={this.loadData}
      />
    }

    /*console.log('sessionStorage.getItem("cardqr-profile")',sessionStorage.getItem('cardqr-profile'));
    const profile=JSON.parse(sessionStorage.getItem('cardqr-profile')) ;*/

    return <div className={styles.mainContainer}>

      {this.state.loading &&
        <CircularProgress style={{top: '50%', left: '50%', position: 'absolute', zIndex: 9000}}/>}

      <div style={{display: "flex", alignItems: "center"}}>
        <Checkbox
          checked={!this.state.allDeleted}
          onChange={this.tumAsansorleriAktifPasifGuncelle}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color={"primary"}
        />
        <Typography color={this.state.allDeleted ? 'error' : 'primary'}>{this.state.allDeleted ? "Tümü Pasif" : "Tümü Aktif"}</Typography>
        <Button
          color="primary"
          startIcon={<RefreshIcon/>}
          onClick={()=> {this.loadData()} }
          size="small"
          style={{textTransform: 'none',marginLeft:'40%'}}
          variant="contained"
        >
          YENİLE
        </Button>
      </div>

      {this.state.groups.map(group=> <div
        className={styles.group}
        key={group.groupId}
      >
        <label>{group.groupName}</label>
        {group.elevators.sort((a,b)=>a.elevatorName.localeCompare(b.elevatorName) ).map(elevator=> {
          return <div
            className={styles.elevator}
            key={elevator.elevatorName}
          >
            <div className={styles.elevatorNameButtonContainer}>
              {/*<label>{elevator.elevatorName}</label>

              <Button
                color="primary"
                onClick={()=> {
                  elevator.defaultSecureFloors=group.availableSecureFloors
                  this.setState({})
                }}
                size="small"
                style={{textTransform: 'none',padding:'1px',marginLeft:'15px'}}
                variant="contained"
              >
                  Tüm katları seç
              </Button>

              <Button
                color="primary"
                onClick={()=> {
                  elevator.defaultSecureFloors=''
                  this.setState({})
                }}
                size="small"
                style={{textTransform: 'none',padding:'1px',marginLeft:'15px'}}
                variant="contained"
              >
                  Tüm seçimleri kaldır
              </Button>*/}
              <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding:"10px",border:'1px'}}>
                <div style={{display: "flex", alignItems: "center"}} >
                  <Checkbox
                    checked={!elevator.deleted}
                    onChange={()=> {
                      elevator.deleted=!elevator.deleted
                      this.setState({})
                    }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    color={"primary"}
                  />
                  <Typography color={elevator.deleted ? 'error' : 'primary'} >{elevator.elevatorName} {elevator.deleted ? "Pasif" : "Aktif"}</Typography>
                </div>
                <Button
                  color="primary"
                  startIcon={<SettingsIcon/>}
                  onClick={()=> {this.setState({toBeEditedElevator:elevator,availableSecureFloors:group.availableSecureFloors})}}
                  size="small"
                  style={{textTransform: 'none',padding:'1px',marginLeft:'15px'}}
                  variant="contained"
                >
                  Kart Okuyucu Ayar
                </Button>


              </div>



            </div>

            <div className={styles.floorNames}>
              {/*{group.allAvailableFloors.split(',').map(availableFloor=> {
                return <span
                  className={styles.floor}
                  key={elevator.elevatorName +'_'+ availableFloor}
                >{availableFloor}</span>
              })
              }*/}

              <b>Kısıtlı Katlar:</b> {elevator.defaultSecureFloors.replaceAll(',',' ,')}
              <div style={{paddingTop:'5px'}}><b style={{paddingRight:'30px'}}>Son bağlantı:</b><span> {this.formatDate(elevator.lastConnectTime)}</span></div>
              <div><b>Son kart okutma:</b><span> {this.formatDate(elevator.lastDataTime)}</span></div>
            </div>
            {/*<div
              style={{width: '95%',padding:'10px'}}
            ><Select
              isMulti
              isDisabled={elevator.deleted}
              isSearchable
              noOptionsMessage={()=>'Uygun kat bulunamadı'}
                onChange={(selectedOptions) => {
                  const selectedValuesArr=selectedOptions.map(option=> option.value);
                  console.log('OnChange Multiselect selectedOption',selectedOptions,'selectedValuesArr',selectedValuesArr);
                  elevator.defaultSecureFloors=selectedValuesArr.join();
                  this.setState({});
                }}
                options={group.availableSecureFloors.split(',').map(avSecFl => {return {label:avSecFl, value:avSecFl}} )}
                placeholder={'Secure durumda yer alacak kat seçiniz'}
                value={elevator.defaultSecureFloors && elevator.defaultSecureFloors.split(',').map(defSecFl=> {return  {value:defSecFl,label:defSecFl}})}
            /></div>*/}
          </div>
        })}
      </div>)}

      <Button
        color="primary"
        onClick={this.saveElevatorFloors}
        size="small"
        style={{textTransform: 'none'}}
        variant="contained"
      >
        Secure Tanımlarını Güncelle
      </Button>

      {/*<SockJsClient url= {process.env.REACT_APP_CARD_QR_BACKEND_URL+ '/emswebsocket'} topics={['/topic/messages']}
                    onMessage={(msg) => { console.log('Gelen mesaj:', msg); }}
                    ref={ (client) => { this.clientRef = client }} />*/}


    </div>
  }


}

export default CardQrAdmin;
