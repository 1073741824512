const config = {
  isShowConsoleWsOnMessage: false,
  isShowConsoleDebug: false,
  imageUrl: process.env.REACT_APP_API_GATEWAY_URL + '/images',
  ClientConnected: 'server2client_connected',
  subsTopicNames: {
    rr_view_only: [
      'state',
      'elev_info',
      'nav-history',
      'comm_status',
      'erstop',
      'enable_cmd_xz',
      'enable_cmd_xz_assigned_uid'
    ],
    live_stream: 'live_stream',
    live_pose: 'live_pose'
  },
  pubsTopicNames: {
    enable_live_stream: 'enable_live_stream',
    enable_cmd_xz: 'enable_cmd_xz',
    enable_live_nav: 'enable_live_nav',
    cmd_xz: 'cmd_xz',
    enable_cmd_xz_assigned_uid: 'enable_cmd_xz_assigned_uid'
  },

  GET_ROBOT_API: '/robot/get-robot',
  GET_NAV_HISTORY_API: '/robot/get-robot-navigation-history',
  GET_MAP_API: '/robot/get-robot-map',
  GET_ALARM_API: '/robot/get-robot-alarm',
  GET_PROJECT_API: '/users/get-project',
  //GET_TARGET_LOCATIONS_API: '/robot/get-robot-target-locations',
  GET_PROJECT_CONFIG_API: '/project/get-config',
  GET_USERNAME_API: '/users/get-username',

  permissionsStateOpenLid: ['Initialize'],
  permissionsStateRobotCall: ['Initialize', 'ReadyForFilling']
};
export default config;
