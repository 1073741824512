import Switch from '@material-ui/core/Switch';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import NightsStayIcon from '@material-ui/icons/NightsStay';
const DarkModeSwicth = props => {
  const classes = useStyles();
  return (
    <Switch
      checkedIcon={<NightsStayIcon />}
      icon={<Brightness4Icon />}
      checked={props.isEnable}
      onChange={props.handleChange}
      classes={{
        track: classes.track,
        checked: classes.checked,
        switchBase: classes.switchBase
      }}
    />
  );
};
const useStyles = makeStyles({
  rootRight: {
    position: 'absolute',
    right: '25px',
    top: '-15px'
  },
  rootLeft: {
    position: 'absolute',
    right: '0',
    bottom: '-2px',
    height: '40px',
    width: '64px'
  },
  track: {
    opacity: 1,
    backgroundColor: '#ffffff54!important'
  },
  checked: {
    color: '#fff!important'
  },
  switchBase: {
    top: '-1px',
    left: '0',
    color: '#317ffb'
  }
});
export default DarkModeSwicth;
