import React, {Component} from 'react';
import Util from '../../utils/CommonUtil';
import Button from '@material-ui/core/Button';
import styles from './ElevatorCall.module.css'
import Mesaj from '../../utils/Mesaj';
import LoginUtil from '../../utils/LoginUtil';
import {IconButton} from '@material-ui/core';
import ElevatorMqttUtil from './ElevatorMqttUtil';
import {AiOutlineLock, AiOutlineUnlock} from 'react-icons/ai';
import {MdElevator} from 'react-icons/md';
import InfoIcon from '@material-ui/icons/Info';

const mqtt = require('mqtt')


const sortLookupList = {
  'RP-1':10 ,
  'RP-2':11 ,
  'RP-3':12 ,
  'PR-4':13 ,
  'PR-5':14,
  'RP-6':20,
  'RP-7':21,
  'RP-8':22,
  'RP-9':23,
  'RP10':30,
  'RP11':31,
  'RP12':32,
  'RFG1':40,
  'HP-1':50,
  'HP-2':51,
  'HRP4':60,
  'HRP5':61,
  'HGF1':70,
  'WC3':100,
  'WC4':101,
  'WGF1':102,
  'WGF2':103
};

class ElevatorAdmin extends Component {
  constructor(props) {
    super(props);
    this.defaultProjectId='1';


    this.state = {
      projectId:null,
      elevators:null,
      mqttResponse: '',
      /*selectedFloorNameForRP1: 'P3',*/
    }

    this.group2 = ['P6','P5','P4','P3','P2','P1','P0','T9','T8','T7','T6','T5','T4','T3','T2','L','M','LC','T1','T0','S1','RS','S2','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','S3','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44'];
    this.group3 = ['P6','P5','P4','P3','P2','P1','T9','T6','T4','T2','L','LC','T0','S1','S2','1','3','5','7','9','11','13','15','17','19','21','23','S3','25','26','27','28','29','30','31','32','33','34','35','36','37','38'];
    this.group4 = ['P5','P4','P3','P2','P1','T9','T6','T4','T2','L','LC','T0','S1','S2','1','3','5','7','9','11','13','15','17','19','21','23','S3','25','27','29','31','33','35','37','39','40','41','42','43'];

    //      elevatorInfo: {topic:"elevator/"+this.defaultProjectId+"/info/#", callbackArr: []},
    //       elevatorError: {topic:"elevator/"+this.defaultProjectId+"/error", callbackArr: []}


  }

  async componentDidMount() {

    //this.mqttTopicCallbacks.elevatorInfo.callbackArr.push(this.mqttMessageCallback);
    //this.mqttTopicCallbacks.elevatorError.callbackArr.push(this.mqttMessageCallback);
    const callBackArr=[];
    callBackArr.push({topic:'elevator/1/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,1)});
    callBackArr.push({topic:'elevator/4/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,4)});
    //callBackArr.push({topic:'elevator/6/elev-summary/response',callback: this.elevSummaryMsgReceived.bind(this,6)});

    this.client = await ElevatorMqttUtil.createClient(callBackArr);
    this.setState({projectId:1});
    setInterval(this.sendElevSummaryRequest,5000)
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if(prevState.projectId!==this.state.projectId) {
      console.log('prevState.projectId',prevState.projectId);
      this.sendElevSummaryRequest(true);
    }
  }

  sendElevSummaryRequest=(requestFloorNames)=> {
    const msg= requestFloorNames ? JSON.stringify({floorNames:true}) : '{}';
    //console.log('msg',msg);
    if(this.client)
      this.client.publish('elevator/'+this.state.projectId+'/elev-summary/request', msg);
    else
      console.error('this.client hazır değildi.')
  }

  elevSummaryMsgReceived=(projectId,topic,msg,)=> {
    if(projectId!==this.state.projectId) {
      //console.log('Farklı projectId geldi');
      return;
    }
    try {
      const elevators = JSON.parse(msg);

      Object.keys(elevators).forEach(elevName=> {
        const elevObj= elevators[elevName];
        if(elevObj.floorNames)
          this.state['floorNames_'+elevName]=elevObj.floorNames;
      })

      const elevatorArr=Object.keys(elevators).map(elevatorName=> {
        const newElev= elevators[elevatorName];
        newElev.name=elevatorName;
        return newElev;
      } ) ;

      elevatorArr.sort(function compare(a, b) {
        return sortLookupList[a.name] - sortLookupList[b.name];
      });


      //console.log('topic,msg,projectId',topic,elevators,projectId);
      this.setState({elevators:elevatorArr})
    }catch (e) {
      console.log('elevSummaryMsgReceived hata',e);
      Mesaj.show('Bir hata oluştu, lütfen sayfasyı yenileyiniz', 'Error', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
    }

  }


  componentWillUnmount() {
    this.client.end();
  }

  mqttMessageCallback = (topic, message) => {
    console.log('Mqtt message received. Topic', topic, 'Message:', message.toString());
    this.setState({mqttResponse: message.toString()});
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  createFloorOption = (floors) => {
    if(!floors || floors.length===0)
      return ;
    let floorOptions = [];

    floors.forEach((floor, index) => {
      floorOptions.push(<option
        key={index}
        value={floor}
      >{floor}</option>)
    });

    return floorOptions;
  }

  sendCallMqttMessage = (groupId, elevatorId, floorName, event) => {
    const elevatorCommand = {
      groupId: groupId.toString(),
      elevatorId: elevatorId.toString(),
      floorName: floorName
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.projectId+'/command/manuel/call', JSON.stringify(elevatorCommand));
    Mesaj.show('Kat isteği gönderildi', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  makeIndependentMqttMsg = (elevatorName,groupId, elevatorId, makeIndependent) => {
    /*
    if (topic.equals("elevator/" + projectId + "/make-independent")) {
                elevatorControlService.makeIndependent(
                        (Integer) jsonMapPayload.get("groupId"),
                        (Integer) jsonMapPayload.get("elevatorId"),
                        (Boolean) jsonMapPayload.get("makeIndependent"));
            }
     */
    if(makeIndependent===true) {
      const sonuc= window.confirm(elevatorName+ ' asansörü Independent (Kilit) moduna alınacak. Emin misiniz?');
      if(!sonuc)
        return;
    }

    const independentCommand = {
      groupId: parseInt(groupId) ,
      elevatorId: parseInt(elevatorId) ,
      makeIndependent: makeIndependent
    }

    // console.log('elevatorCommand : ', elevatorCommand);

    this.client.publish('elevator/'+this.state.projectId+'/make-independent', JSON.stringify(independentCommand));
    Mesaj.show('Independent request sent', 'Success', Mesaj.ICON_SUCCESS, Mesaj.BUTTON_OK);
  }

  onChangeSelect = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  renderElevators() {
    return this.state.elevators.map(elevObj=> {
      //const elevObj=this.state.elevators[elevatorName];
      const elevatorName=elevObj.name;
      let stateColor='transparent';
      if(elevObj.operationMode==='INOPERATION')
        stateColor='green';
      else if(!['IDLE','NORMAL','PARK'].includes(elevObj.operationMode) )
        stateColor='red';

      //const toBeSendFloorVal= this.state[elevatorName+'_floorName'];
      return <div
        key={elevatorName}
        style={{width: '95%', textAlign: 'center', paddingTop: '5px',paddingBottom: '5px', border: '1px solid black'}}
      >
        <div style={{height:'22px',textAlign:'center'}}>
          <IconButton
            onClick={() => Mesaj.show('Tüm bilgiler:'+ Util.printKeysAndValues(elevObj) , 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK)}
            size={'small'}
            style={{'padding': '0'}}
          >
            <InfoIcon fontSize={'small'}/>
          </IconButton>
          <label style={{fontWeight:'bold'}}>{elevatorName}</label>
          <label style={{}}> (Kat:{elevObj.currFloorName}) : </label>

          {/*<input type={'text'} size={'3'} value={ Util.checkNullOrUndefined(toBeSendFloorVal) ? '' : toBeSendFloorVal}
                   onChange={(e)=>this.setState({[elevatorName+'_floorName']:e.target.value})}/>*/}
          <select
            onChange={(e)=>this.setState({[elevatorName+'_floorName']:e.target.value})}
            style={{}}
          >
            {this.createFloorOption(this.state['floorNames_'+elevatorName])}
          </select>

          <IconButton
            color={'primary'}
            onClick={()=> this.sendCallMqttMessage(elevObj.groupId, elevObj.elevatorId, this.state[elevatorName+'_floorName'] )}
          >
            <MdElevator size={'25'} />
          </IconButton>

          <label>Hrkt:{elevObj.movingDirection}</label>
        </div>


        <div style={{height:'30px',paddingTop:'15px',paddingBottom:'3px',textAlign:'center'}}>

          <label style={{backgroundColor:stateColor}}>Mod:{elevObj.operationMode}</label>

          <IconButton
            color={'secondary'}
            onClick={()=> this.makeIndependentMqttMsg(elevatorName,elevObj.groupId, elevObj.elevatorId, true)}
            style={{'paddingTop':'10px' }}
            title={'Make elevator Independent'}
          >
            <AiOutlineLock size={'25'} />
          </IconButton>

          <IconButton
            color={'primary'}
            onClick={()=> this.makeIndependentMqttMsg(elevatorName,elevObj.groupId, elevObj.elevatorId, false)}
            style={{'paddingTop':'10px' }}
            title={'Cancel elevator from independent'}
          >
            <AiOutlineUnlock size={'25'} />
          </IconButton>

          <label>Yön: {elevObj.carDirection}</label>
        </div>

      </div>
    }
    )
  }

  render() {
    return <div
      className={styles.mainContainer}
      style={{overflow: 'auto', height: '89%'}}
           >
      <div>
        {!Util.isElevatorUser() &&
      <Button
        color="primary"
        onClick={() => {window.location.href = '/';}}
        size="small"
        style={{marginRight: '2px',padding:'2px',textTransform:'none'}}
        variant="contained"
      >
        Anasayfa
      </Button>}
        <Button
          color="primary"
          onClick={() => {LoginUtil.logout();}}
          size="small"
          style={{marginRight: '2px',padding:'2px',textTransform:'none'}}
          variant="contained"
        >
        Çıkış
        </Button>
        <Button
          color="primary"
          onClick={() => {this.sendElevSummaryRequest()}}
          size="small"
          style={{marginRight: '2px',padding:'2px',textTransform:'none'}}
          variant="contained"
        >
        Yenile
        </Button>
      </div>
      <div>
        {[{label:'A-C Bloklar',value:1},{label:'B Blok',value:4}]
          .map((option, index) => {
            const checked = this.state.projectId === option.value ? true : '';
            return (
              <label key={index}>
                <input
                  checked={checked}
                  name={option.label}
                  onChange={(e)=> this.setState({projectId:parseInt(e.target.value)})}
                  type="radio"
                  value={option.value}
                />
                {option.label}
              </label>);
          })}
      </div>
      {(!this.state.elevators || Object.keys(this.state.elevators).length==0) ?
        <h2>Asansör Bilgisi okunamadı</h2> :
        this.renderElevators()
      }

    </div>
  }


}

export default ElevatorAdmin;
