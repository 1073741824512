import React, {Component} from 'react';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import {Consts} from "../../const/Consts";
import Grid from "../Grid/Grid";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";

class MenuProduct extends Component {
  constructor(props) {
    super(props);

    this.colDefs = [
      {
        headerName: 'Ürün Id',
        field: 'id',
        valueGetter: 'data.productId'
      },
      {
        headerName: 'Pasif',
        field: 'deleted',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'Ürün Adı',
        field: 'name',
        floatingFilter: true,
      },
      {
        headerName: 'Ürün Resmi',
        field: 'imageUrl',
        cellRenderer: 'ImageCellRenderer'
      },
      {
        headerName: 'Açıklama',
        field: 'description'
      },
      {
        headerName: 'Marka',
        field: 'brand'
      },
      {
        headerName: 'Bağlı Olduğu Kategori Adı',
        field: 'categoryName'
      }
    ];

    if (this.props.mode === "View")
      this.colDefs.push({
        headerName: 'Menü İçerisindeki Adedi',
        field: 'amountInMenu'
      });

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.state = {
      gridRowData: [],
      productAmount: 1,
      loading: false
    }
  }

  componentDidMount() {
    this.loadData().then();
  }

  loadData = async () => {
    this.setState({loading: true});

    let apiRes = null;
    if (this.props.mode === "Add") {
      const projectId = Util.isAdminUser() ? this.props.projectId : Util.getProjectId();
        apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/product/list-for-panel-with-project-id',
          'POST', Util.HEADERS_FOR_GET, projectId);
    } else if (this.props.mode === "View")
      apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/menu/list-menu-products',
        'POST', Util.HEADERS_FOR_GET, this.props.selectedMenu);

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    })

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();
  }

  prepareRowData = (list) => {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  addOrDeleteProductToMenu = async () => {
    const product = this.gridApi.getSelectedNodes();

    if (product.length !== 1) {
      Mesaj.show('Ürün seçilmemiştir!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});

    let body = {
      menuId: this.props.selectedMenu,
      productId: product[0].data.productId,
      productAmount: this.state.productAmount
    }

    const apiUrl = this.props.mode === "Add" ? '/menu/add-menu-product' : '/menu/del-menu-product';

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + apiUrl,
      'POST', Util.HEADERS_FOR_GET, JSON.stringify(body));
    this.setState({loading: false, productAmount: 1});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    const mesaj = this.props.mode === "Add" ? 'Menüye ürün eklendi.' : 'Menüden ürün çıkartıldı.';

    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : mesaj, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);

    if (this.props.mode === "View")
      this.loadData().then();
  }

  onChangeInput = (event) => {
    this.setState({productAmount: event.target.value})
  }

  render() {
    return <div style={{padding: '10px', width: '100%'}}>
      {this.props.mode === "Add" &&
      < TextField label="Ürün Adeti Giriniz." variant="outlined" value={this.state.productAmount} size='small'
                  style={{paddingRight: '20px'}}
                  onChange={this.onChangeInput}
                  type='number'/>
      }

      <Button
        disabled={this.state.loading}
        variant={"contained"}
        color="primary"
        size="small"
        startIcon={<SaveIcon/>}
        onClick={this.addOrDeleteProductToMenu.bind(this)}
      >
        {this.props.mode === "Add" ? 'Ürünü Ekle' : 'Ürün Çıkar'}
      </Button>

      <div
        style={{
          height: '500px',
          width: '98%',
          marginTop: '8px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
          summaryBar={false}
          suppressContextMenu={true}
        >
        </Grid>
      </div>
    </div>
  };
}

export default MenuProduct;
