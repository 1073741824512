import React, {Component} from 'react';
import Util from '../../utils/CommonUtil';
import FormService from '../../services/Form/FormService';
import Button from '@material-ui/core/Button';
import styles from './CardQrGenerate.module.css'

import LoginUtil from '../../utils/LoginUtil';
import InputForm from '../InputForm/InputForm';
import {Consts} from '../../const/Consts';
import Mesaj from '../../utils/Mesaj';
import Grid from '../Grid/Grid';
import moment from 'moment';
import CardQrLogin from './CardQrLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardQrUtil from './CardQrUtil';


const DECIMAL = 'decimal';
const HEX = 'hex';
const HEX_REVERSE = 'hexReverse';

const TYPE_CARD = 'card';
const TYPE_QR = 'qr';


const OWNERTYPE_KURYE='Kurye';
const OWNERTYPE_ZIYARETCI='Ziyaretçi';
const OWNERTYPE_KAT_MALIKI='Kat Maliki';
const OWNERTYPE_PERSONEL='Personel';

//const HEX_TEST_REGEX = /[0-9A-Fa-f]{6}/g;

const CARRIER_COMPANIES= ['Getir','YemekSepeti','Trendyol','Su','Diğer'];

class CardQrGenerate extends Component {
  constructor(props) {
    super(props);

    this.colDefs = [
      {
        headerName: 'Pasif',
        field: 'deleted',
        valueGetter: (params) => params.data.deleted == 'true' ? 'Pasif' : 'Aktif'
      },
      {
        headerName: 'Tür',
        field: 'ownerType'
      },
      {
        headerName: 'Kurye Firma',
        field: 'carrierCompany'
      },
      {
        headerName: 'Kurye Firma Diğer',
        field: 'carrierCompanyOther'
      },
      {
        headerName: 'Daire',
        field: 'flatNo'
      },
      {
        headerName: 'Oluşturma Zamanı',
        field: 'createTime',
        cellRenderer: 'DateTimeCellRenderer',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          debounceMs: 500,
          comparator: Util.GRID_DATE_COMPARATOR
        },
        cellRendererParams: {
          format: 'DD.MM.YYYY HH:mm'
        }
      },
      {
        headerName: 'Kart / QR',
        field: 'type',
        valueFormatter: (params) => {
          if (params.data.type === TYPE_CARD)
            return 'Kart';
          if (params.data.type === TYPE_QR)
            return 'QR';
          return '';
        }
      },
      {
        headerName: 'Ad',
        field: 'name'
      },
      {
        headerName: 'Soyad',
        field: 'lastName'
      },
      {
        headerName: 'E-posta',
        field: 'email'
      },
      {
        headerName: 'Telefon',
        field: 'phone'
      },
      {
        headerName: 'Master',
        field: 'isMaster',
        valueGetter: (params)=> {
          if(params.data.isMaster===true)
            return 'Master';
          return '';
        }
      },
      {
        headerName: 'İzin Verilen Asansörler',
        field: 'allowedElevatorNames'
      },
      {
        headerName: 'İzin Verilen Katlar',
        field: 'allowedSecuredFloorsStr'
      },
      {
        headerName: 'Otomatik Kat İsteği',
        field: 'autoCallFloorName'
      },
      {
        headerName: 'Kart Id (10luk)',
        field: 'cardIdDecimal',
        getQuickFilterText: function(params) {
          return params.value ? '0000000'+ params.value : '';
        }
      },
      {
        headerName: 'Açıklama',
        field: 'description'
      },
      {
        headerName: 'Geçerlilik Zamanı',
        field: 'expireTime',
        cellRenderer: 'DateTimeCellRenderer',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          debounceMs: 500,
          comparator: Util.GRID_DATE_COMPARATOR
        },
        cellRendererParams: {
          format: 'DD.MM.YYYY HH:mm'
        }
      }
    ]

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR,
      /*cellClass: params => {
        return params.data.operatorOperation ? 'custom-cell-red' : ''
      }*/
    };

    this.state = {
      inputParams: {},
      formData: null,
      infoMessage: [],
      gridRowData: [],
      token:sessionStorage.getItem('cardqr-token'),
      loadingListData:false,
      showPassivesAlso:false,
      allFloorNames:[]
    }

  }

  async delData(id) {
    const delCardQrModel= {
      cardQrId:id
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/del-card-qr-with-branchid',
      'POST', this.getHeaders(), JSON.stringify(delCardQrModel));
    this.setState({loading: false});
    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error('Bir hata oluştu! Detay:', apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  deleteRow = () => {
    const selectedData = this.gridApi.getSelectedNodes();
    selectedData.forEach((node) => {
      this.delData(node.data.id).then();
    });
  }

  askForDeleteRow = () => {
    const selectedData = this.gridApi.getSelectedNodes();

    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show('Kaydı silmek istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this));
  }

  getContextMenuItems = params => {
    if (params.node?.group === true)
      return null;

    const menu = [
      {
        name: 'Sil',
        action: this.askForDeleteRow.bind(this),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      }
    ];
    return menu;
  }


  async componentDidMount() {
    //let elevators=[];
    if (!Util.checkNullOrUndefined(this.state.token)) {
      this.loadData();
      this.loadElevatorMetaInfo();
    }

    const inputParams = {
      deleted: {
        label: 'Pasif',
        checkedVal: true,
        unCheckedVal: false,
        defaultValue: false,
        type: 'checkbox',
      },
      ownerType: {
        label: 'Tanımlama Tipi', type: 'radio', defaultValue: OWNERTYPE_KURYE, title: 'Kart sahibine göre tanımlama tipi seçiniz!',
        options: [
          {label: OWNERTYPE_KURYE, value: OWNERTYPE_KURYE},
          {label: OWNERTYPE_ZIYARETCI, value: OWNERTYPE_ZIYARETCI},
          {label: OWNERTYPE_KAT_MALIKI, value: OWNERTYPE_KAT_MALIKI},
          {label: OWNERTYPE_PERSONEL, value: OWNERTYPE_PERSONEL},

        ],
        onChange: ()=> {
          if(this.state.formData.ownerType.value===OWNERTYPE_PERSONEL)
            this.state.formData.isMaster.value=true;
          else
            this.state.formData.isMaster.value=false;

          this.updateExpireTimeByOwnerTime();
        }
      },
      cardId: {
        label: 'Kart ID',
        defaultValue: '',
        type: 'text',
        disabled: () => this.state.formData.type.value !== TYPE_CARD
      },
      flatNo: {
        label: 'Daire No',
        defaultValue: '',
        type: 'text'
      },
      carrierCompany: {
        label: 'Kurye Firması',
        type: 'radio',
        title: 'Firmalardan birisini seçiniz. Yoksa Diğer seçerek bilgi giriniz.',
        defaultValue: 'Diğer',
        options: CARRIER_COMPANIES.map(compName=> {return {label:compName,value:compName}}  ),
        disabled: () => this.state.formData.ownerType.value!==OWNERTYPE_KURYE
      },
      carrierCompanyOther: {
        label: 'Kurye Firması Diğer',
        type: 'text',
        title: 'Kurye seçiminde listede yer almayan firma bilgisini giriniz.',
        disabled: () => this.state.formData.carrierCompany.value!=='Diğer'
      },
      allowedSecuredFloorsStr: {
        label: 'İzin Verilen Katlar (Aralarda virgül olacak)',
        type: 'text',
        defaultValue: '',
        placeholder: 'Örn:  P3,34',
        title: 'Araları virgül ile ayırınız.',
        disabled: () => this.state.formData.isMaster.value,
        onBlur: this.quickLoadAutoCallFloorName
      },
      autoCallFloorName: {
        label: 'Otomatik aktifleştirilecek Kat',
        type: 'text',
        defaultValue: '',
        placeholder: 'Örn:  P3',
        title: 'Kart okutulur okutulmaz, düğmeye basmadan asansörün otomatik olarak gönderileceği kat bilgisidir. Yalnızca tek bir kat belirtilebilir. Zorunlu alan değildir.'
      },
      type: {
        label: 'Kart / QR Kod', type: 'radio', defaultValue: TYPE_CARD, title: 'Tanımlama tipi seçiniz.',
        options: [
          {label: 'Kart', value: TYPE_CARD},
          {label: 'Qr Kodu', value: TYPE_QR}
        ]
      },
      name: {
        label: 'Ad', type: 'text', defaultValue: '', placeholder: 'Ad'
      },
      lastName: {
        label: 'Soyad',
        type: 'text',
        defaultValue: '',
        placeholder: 'Soyad'
      },
      email: {
        label: 'E-posta',
        type: 'text',
        defaultValue: '',
        placeholder: 'E-posta'
      },
      phone: {
        label: 'Telefon',
        type: 'text',
        defaultValue: '',
        placeholder: 'Telefon'
      },
      isMaster: {
        label: 'Tüm Kat ve Asansörlere Yetkili (Master) ',
        checkedVal: true,
        unCheckedVal: false,
        defaultValue: false,
        type: 'checkbox',
      },
      allowedElevatorNames: {
        label: 'İzin Verilen Asansörler',
        type: 'multi-select',
        options: [],//elevatorNames.map(elevatorName=> {return {label:elevatorName,value:elevatorName }}),
        defaultValue: '',
        placeholder: 'Tüm asansörleri kullanmak için boş bırakınız)',
        title: 'Asansör yetki kısıtlaması olmayacaksa boş seçim olarak bırakınız.',
        noOptionsMessage: 'Başka kat seçeneği yoktur.',
        disabled: () => this.state.formData.isMaster.value
      },
      cardIdFormat: {
        label: 'Kart ID Formatı',
        type: 'radio',
        defaultValue: DECIMAL,
        title: 'Kart Id Formatı seçiniz. Emin değilseniz varsayılan ayarında bırakınız',
        options: [
          {label: '10', value: DECIMAL},
          /*{label: '16(Hex)', value: HEX},
          {label: 'Ters-16(Rev-Hex)', value: HEX_REVERSE},*/
        ]
      },
      expireTime: {
        label: 'Geçerlilik Zamanı',
        defaultValue: moment().add(1, 'day'),
        type: 'datetime-custom'
      },
      description: {
        label: 'Açıklama', type: 'text', defaultValue: ''
      }
    };

    const that = this;
    this.setState({inputParams: inputParams}, function () {
      that.setState({formData: FormService.createInitialFormState(this.state.inputParams)})
    })


  }

  updateExpireTimeByOwnerTime=()=> {
    const form=this.state.formData;
    if(form.ownerType.value===OWNERTYPE_KAT_MALIKI || form.ownerType.value===OWNERTYPE_PERSONEL)
      form.expireTime.value = moment().add(6, 'month');
    else
      form.expireTime.value = moment().add(1, 'day');

  }

  quickLoadAutoCallFloorName=()=> {
    if(!this.state.formData.allowedSecuredFloorsStr.value)
      return;

    if(this.state.formData.ownerType.value!==OWNERTYPE_KURYE)
      return;

    const commaSeperateStrToArr= CardQrUtil.commaSeperateStrToArr(this.state.formData.allowedSecuredFloorsStr.value);
    this.state.formData.autoCallFloorName.value=commaSeperateStrToArr[0];
    this.setState({});
  }

  loadData = async () => {
    console.log('loadData istek gitti');
    this.setState({loadingListData: true});

    const  cardQrPageListModel= {showPassivesAlso:this.state.showPassivesAlso}
    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/card-qr-page-list-with-branchid', 'POST', this.getHeaders(), JSON.stringify(cardQrPageListModel));
    this.setState({loadingListData: false});
    console.log('loadData isteğe cevap geldi');

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      gridRowData: apiRes.list
    });

    if (this.gridApi)
      this.gridApi.sizeColumnsToFit();

  }

  formDataUpdater = (formData) => {
    this.state.formData=formData;
    this.setState({formData});
  }

  loadElevatorMetaInfo = async () => {
    this.setState({loading: true});

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/list-elevator-metainfo-for-cardqr-with-branchid', 'GET', this.getHeaders(), null);
    console.log('apiRes  elevNames',apiRes );
    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error('Bir hata oluştu! Detay:', apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    //this.state.inputParams.allowedElevatorNames.options= apiRes.elevators.map(elev=> {return {label:elev.elevatorName,value:elev.elevatorName }});
    this.state.inputParams.allowedElevatorNames.options= apiRes.elevatorNames.map(elevName=> {return {label:elevName,value:elevName }});
    this.state.inputParams.allowedSecuredFloorsStr.title='Mevcut katlar: '+ apiRes.floorNames.join(' ');
    this.state.inputParams.autoCallFloorName.title='Sadece bir kat giriniz. Mevcut katlar: '+ apiRes.floorNames.join(' ');
    this.state.allFloorNames=apiRes.floorNames;
    //console.log('loadElevatorMetaInfo res:',apiRes);
    this.setState({});
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  onChangeSelect = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  isValidHex(s) {

    // Size of string
    let n = s.length;

    // Iterate over string
    for (let i = 0; i < n; i++) {
      let ch = s[i];

      // Check if the character
      // is invalid
      if ((ch < '0' || ch > '9') &&
        (ch < 'A' || ch > 'F')) {
        return false;
      }
    }

    return true;
  }

  excelExport = () => {
    const params = {
      fileName: 'Tanımlı_Kayıtlar.xlsx',
      sheetName: 'Tanımlı_Kayıtlar',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  qrCardAddEdit = async (event) => {
    //console.log('this.state.formData.expireTime.value',this.state.formData.expireTime.value);

    const type = this.state.formData.type.value;
    const cardIdFormat = this.state.formData.cardIdFormat.value;
    const cardIdInputStr = this.state.formData.cardId.value;
    let cardIdDecimal = '';

    if (type === TYPE_CARD) {
      if (cardIdFormat === HEX || cardIdFormat === HEX_REVERSE) {
        if (!this.isValidHex(cardIdInputStr.toUpperCase())) {
          Mesaj.show('Girilen Kart Id Değeri Doğru HEX formatında değildir.', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
          return;
        }
      } else if (cardIdFormat === DECIMAL) {
        let isNum = /^\d+$/.test(cardIdInputStr);
        if (!isNum) {
          Mesaj.show('Kart ID Değerinde numara dışında karakter bulunamaz. Lütfen kontrol ediniz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
          return;
        }
      }

      cardIdDecimal = this.getCardIdDecimal(cardIdInputStr, cardIdFormat);

      if (!cardIdInputStr) {
        Mesaj.show('Kart ID boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
        return;
      }


      /*Devre dışı bırakılmıştır
      for (const cardQr of this.state.gridRowData) {
        if (parseInt(cardQr.cardIdDecimal) === parseInt(cardIdDecimal) && this.state.id !== cardQr.id) {
          Mesaj.show("Bu Kart numarası daha önceden tanımlanmıştır.", 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
          return;
        }
      }
      */


    }else if(type === TYPE_QR) {
      cardIdDecimal=this.state.formData.cardId.value;
    }

    if(!this.state.formData.ownerType.value) {
      Mesaj.show('Tanımlama tipi boş bırakılamaz!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    //İzin verilen katlarda, olmayan kat eklenirse kızacak.
    if(!CardQrUtil.checkFloorNames(this.state.formData.allowedSecuredFloorsStr.value,this.state.allFloorNames,'İzin verilen kat listesinde yer alan ')  ) {
      return;
    }

    if( !Util.checkNullOrUndefined(this.state.formData.autoCallFloorName.value) && this.state.formData.autoCallFloorName.value.split(',').length>1) {
      Mesaj.show('Otomatik aktifleştirilecek kat sadece bir adet kat için tanımlanabilir.', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    if(!CardQrUtil.checkFloorNames(this.state.formData.autoCallFloorName.value,this.state.allFloorNames,'Otomatik aktifleştirilecek kat ')  ) {
      return;
    }

    if(this.state.formData.ownerType.value===OWNERTYPE_KURYE) {
      if( !this.state.formData.flatNo.value) {
        Mesaj.show('Kuryeler için Daire No seçimi zorunludur.', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
        return;
      }

      if( !this.state.formData.carrierCompany.value) {
        Mesaj.show('Kuryeler için Firma seçimi zorunludur.', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
        return;
      }

      if( this.state.formData.carrierCompany.value==='Diğer' && !this.state.formData.carrierCompanyOther.value) {
        Mesaj.show('Kuryeler için Diğer seçeneği seçilmiş ise, Kurye Firması Diğer alanı doldurulması zorunludur', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
        return;
      }

    }

    let carrierCompany=null;
    if(this.state.formData.ownerType.value===OWNERTYPE_KURYE) {
      if(this.state.formData.carrierCompany.value==='Diğer')
        carrierCompany=this.state.formData.carrierCompanyOther.value;
      else
        carrierCompany=this.state.formData.carrierCompany.value;
    }

    const body = {
      deleted: this.state.formData.deleted.value,
      type: type,
      name: this.state.formData.name.value,
      lastName: this.state.formData.lastName.value,
      email: this.state.formData.email.value,
      phone: this.state.formData.phone.value,
      isMaster: this.state.formData.isMaster.value,
      allowedSecuredFloorsStr: this.state.formData.allowedSecuredFloorsStr.value,
      allowedElevatorNames:CardQrUtil.arrToCommaSeperatedStr(this.state.formData.allowedElevatorNames.value)  ,
      autoCallFloorName: this.state.formData.autoCallFloorName.value,
      cardIdDecimal: cardIdDecimal,
      /*expireTime: this.state.formData.expireTime.value,*/
      description: this.state.formData.description.value,
      ownerType:this.state.formData.ownerType.value,
      flatNo:this.state.formData.flatNo.value,
      carrierCompany:carrierCompany,
      id: this.state.id
    }

    if (this.state.formData.expireTime.value) {
      body.expireTimestamp = new Date(this.state.formData.expireTime.value).getTime()
    }

    console.log('body', body);

    this.setState({loading: true});

    let apiRes = await Util.apiRequest(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/save-update-card-qr-with-branchid', 'POST', this.getHeaders(), JSON.stringify(body));
    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error('Bir hata oluştu! Detay:', apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata', Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.loadData();
    let msg='İşlem başarılı';
    if(apiRes.passivedCnt) {
      msg+='. Aynı ID numarasına sahip önceki '+apiRes.passivedCnt+' kayıt pasif yapılmıştır. ';
    }

    Mesaj.show(msg, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    //await Util.postForImage(process.env.REACT_APP_CARD_QR_BACKEND_URL + '/save-update-card-qr', body);
    //this.exitEditMode();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';
    return null;
  }
  onRowDoubleClicked = (event) => {
    const form = this.state.formData;
    form.deleted.value = event.data.deleted;
    form.type.value = event.data.type;
    form.name.value = event.data.name;
    form.lastName.value = event.data.lastName;
    form.email.value = event.data.email;
    form.phone.value = event.data.phone;
    form.cardId.value = this.getCardIdFromDecimal(event.data.cardIdDecimal, this.state.formData.cardIdFormat.value);
    form.isMaster.value = event.data.isMaster;
    form.expireTime.value = event.data.expireTime;
    form.description.value = event.data.description;
    form.allowedSecuredFloorsStr.value = event.data.allowedSecuredFloorsStr;
    form.allowedElevatorNames.value = CardQrUtil.commaSeperateStrToArr( event.data.allowedElevatorNames);
    form.autoCallFloorName.value = event.data.autoCallFloorName;
    form.ownerType.value=event.data.ownerType;
    form.flatNo.value=event.data.flatNo;
    form.carrierCompany.value=null;
    form.carrierCompanyOther.value=null;
    if(form.ownerType.value===OWNERTYPE_KURYE) {
      if(CARRIER_COMPANIES.includes(event.data.carrierCompany))
        form.carrierCompany.value=event.data.carrierCompany;
      else {
        form.carrierCompany.value='Diğer';
        form.carrierCompanyOther.value=event.data.carrierCompany;
      }
    }

    this.setState({
      id: event.data.id,
      qrCode: event.data.qrCode,
    });
  }

  exitEditMode = () => {
    const form = this.state.formData;
    for (const key in form) {
      if(key==='ownerType')
        continue;
      this.state.formData[key].value = ''
    }
    form.type.value = TYPE_CARD;
    form.deleted.value = false;
    form.isMaster.value = false;
    form.cardIdFormat.value = DECIMAL;
    //2022-07-27T21:00:00.000+00:00
    this.updateExpireTimeByOwnerTime()//console.log('form.expireTime.value',form.expireTime.value);


    this.setState({id: '', qrCode: ''});
    document.getElementsByName('cardId')[0].focus();
  }

  loginSuccess=(token)=> {
    const that=this;
    this.setState({token:token}, function () {
      that.loadData();
      that.loadElevatorMetaInfo();
    } )
  }

  logout=()=> {
    this.setState({token:null},function () {
      CardQrUtil.cardQrLogout();
    });
  }

  getHeaders=()=> {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.state.token
    };
  }

  render() {
    if(Util.checkNullOrUndefined(this.state.token)) {
      return <CardQrLogin successCallback={this.loginSuccess}  />
    }

    console.log('sessionStorage.getItem("cardqr-profile")',sessionStorage.getItem('cardqr-profile'));
    const profile=JSON.parse(sessionStorage.getItem('cardqr-profile')) ;

    return <div
      className={styles.mainContainer}
      onKeyDown={(e)=> {
        if (e.ctrlKey && e.key === 's') {
          // Prevent the Save dialog to open
          e.preventDefault();
          // Place your code here
          this.qrCardAddEdit();
        }else if (e.ctrlKey && e.key === 'b') {
          // Prevent the Save dialog to open
          e.preventDefault();
          e.stopPropagation();
          // Place your code here
          this.exitEditMode();
        }
      }
      }
      style={{overflow: 'auto', height: '89%', position: 'fixed'}}
    >


      {(this.state.loading || this.state.loadingListData) &&
        <CircularProgress style={{top: '50%', left: '50%', position: 'absolute', zIndex: 9000}}/>}


      <div style={{textAlign:'right',padding:'0',marginBottom:'0px',height: '20px'}}>
        {profile.role==='PROJECT_ADMIN' &&
          <div style={{display:'initial'}}><a
            href={'/card-qr-admin'}
            style={{margin:'5px'}}
          >Asansör Yönetim</a>
          <a
            href={'/card-qr-log'}
            style={{margin:'5px'}}
          >Log</a>
          <a
            href={'/card-qr-device-list'}
            style={{margin:'5px'}}
          >Kart Okuyucular</a></div>}
        <label style={{fontSize:'12px',float: 'left'}}>Veri alanları arasında hızlıca sonraki alana geçebilmek için "TAB" tuşunu, bir önceki alana geri dönebilmek için "Shift + Tab" tuş kombinasyonunu kullanabilirsiniz.</label>
        <label title={profile.name+ ' '+profile.lastName}>{profile.email}</label>
        <button
          onClick={this.logout}
          style={{margin:'5px'}}
        >Çıkış</button>
      </div>

      {this.state.formData &&
      <InputForm
        customTabKeyCode={13}
        formData={this.state.formData}
        formDataUpdater={this.formDataUpdater}
        inputFormColumnWidth={'50%'}
        inputParams={this.state.inputParams}
        tabLoop
      />
      }

      <div className={styles.buttonContainer}>

        <Button
          color="primary"
          onClick={this.qrCardAddEdit}
          size="small"
          style={{textTransform: 'none',marginRight: '20px'}}
          variant="contained"
        >
          {this.state.id ? 'Kaydı Güncelle' : 'Kayıt Ekle'}  ( CTRL + S )
        </Button>


        <Button
          color={this.state.id ? 'secondary' : 'primary'}
          onClick={this.exitEditMode}
          size="small"
          style={{textTransform: 'none',marginRight: '20px'}}
          variant="contained"
        >
          {this.state.id ? 'Değiştirme Modundan Çık' : 'Formu Temizle'} (Ctrl+ B)
        </Button>

        <div>
          <input
            onChange={(e) => this.gridApi.setQuickFilter(e.target.value)}
            placeholder={'Genel Arama.'}
            style={{'height':'80%','width':'60%'}}
            type={'text'}
          />
        </div>


        <div
          className={styles.showPassivesButtonContainer}
          title={'Pasif (eski) kayıtları görmek için seçiniz. Ancak yüklenecek veri fazla olduğundan yavaşlatabilir.'}
        >
          <label>Pasif'leri Göster</label>
          <input
            checked={Boolean(this.state.showPassivesAlso===true)}
            disabled={this.state.loadingListData}
            onChange={(e) => this.setState({showPassivesAlso:e.target.checked},this.loadData)}
            type={'checkbox'}
          />
        </div>

        <Button
          color="primary"
          onClick={this.excelExport}
          size="small"
          style={{marginRight: '20px', float: 'right'}}
          variant="contained"
        >
          Excel'e Aktar
        </Button>


        {this.state.qrCode &&
        <a
          href={process.env.REACT_APP_CARD_QR_BACKEND_URL + '/card-qr/qr-generate?qrCodeStr=' + this.state.qrCode}
          target={'_blank'}
        >QR Kod İçin Tıklayınız</a>
        }
      </div>
      <div
        style={{
          height: 'calc(100% - 370px)',
          width: '100%',
          marginTop: '0px',
          zIndex: '0'
        }}
      >
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          getContextMenuItems={this.getContextMenuItems}
          getRowClass={this.getRowClass}
          onFirstDataRendered={this.onFirstDataRendered}
          onGridReadyCustom={this.onGridReady}
          onRowDoubleClicked={this.onRowDoubleClicked}
          rowData={this.state.gridRowData}
          rowHeight={30}
          rowSelection="single"
          suppressDragLeaveHidesColumns
        />

      </div>

      <div>
        {this.renderInfoMsgs()}
      </div>
    </div>
  }

  renderInfoMsgs() {
    //TODO test edilecek.
    const res = [];
    for (let i = this.state.infoMessage.length - 1; i >= 0; i--) {
      const info = this.state.infoMessage[i];
      res.push(
        <>
          <label
            key={i}
            style={{
              width: '100%',
              fontSize: '13px',
              color: info.type === 'info' ? 'green' : 'red'
            }}
          >
            {info.time.format('HH:mm:ss ') + info.msg}</label><br/></>);
    }

    return res;

  }

  getCardIdDecimal(cardIdInputText, format) {
    if (!cardIdInputText)
      return '';

    if (format === DECIMAL)
      return cardIdInputText;
    if (format === HEX)
      return parseInt(cardIdInputText, 16).toString();
    if (format === HEX_REVERSE) {
      const hexStr = cardIdInputText.match(/[a-fA-F0-9]{2}/g).reverse().join('');
      return parseInt(hexStr, 16).toString();
    }
    throw 'Bilinmeyen Kart ID FOrmat değeri gelmiştir. Format:' + format;
  }

  getCardIdFromDecimal(cardIdDecimal, format) {
    if (!cardIdDecimal)
      return '';

    if (format === DECIMAL)
      return cardIdDecimal;
    if (format === HEX)
      return parseInt(cardIdDecimal).toString(16);
    if (format === HEX_REVERSE) {
      const hexStr = parseInt(cardIdDecimal).toString(16);
      const reversedHex = hexStr.match(/[a-fA-F0-9]{2}/g).reverse().join('');
      return reversedHex;
    }
    throw 'Bilinmeyen Kart ID FOrmat değeri gelmiştir. Format:' + format;
  }
}

export default CardQrGenerate;
