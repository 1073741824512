import React, {Component} from 'react';
import styles from './Coupon.module.css'
import InputForm from "../InputForm/InputForm";
import FormService from "../../services/Form/FormService";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Mesaj from "../../utils/Mesaj";
import Util from "../../utils/CommonUtil";
import LoginUtil from "../../utils/LoginUtil";
import {Consts} from "../../const/Consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "../Toolbar/Toolbar";
import Grid from "../Grid/Grid";
import CancelIcon from '@material-ui/icons/Cancel';

class Coupon extends Component {
  constructor(props) {
    super(props);

    const categoryColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.categoryId',
        hide: true
      },
      {
        headerName: 'Kategori',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    const productColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.productId',
        hide: true
      },
      {
        headerName: 'Ürün',
        field: 'name',
        cellRenderer: "loadingCellRenderer",
        resizable: true,
        flex: 1
      }
    ];

    const menuColumnDefs = [
      {
        headerName: 'Id',
        field: 'id',
        valueGetter: 'data.menuId',
        hide: true
      },
      {
        headerName: 'Menü',
        field: 'name',
        resizable: true,
        flex: 1
      }
    ];

    const brandColumnDefs = [
      {
        headerName: 'Marka',
        field: 'brand',
        resizable: true,
        flex: 1
      }
    ];

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      comparator: Util.GRID_COLUMN_COMPARATOR,
      keyCreator: Util.GRID_GROUP_KEY_CREATOR
    };

    this.colDefs = [
      {
        headerName: 'Kupon Id',
        field: 'couponId',
        hide: true
      },
      {
        headerName: 'Kupon',
        field: 'name'
      },
      {
        headerName: 'Açıklama',
        field: 'description'
      },
      {
        headerName: 'İndirim Tipi',
        field: 'discountType'
      },
      {
        headerName: 'İndirim Değeri',
        filter: 'agNumberColumnFilter',
        field: 'discount'
      },
      {
        headerName: 'Kullanım Limiti',
        filter: 'agNumberColumnFilter',
        field: 'usageLimit'
      },
      {
        headerName: 'Kolay Kupon',
        field: 'easyCoupon',
        cellRenderer: 'CheckBoxCellRenderer'
      },
      {
        headerName: 'categoryId',
        field: 'categoryId',
        hide: true
      },
      {
        headerName: 'Kategori',
        field: 'categoryName'
      },
      {
        headerName: 'Marka',
        field: 'brand'
      },
      {
        headerName: 'productId',
        field: 'productId',
        hide: true
      },
      {
        headerName: 'Ürün',
        field: 'productName'
      },
      {
        headerName: 'menuId',
        field: 'menuId',
        hide: true
      },
      {
        headerName: 'Menü',
        field: 'menuName'
      }
    ];

    this.inputParams = {
      name: {
        label: 'Kupon', type: 'text', defaultValue: ''
      },
      description: {
        label: 'Açıklama', type: 'text', defaultValue: ''
      },
      category: {
        label: 'Kategori', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: categoryColumnDefs,
          urlEndPoint: "/category/get-categories-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Kategori Seçiniz',
          onSelection: this.onCategorySelection.bind(this)
        },
      },
      brand: {
        label: 'Marka', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: brandColumnDefs,
          urlEndPoint: "/product/get-brand-list-by-project-id",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Marka Seçiniz',
          onSelection: this.onBrandSelection.bind(this)
        },
      },
      product: {
        label: 'Ürün', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: productColumnDefs,
          urlEndPoint: "/product/list-for-panel-with-project-id-for-popmodal",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Ürün Seçiniz',
          onSelection: this.onProductSelection.bind(this)
        },
      },
      menu: {
        label: 'Menü', type: 'select', defaultValue: '',
        options: {
          lazy: false,
          columnDefs: menuColumnDefs,
          urlEndPoint: "/menu/list-for-panel-with-project-id-for-popmodal",
          urlSendType: 'Post',
          urlMethodName: null,
          urlParametersFunc: () => Util.isAdminUser() ? [this.state.formData.projectId.value] : [Util.getProjectId()],
          title: 'Menü Seçiniz',
          onSelection: this.onMenuSelection.bind(this)
        },
      },
      discountType: {
        label: 'Kupon Tipi', defaultValue: 'Percentage', type: 'select',
        options: [
          {label: 'Yüzde', value: 'Percentage'},
          {label: 'Miktar', value: 'Amount'}
        ]
      },
      discount: {
        label: 'İndirim Değeri', type: 'number', defaultValue: 0
      },
      usageLimit: {
        label: 'Kullanım Limiti', type: 'number', defaultValue: 0
      },
      easyCoupon: {
        label: 'Kolay Kupon',
        type: 'checkbox',
        checkedVal: true,
        unCheckedVal: false,
        defaultValue: false
      },
      projectId: {  // ilerde proje isimlerinin çekilebilecek bir apı oluşturulunca bu kısım db den çekilecek!
        label: 'Proje', defaultValue: Util.getProjectId(), type: 'select',
        disabled: Util.isAdminUser() ? false : true,
        options: this.props.projectList
      }
    };

    this.state = {
      formData: FormService.createInitialFormState(this.inputParams),
      gridColumnDefs: [],
      gridRowData: [],
      editData: null
    };

    this.gridSettingJson = localStorage.getItem('couponGridSetting');
  }

  onCategorySelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].categoryId;
    formData[parentItemName].label = selectedRow[0].name;
    formData.product.value = 0;
    formData.product.label = '';
    formData.menu.value = 0;
    formData.menu.label = '';
    formData.brand.value = '';
    this.setState({formData: formData});
  }

  onBrandSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].brand;
    formData[parentItemName].label = selectedRow[0].brand;
    formData.category.value = 0;
    formData.category.label = '';
    formData.product.value = 0;
    formData.product.label = '';
    formData.menu.value = 0;
    formData.menu.label = '';
    this.setState({formData: formData});
  }

  onProductSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].productId;
    formData[parentItemName].label = selectedRow[0].name;
    formData.category.value = 0;
    formData.category.label = '';
    formData.brand.value = '';
    formData.menu.value = 0;
    formData.menu.label = '';
    this.setState({formData: formData});
  }

  onMenuSelection(popProps, selectedRow) {
    let parentItemName = popProps.parentItemName;
    let formData = {...this.state.formData};
    formData[parentItemName].showPopModal = false;
    formData[parentItemName].value = selectedRow[0].menuId;
    formData[parentItemName].label = selectedRow[0].name;
    formData.category.value = 0;
    formData.category.label = '';
    formData.brand.value = '';
    formData.product.value = 0;
    formData.product.label = '';
    this.setState({formData: formData});
  }

  async componentDidMount() {
    await this.loadData().then();
  }

  loadData = async () => {
    this.setState({loading: true});
    let apiRes;

    const projectId = Util.isAdminUser() ? this.state.formData.projectId.value : Util.getProjectId();

    apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/list-for-panel-with-project-id',
      'POST', Util.HEADERS_FOR_GET, projectId);

    this.setState({loading: false});

    if (apiRes.result === Consts.RESULT_ERROR) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({
      loading: false,
      gridRowData: this.prepareRowData(apiRes)
    })

    if (!this.gridSettingJson)
      this.gridApi.sizeColumnsToFit();
  }

  close = (event) => {
    this.props.closeTab(event, this.props.tabId);
  }

  askForAssignCoupon = (data) => {
    if (!data)
      Mesaj.show('Atanacak kupon seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else {
      if (data.easyCoupon) {
        Mesaj.show('Kolay kupon bu şekilde atanamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
        return;
      }

      Mesaj.show(data.name + ' Kuponunu bütün kullanıcılara atamak istiyor musunuz?'
        , 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.assignCoupon.bind(this, data.couponId));
    }
  }

  async assignCoupon(couponId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("couponId", couponId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/assign-coupon-to-all-user',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.setState({loading: false});
  }

  askForAssignEasyCoupon = (data) => {
    if (!data)
      Mesaj.show('Atanacak kolay kupon seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else {
      if (!data.easyCoupon) {
        Mesaj.show('Bu şekilde sadece kolay kupon atanır!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
        return;
      }

      Mesaj.show(data.name + ' Kolay Kuponunu bütün kullanıcılara atamak istiyor musunuz?'
        , 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.assignEasyCoupon.bind(this, data.couponId));
    }
  }

  async assignEasyCoupon(couponId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("couponId", couponId);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/assign-easy-coupon-to-all-user',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.setState({loading: false});
  }

  askForDeleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();

    if (selectedData.length === 0)
      Mesaj.show('Silinecek kayıt seçimi yapılmamış!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
    else
      Mesaj.show(selectedData[0].data.name + ' Kuponu ' + (makePassive ? 'pasifleştirmek' : 'silmek') +
        ' istiyor musunuz?', 'Uyarı', Mesaj.ICON_QUESTION, Mesaj.BUTTON_YES_NO, this.deleteRow.bind(this, makePassive));
  }

  deleteRow = (makePassive) => {
    const selectedData = this.gridApi.getSelectedNodes();
    selectedData.forEach((node) => {
      this.delData(node.data.couponId, makePassive).then();
    });
  }

  async delData(id, makePassive) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("couponId", id);
    urlencoded.append("makePassive", makePassive ? 'true' : 'false');

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    this.setState({loading: true});
    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/del-coupon',
      'POST', headers, urlencoded);

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      this.setState({loading: false});
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    Mesaj.show(apiRes.resultMessage, 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then();
  }

  refreshList = () => {
    this.loadData().then();
  }

  excelExport = () => {
    const params = {
      fileName: "Kupon-Listesi.xlsx",
      sheetName: 'Kupon-Listesi',
    };

    this.gridApi.exportDataAsExcel(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.gridSettingJson)
      this.gridColumnApi.setColumnState(JSON.parse(this.gridSettingJson));
  };

  saveGridSetting = () => {
    localStorage.removeItem('couponGridSetting');
    let savedState = this.gridColumnApi.getColumnState();
    localStorage.setItem('couponGridSetting', JSON.stringify(savedState));
    Mesaj.show('Tablo ayarları kayıt edildi!', 'Bilgi', Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
  }

  onFirstDataRendered = params => {
    if (!this.gridSettingJson)
      params.api.sizeColumnsToFit();
  };

  prepareRowData(list) {
    if (!list || list.length === 0)
      return [];

    let rowData = [];

    list.forEach(function (item, index) {
      rowData.push(item);
    });

    return rowData;
  }

  formDataUpdater = (formData) => {
    this.setState({formData});
  }

  addEditProduct = async () => {
    if (!this.state.formData.name.value) {
      Mesaj.show('Kupon adı boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.discountType.value) {
      Mesaj.show('İndirim tipi boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (!this.state.formData.discount.value) {
      Mesaj.show('İndirim değeri boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    if (this.state.formData.usageLimit.value !== 0 && !this.state.formData.usageLimit.value) {
      Mesaj.show('Kullanım limit boş olamaz!', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    let nameExist = false;
    const that = this;

    this.gridApi.forEachNode(function (rowNode) {
      if (!that.state.editData &&
        rowNode.data.name.toString().toLocaleLowerCase('tr') ===
        that.state.formData.name.value.toLocaleLowerCase('tr')) {
        nameExist = true;
        return;
      }
    });

    if (nameExist) {
      Mesaj.show('Bu isimde bir kupon daha önceden oluşturulmuş', 'Uyarı', Mesaj.ICON_WARN, Mesaj.BUTTON_OK);
      return;
    }

    this.setState({loading: true});

    const data = new FormData();

    if (this.state.editData)
      data.append('couponId', this.state.editData.couponId);

    data.append('name', this.state.formData.name.value || '');
    data.append('description', this.state.formData.description.value || '');
    data.append('discountType', this.state.formData.discountType.value || '');
    data.append('discount', this.state.formData.discount.value || '');
    data.append('categoryId', this.state.formData.category.value || '');
    data.append('productId', this.state.formData.product.value || '');
    data.append('menuId', this.state.formData.menu.value || '');
    data.append('brand', this.state.formData.brand.value || '');
    data.append('usageLimit', this.state.formData.usageLimit.value || 0);
    data.append('easyCoupon', this.state.formData.easyCoupon.value || false);
    data.append('couponProjectId', this.state.formData.projectId.value || 1);

    const headers = {
      'Authorization': 'Bearer ' + LoginUtil.getToken()
    }

    let apiRes = await Util.apiRequest(process.env.REACT_APP_API_GATEWAY_URL + '/coupon/add-edit-coupon',
      'POST', headers, data);

    this.setState({loading: false});

    if (apiRes.result !== Consts.RESULT_SUCCESS) {
      console.error("Bir hata oluştu! Detay:", apiRes);
      Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Sunucuda Hata Oluştu!', 'Hata',
        Mesaj.ICON_ERROR, Mesaj.BUTTON_OK);
      return;
    }

    this.clearEditMode();
    Mesaj.show(apiRes.resultMessage ? apiRes.resultMessage : 'Kupon düzenleme başarılı', 'Bilgi',
      Mesaj.ICON_INFO, Mesaj.BUTTON_OK);
    this.loadData().then(() => {
      this.gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.couponId === apiRes.couponId) {
          rowNode.setSelected(true);
          that.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
        }
      });
    });
  }

  clearEditMode() {
    this.state.formData.name.value = '';
    this.state.formData.description.value = '';
    this.state.formData.discountType.value = 'Percentage';
    this.state.formData.discount.value = 0;
    this.state.formData.brand.value = '';
    this.state.formData.category = {};
    this.state.formData.product = {};
    this.state.formData.menu = {};
    this.state.formData.usageLimit.value = 0;
    this.state.formData.easyCoupon.value = false;
    this.setState({editData: null});
  }

  editProduct = editData => {
    this.state.formData.name.value = editData.name;
    this.state.formData.description.value = editData.description;
    this.state.formData.discountType.value = editData.discountType;
    this.state.formData.discount.value = editData.discount;
    this.state.formData.brand.value = editData.brand;
    this.state.formData.category = {value: editData.categoryId ? editData.categoryId : 0, label: editData.categoryName};
    this.state.formData.product = {value: editData.productId ? editData.productId : 0, label: editData.productName};
    this.state.formData.menu = {value: editData.menuId ? editData.menuId : 0, label: editData.menuName};
    this.state.formData.usageLimit.value = editData.usageLimit;
    this.state.formData.easyCoupon.value = editData.easyCoupon;
    this.setState({editData: editData});
  }

  getContextMenuItems = params => {
    if (params.node.group === true)
      return null;

    const menu = [
      {
        name: 'Kupon Sil',
        action: this.askForDeleteRow.bind(this, false),
        icon: '<img src="/images/menu/18px/mnuDelete_black.png"/>',
      },
      {
        name: 'Kupon Düzenle',
        action: this.editProduct.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      },
      {
        name: 'Herkeze Kupon Tanımla',
        action: this.askForAssignCoupon.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      },
      {
        name: 'Herkeze Kolay Kupon Tanımla',
        action: this.askForAssignEasyCoupon.bind(this, params.node.data),
        icon: '<img src="/images/edit.png"/>',
      }
    ];
    return menu;
  };

  getRowClass = (params) => {
    if (params.node.data.deleted === true)
      return 'ag-grid-deleted-row';

    return null;
  }

  render() {
    return <div className={styles.mainContainer}>
      <div className={styles.inputContainer} style={{display: 'flex', flexWrap: 'wrap'}}>

        {this.state.loading &&
        <CircularProgress style={{top: "50%", left: "50%", position: "absolute", zIndex: 9000}}/>}
        <InputForm
          formData={this.state.formData}
          formDataUpdater={this.formDataUpdater}
          inputParams={this.inputParams}
          inputFormColumnWidth={'50%'}
          tabLoop={true}
          customTabKeyCode={13}
        />
        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            color="primary"
            size="small"
            startIcon={<SaveIcon/>}
            onClick={this.addEditProduct.bind(this)}
          >
            {this.state.editData ? 'Kupon Bilgi Güncelle' : 'Kupon Ekle'}
          </Button>
        </div>

        <div style={{display: 'flex', width: '48%', textAlign: 'center', padding: '10px'}}>
          <Button
            disabled={this.state.loading}
            variant={"contained"}
            size="small"
            color="primary"
            startIcon={<CancelIcon/>}
            onClick={this.clearEditMode.bind(this)}
          >
            Düzenleme Modundan Çık
          </Button>
        </div>
      </div>

      <div>
        <Toolbar handlers={
          [
            {callBack: this.refreshList, title: 'Yenile', icon: '/images/menu/mnuAutorenew_24px.svg'},
            {callBack: this.excelExport, title: 'Excel\'e aktar', icon: '/images/menu/mnuMicrosoft-excel.svg'},
            {
              callBack: this.saveGridSetting.bind(this),
              title: 'Tablo Ayarlarını Kaydet',
              icon: '/images/menu/24px/mnuSaveSettings.png'
            }
          ]
        }
        />
      </div>
      <div
        style={{
          height: 'calc(100% - 320px)',
          width: '100%',
          marginTop: '8px',
          zIndex: '0'
        }}>
        <Grid
          columnDefs={this.colDefs}
          defaultColDef={this.defaultColDef}
          rowData={this.state.gridRowData}
          rowSelection='single'
          onGridReadyCustom={this.onGridReady}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          getContextMenuItems={this.getContextMenuItems}
          suppressDragLeaveHidesColumns={true}
          rowHeight={50}
          getRowClass={this.getRowClass}
        >
        </Grid>
      </div>
    </div>
  };
}

export default Coupon;
