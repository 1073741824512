import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {Chart} from 'react-chartjs-2';
import {ThemeProvider} from '@material-ui/styles';
import validate from 'validate.js';

import {chartjs} from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.css';
import validators from './common/validators';
import Routes from './Routes';
import Alert from "./components/Alert/Alert";
import {SET_ALERT} from "./redux/actions/actionTypes";
import {connect} from "react-redux";


const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

class App extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    const defaultLanguage = sessionStorage.getItem("language")
    defaultLanguage == null && sessionStorage.setItem("language","tr")
  }   

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Alert
          alert={this.props.alert}
          closeHandler={this.props.closeAlert}
        />
        <Router history={browserHistory}>
          <Routes/>
        </Router>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log('(App.js) mapStateToProps state : ', state);
  return {
    alert: state.alert
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log('(App.js) mapDispatchToProps');
  return {
    closeAlert: () => {
      dispatch({
        type: SET_ALERT,
        alert: null
      })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

